import { IFeature } from "./features.model";

export interface IBarAdmission  {
  id?: number;
  description?: string;
  location?: string;  
}

export const BarAdmissionFeature: IFeature = {
  FeatureName: 'BarAdmission',
  ControllerPath: 'utility/baradmission',
  CreateSuccessMessage: 'Bar Admission created successfully.',
  CreateFailureMessage: 'Failed to create Bar Admission.', 
};

export const BarAdmissionsFeature: IFeature = {
  FeatureName: 'BarAdmissions',
  ControllerPath: 'utility/baradmission',
  GetFailureMessage: 'Failed to get Bar Admissions.',
};
