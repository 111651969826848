import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeContactActions from '../actions/employee-contact.actions';
import { EmployeeContactService } from 'src/app/services/employee-contact.service';
import { IEmployeeContact } from 'src/app/models/employee-contact.model';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';

@Injectable()
export class EmployeeContactEffects {
  private actions$ = inject(Actions);

  constructor(
    private employeeContactService: EmployeeContactService
  ) {}

  getEmployeeContact$ = createEffect(() =>  
    this.actions$.pipe(
      ofType(EmployeeContactActions.getEmployeeContact),
      mergeMap((action: EmployeeContactActions.GetEmployeeContactAction) => {
        return this.employeeContactService.get(action.id).pipe(
          map((model: IEmployeeContact) => {
            return EmployeeContactActions.getEmployeeContactSuccess({ model });
          }),
          catchError(error => {
            return of(
              EmployeeContactActions.getEmployeeContactFailure({ error }),
              toastErrorAction({ message: 'Failed to get employee contacts' })
            );
          })
        );
      })
    )
  );

  updateEmployeeContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeContactActions.updateEmployeeContact),
      mergeMap((action: EmployeeContactActions.UpdateEmployeeContactAction) => {
        return this.employeeContactService.update(action.model, action.id, action.includeChildren, action.queryParams).pipe(
          concatMap((model:IEmployeeContact) => {
            return [
              EmployeeContactActions.updateEmployeeContactSuccess({ model }),
              toastSuccessAction({ message: 'Employee contacts updated successfully' })
            ];
          }),
          catchError(error => {
            return of(
              EmployeeContactActions.updateEmployeeContactFailure({ error }),
              toastErrorAction({ message: 'Failed to update employee contacts' })
            );
          })
        );
      })
    )
  );

}