<mdb-form-control class={{className}}>
  <input  mdbInput 
          type={{type}} 
          id={{fieldName}} 
          name={{fieldName}} 
          class="form-control" 
          [(ngModel)]="inputModel" 
          (ngModelChange)="inputModelChange.emit(inputModel)"
          [ngModelOptions]="{standalone: true}" />
  <label mdbLabel class="form-label" for={{fieldName}}>{{label}}</label>  
</mdb-form-control>