
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { Store } from '@ngrx/store';
import { ICommittee } from '../models/committee.model';
import { ICommitteeEmployee } from '../models/committee-employee.model';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class CommitteeService extends AppAuthenticatedCrudBaseService<ICommittee> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'Committees', store);
  }
  createDto(model: ICommittee): ICommittee {
    let committeeEmployees = model.committeeEmployees;
    if (model.committeeEmployees) committeeEmployees = model.committeeEmployees.map(pl => {
      return {
        committeeEmployeeId: pl.committeeEmployeeId,
        employeeId: pl.employeeId,
        committeeId: pl.committeeId
      } as ICommitteeEmployee;
    });
    return{
      committeeId: model.committeeId,
      name: model.name,
      photoName: model.photoName,
      isActive: model.isActive,
      committeeEmployees: committeeEmployees
    };    
  }
}