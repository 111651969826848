import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEmployeeSupportTeam } from 'src/app/models/employee-support-team';

export const createEmployeeSupportTeam = createAction(
  '[EmployeeSupportTeam] Create',
  props<{ model: IEmployeeSupportTeam, includeChildren: boolean }>()
);

export const createEmployeeSupportTeamSuccess = createAction(
  '[EmployeeSupportTeam] Create Success',
  props<{ model: IEmployeeSupportTeam }>()
);

export const createEmployeeSupportTeamFailure = createAction(
  '[EmployeeSupportTeam] Create Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const getAllEmployeeSupportTeam = createAction(
  '[EmployeeSupportTeam] Get All',
  props<{ queryParams: Record<string, unknown> }>()
);

export const getAllEmployeeSupportTeamSuccess = createAction(
  '[EmployeeSupportTeam] Get All Success',
  props<{ models: IEmployeeSupportTeam[] }>()
);

export const getAllEmployeeSupportTeamFailure = createAction(
  '[EmployeeSupportTeam] Get All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const updateAllEmployeeSupportTeam = createAction(
  '[EmployeeSupportTeam] Update All',
  props<{ models: IEmployeeSupportTeam[] }>()
);

export const updateAllEmployeeSupportTeamSuccess = createAction(
  '[EmployeeSupportTeam] Update All Success',
  props<{ models: IEmployeeSupportTeam[] }>()
);

export const updateAllEmployeeSupportTeamFailure = createAction(
  '[EmployeeSupportTeam] Update All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const deleteEmployeeSupportTeam = createAction(
  '[EmployeeSupportTeam] Delete',
  props<{ id: string | number }>()
);

export const deleteEmployeeSupportTeamSuccess = createAction(
  '[EmployeeSupportTeam] Delete Success'  
);

export const deleteEmployeeSupportTeamFailure = createAction(
  '[EmployeeSupportTeam] Delete Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const setEmployeeSupportTeamCanUpdate = createAction(
  '[EmployeeSupportTeam] Set Can Update',
  props<{ canUpdate: boolean }>()
);

export const setAllEmployeeSupportTeam = createAction(
  '[EmployeeSupportTeam] Set All',
  props<{ models: IEmployeeSupportTeam[] }>()
);

export type CreateEmployeeSupportTeamAction = ReturnType<typeof createEmployeeSupportTeam>;
export type CreateEmployeeSupportTeamSuccessAction = ReturnType<typeof createEmployeeSupportTeamSuccess>;
export type CreateEmployeeSupportTeamFailureAction = ReturnType<typeof createEmployeeSupportTeamFailure>;

export type GetAllEmployeeSupportTeamAction = ReturnType<typeof getAllEmployeeSupportTeam>;
export type GetAllEmployeeSupportTeamSuccessAction = ReturnType<typeof getAllEmployeeSupportTeamSuccess>;
export type GetAllEmployeeSupportTeamFailureAction = ReturnType<typeof getAllEmployeeSupportTeamFailure>;

export type UpdateAllEmployeeSupportTeamAction = ReturnType<typeof updateAllEmployeeSupportTeam>;
export type UpdateAllEmployeeSupportTeamSuccessAction = ReturnType<typeof updateAllEmployeeSupportTeamSuccess>;
export type UpdateAllEmployeeSupportTeamFailureAction = ReturnType<typeof updateAllEmployeeSupportTeamFailure>;

export type DeleteEmployeeSupportTeamAction = ReturnType<typeof deleteEmployeeSupportTeam>;
export type DeleteEmployeeSupportTeamSuccessAction = ReturnType<typeof deleteEmployeeSupportTeamSuccess>;
export type DeleteEmployeeSupportTeamFailureAction = ReturnType<typeof deleteEmployeeSupportTeamFailure>;

export type SetEmployeeSupportTeamCanUpdateAction = ReturnType<typeof setEmployeeSupportTeamCanUpdate>;
export type SetAllEmployeeSupportTeamAction = ReturnType<typeof setAllEmployeeSupportTeam>;
