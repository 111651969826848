import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges  } from '@angular/core';
import { IEmployee } from '../../models/employee.model';
import { Observable } from 'rxjs';
import { Profile } from 'src/app/models/user.model';
import { user } from 'src/app/store/state/auth.state';
import { select, Store } from '@ngrx/store';
import { MdbWysiwygComponent, MdbWysiwygToolbarOptions } from 'mdb-angular-wysiwyg';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'app-employee-bio-admin',
  templateUrl: './employee-bio-admin.component.html',
  styleUrls: ['./employee-bio-admin.component.scss']
})
export class EmployeeBioAdminComponent implements OnInit, OnChanges {

  @ViewChild('wysiwyg') wysiwyg!: MdbWysiwygComponent;
  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;

  @Input()
  model!: IEmployee;

  @Output()
  save = new EventEmitter<IEmployee>();

  mode  = "read";
  text = "";
  user$!: Observable<Profile | null>;
  modelCopy: IEmployee = {};

  constructor(private store: Store<RootState>
              , private popconfirmService: MdbPopconfirmService)
  {}

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(user));
  }

  ngOnChanges() {
    this.modelCopy = {...this.model};
  }

  disableToolbarOptions: MdbWysiwygToolbarOptions = {
    styles: { hidden : true },
    formatting: {
      hidden: true,
    },
    align: {
      hidden: true,
    },
    lists: {
      disabled: false,
      hidden: true,
      insertUnorderedList: { hidden: true },
      indent: { hidden: true },

    },
    links: {
      image: { hidden: true },
      insertHorizontalRule: { hidden: true},
      link: {hidden: true}
    },
    showCode: { hidden: true },
    undoRedo: { hidden: true }
  };

  onEdit() {
    this.mode = 'update';
  }

  onCancelCheck(){
    return JSON.stringify(this.model) === JSON.stringify(this.modelCopy);
  }

  onCancel() {
    this.mode = 'read';
    if(!this.modelCopy.bioBodyAdmin){
      this.modelCopy.bioBodyAdmin = "";
    }
    this.model = { ...this.modelCopy };
  }

  onSave() {
    this.model.bioBodyAdmin = this.wysiwyg.getCode();
    this.save.emit({ ...this.model });
    this.mode = 'read';
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }

}
