import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ILanguage } from 'src/app/models/language.model';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { Observable, of, tap } from 'rxjs';
import { RootState } from 'src/app/store/root.state';
import { Store } from '@ngrx/store';
import { selectors } from 'src/app/store/selectors/selectors';
import { toastErrorAction } from 'src/app/store/actions/toast.actions';
import { Feature } from 'src/app/models/features.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';

@Component({
  selector: 'app-language-new',
  templateUrl: './language-new.component.html',
  styleUrls: ['./language-new.component.scss']
})
export class LanguageNewComponent implements OnInit {

  newLanguage$: Observable<ILanguage> = of({});
  isSaving$: Observable<boolean> = of(false);
  model: ILanguage = {}
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;

  constructor(private readonly store: Store<RootState>
            , private readonly title: Title
            , private modalService: MdbModalService
            , private readonly router:Router
  ) { }

  ngOnInit(): void {
    this.isSaving$ = this.store.select(selectors.language.isSaving);    
    this.newLanguage$ = this.store.select(selectors.barAdmission.get)
      .pipe(tap((model: ILanguage) => {
          this.model = {...model};
      }));
    this.newLanguage$.subscribe();
    this.title.setTitle(`New Language`);
  }

  save() {
    const action = this.model.name?.trim() ?
    CrudActions.create<ILanguage>(Feature.Language)(this.model) : 
      toastErrorAction({ message: 'A language name is required!' })
    this.store.dispatch(action);
  }

  onCancelCheck(){
    return this.model.name  && this.model.name.trim() !== '' ? false : true;
  }

  cancelUpdate(){
    this.model = {};
    this.router.navigate(['/']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'Language-New'},
    });
  }

}