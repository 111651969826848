import { createReducer, on } from "@ngrx/store";
import * as EmployeeLanguageActions from "../actions/employee-language.actions";
import { EmployeeLanguageState, initialEmployeeLanguageState } from "../state/employee-language.state";
import { IEmployeeLanguage } from "src/app/models/employee-language.model";

export const employeeLanguageReducer = createReducer(
  initialEmployeeLanguageState,
  on(EmployeeLanguageActions.createEmployeeLanguage, (state: EmployeeLanguageState, { model }) => {
    return { ...state, 
        employeeLanguages: [...state.employeeLanguages, model], 
        isLoading: true, 
        canUpdate: false, 
        error: null};
  }),
  on(EmployeeLanguageActions.createEmployeeLanguageSuccess, (state: EmployeeLanguageState, { model }) => {
    return { ...state, 
      currentEmployeeLanguage: model, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeLanguageActions.createEmployeeLanguageFailure, (state: EmployeeLanguageState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeLanguageActions.deleteEmployeeLanguage, (state: EmployeeLanguageState, { id }) => { 
    return { ...state, 
      employeeLanguages: state.employeeLanguages.filter((language: IEmployeeLanguage) => language.id !== id), 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeLanguageActions.deleteEmployeeLanguageSuccess, (state: EmployeeLanguageState) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeLanguageActions.deleteEmployeeLanguageFailure, (state: EmployeeLanguageState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeLanguageActions.getAllEmployeeLanguage, (state: EmployeeLanguageState) => {
    return { ...state, 
      employeeLanguages: [], 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeLanguageActions.getAllEmployeeLanguageSuccess, (state: EmployeeLanguageState, { models }) => {
    return { ...state, 
      employeeLanguages: models, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeLanguageActions.getAllEmployeeLanguageFailure, (state: EmployeeLanguageState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeLanguageActions.setEmployeeLanguageCanUpdate, (state: EmployeeLanguageState, { canUpdate }) => {
    return { ...state, canUpdate };
  }),
  on(EmployeeLanguageActions.setAllEmployeeLanguage, (state: EmployeeLanguageState, { models }) => {
    return { ...state, 
      employeeLanguages: models,
      isLoading: false, 
      canUpdate: false, 
     };
  }),
  on(EmployeeLanguageActions.updateAllEmployeeLanguage, (state: EmployeeLanguageState, { models }) => {
    return { ...state, 
      employeeLanguages: models, 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeLanguageActions.updateAllEmployeeLanguageSuccess, (state: EmployeeLanguageState, { models }) => {
    return { ...state, 
      employeeLanguages: models, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeLanguageActions.updateAllEmployeeLanguageFailure, (state: EmployeeLanguageState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }), 
 
);