import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEmployeeNotary } from '../models/employee-notary.model';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})

export class EmployeeNotaryService extends AppAuthenticatedCrudBaseService<IEmployeeNotary> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'EmployeeNotaries', store);
  }

  createDto(model: IEmployeeNotary): IEmployeeNotary {    
    const result: IEmployeeNotary =
    {
      id: model.id,
      employeeId: model.employeeId,
      countyId: model.countyId,      
      expireDate: model.expireDate
    };

    return result;
  }

  createListDto(listItems: IEmployeeNotary[]) : IEmployeeNotary[] {
    const results : IEmployeeNotary[] = [];
    for (let i = 0; i < listItems.length; i++) {
      results.push(this.createDto(listItems[i]));
    }
    return results;
  }
}