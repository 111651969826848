import { BarAdmissionFeature, BarAdmissionsFeature } from "./bar-admission.model";
import { ChangeRequestFeature } from "./change-request.model";
import { CommitteeFeature, CommitteesFeature } from "./committee.model";
import { DepartmentFeature, DepartmentsFeature } from "./department.model";
import { EmployeeFeature, EmployeesFeature } from "./employee.model";
import { EmployeeKeyDatesFeature } from "./employee-key-dates.model";
import { LanguageFeature, LanguagesFeature } from "./language.model";
import { OfficeFeature, OfficesFeature } from "./office.model";
import { SupportTeamFeature, SupportTeamsFeature } from "./support-team.model";
import { PscFeature, PscsFeature } from "./psc.model";
import { SbuFeature, SbusFeature } from "./sbu.model";

export interface IFeature {
  FeatureName: string;
  ControllerPath: string;
  CreateSuccessMessage?: string;
  CreateFailureMessage?: string;
  GetFailureMessage?: string;
  UpdateSuccessMessage?: string;
  UpdateFailureMessage?: string;
  DeleteSuccessMessage?: string;
  DeleteFailureMessage?: string;
}

export const Feature = {
  BarAdmission: BarAdmissionFeature,
  BarAdmissions: BarAdmissionsFeature,
  ChangeRequest: ChangeRequestFeature,
  Committee: CommitteeFeature,
  Committees: CommitteesFeature,
  Department: DepartmentFeature,
  Departments: DepartmentsFeature,
  Employee: EmployeeFeature,
  EmployeeKeyDates: EmployeeKeyDatesFeature,
  Employees: EmployeesFeature,
  Language: LanguageFeature,
  Languages: LanguagesFeature,
  Office: OfficeFeature,
  Offices: OfficesFeature,
  Psc: PscFeature,
  Pscs: PscsFeature,
  Sbu: SbuFeature,
  Sbus: SbusFeature,
  SupportTeam: SupportTeamFeature,
  SupportTeams: SupportTeamsFeature,
};
