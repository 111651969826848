<div class="d-flex flex-row-reverse">
  <button type="button" class="btn btn-primary btn-sm ml-05" 
            (click)="openAddNewModal()" 
            *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))">
    <i class="fas fa-plus"></i>
    Add New Notary
  </button>
  <button type="button" class="btn btn-primary btn-sm" 
          *ngIf="(canUpdate$ | async) === false && employeeNotaries.length > 0 && (((user$ | async)?.isHR) || ((user$ | async)?.isAdmin))" 
          (click)="onEdit()">
    <i class="fas fa-edit"></i> Edit
  </button>
  <button type="button" class="btn btn-primary btn-sm ml-05" *ngIf="(canUpdate$ | async)"
          (click)="!onCancelCheck() ? openPopconfirm($event) : onCancel()">
    <i class="fas fa-undo"></i> Cancel
  </button>
  <button type="button" class="btn btn-primary btn-sm" *ngIf="(canUpdate$ | async)" (click)="onSave()">      
    <i class="fas fa-save ml-025"></i> Save
  </button>
</div>
<div class="row">
  <div class="col-md-9 ml-05 mt-1">
    <div class="d-flex align-items-center" *ngIf="(isLoading$ | async)">
      <strong>Loading...</strong>
      <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
    </div>  
    <div *ngIf="employeeNotaries.length === 0 && (isLoading$ | async) === false">
      <h6>No notaries found.</h6>
      <h6 *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))"> Click on "Add New Notary" to add a new notary. </h6>
    </div>
    <table class="table table-bordered table-responsive-md table-striped" *ngIf="employeeNotaries.length > 0 && (isLoading$ | async) === false" >      
      <tr>
        <th class="text-center">County</th>
        <th class="text-center">Expiration Date</th>        
        <th *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))"></th>
      </tr>
      <tr *ngFor="let empNotary of employeeNotaries;let id=index" >
        <td class="text-center">
          <ng-select  [(ngModel)]="empNotary.countyId"
                      [readonly]="(canUpdate$ | async) === false"
                      name="countyId"
                      id="countyId"
                      [loading]="(isCountyLoading$ | async) ?? false">
            <ng-option *ngFor="let temp of (counties$ | async)" [value]="temp.id">{{temp.name}}</ng-option>
          </ng-select>
        </td>
        <td class="text-center exp-date-width">
          <app-datepicker datepickerName="expireDatePicker" 
                    fieldName="expireDate"
                    [(inputModel)]="empNotary.date"
                    [disabled]="((canUpdate$ | async) === false)  || (!((user$ | async)?.isAdmin) && !((user$ | async)?.isHR)) "
                    [fromHR]="false"
                    label=""
                    [showClearButton]="true"
                    className="col-12">
          </app-datepicker>
        </td>      
        <td class="text-center delete-width" *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))">
          <span>
            <a href="#!" role="button"  class="icon-selectable"
                *ngIf="(canUpdate$ | async)" 
                (click)="setDeleteId(empNotary.id);deletePopconfirm($event)">
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
            <a *ngIf="(canUpdate$ | async) === false" class="disabled icon-disabled">
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
          </span>
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add new notary</h5>
    <button type="button" class="btn-close-custom" aria-label="Close" (click)="templateModalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <ng-select  [(ngModel)]="newNotary.countyId"                    
                    name="countyId"
                    placeholder="Search to add county"
                    [loading]="(isCountyLoading$ | async) ?? false">
        <ng-option *ngFor="let temp of (counties$ | async)" [value]="temp.id">{{temp.name}}</ng-option>
        </ng-select>
      </div>
      <div class="col-md-12">
        <mdb-form-control class="col-12">
          <input mdbInput [mdbDatepicker]="newexpireDatePicker" type="text" 
                          class="form-control" id="newexpireDate" 
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="newNotary.date"/>
          <label mdbLabel for="resignationDate" class="form-label">Add an Expiration Date:</label>
          <mdb-datepicker-toggle  [mdbDatepicker]="newexpireDatePicker"></mdb-datepicker-toggle>
          <mdb-datepicker [inline]="true" #newexpireDatePicker [format]="'mm/dd/yyyy'"></mdb-datepicker>
        </mdb-form-control>            
      </div>     
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="templateModalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="add()">Add</button>
  </div>
</ng-template>