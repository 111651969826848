import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEmployeeOrg } from 'src/app/models/employee-org.model';

export const getEmployeeOrg = createAction(
  '[EmployeeOrg] Get',
  props<{ id: string | number }>()
);

export const getEmployeeOrgSuccess = createAction(
  '[EmployeeOrg] Get Success',
  props<{ model: IEmployeeOrg }>()
);

export const getEmployeeOrgFailure = createAction(
  '[EmployeeOrg] Get Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const updateEmployeeOrg = createAction(
  '[EmployeeOrg] Update',
  props<{ model: IEmployeeOrg, id: string | number | null | undefined, includeChildren: boolean, queryParams: Record<string, unknown> }>()
);

export const updateEmployeeOrgSuccess = createAction(
  '[EmployeeOrg] Update Success',
  props<{ model: IEmployeeOrg }>()
);

export const updateEmployeeOrgFailure = createAction(
  '[EmployeeOrg] Update Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const setEmployeeOrgCanUpdate = createAction(
  '[EmployeeOrg] Set Can Update',
  props<{ canUpdate: boolean }>()
);

export type GetEmployeeOrgAction = ReturnType<typeof getEmployeeOrg>;
export type GetEmployeeOrgSuccessAction = ReturnType<typeof getEmployeeOrgSuccess>;
export type GetEmployeeOrgFailureAction = ReturnType<typeof getEmployeeOrgFailure>;

export type UpdateEmployeeOrgAction = ReturnType<typeof updateEmployeeOrg>;
export type UpdateEmployeeOrgSuccessAction = ReturnType<typeof updateEmployeeOrgSuccess>;
export type UpdateEmployeeOrgFailureAction = ReturnType<typeof updateEmployeeOrgFailure>;

export type SetEmployeeOrgCanUpdateAction = ReturnType<typeof setEmployeeOrgCanUpdate>;


