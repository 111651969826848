import { createReducer, on } from "@ngrx/store";
import * as EmployeeContactActions from "../actions/employee-contact.actions";
import { EmployeeContactState, initialEmployeeContactState } from "../state/employee-contact.state";

export const employeeContactReducer = createReducer(
  initialEmployeeContactState,
  on(EmployeeContactActions.getEmployeeContact, (state: EmployeeContactState) => {
    return { ...state, employeeContact: {}, isLoading: true, isSaving: false, canUpdate: false, error: null };
  }),
  on(EmployeeContactActions.getEmployeeContactSuccess, (state: EmployeeContactState, { model }) => {
    return { ...state, employeeContact: model, isLoading: false, isSaving: false, error: null };
  }),
  on(EmployeeContactActions.getEmployeeContactFailure, (state: EmployeeContactState, { error }) => {
    return { ...state, employeeContact: {}, isLoading: false, isSaving: false, error };
  }),
  on(EmployeeContactActions.updateEmployeeContact, (state: EmployeeContactState, { model }) => {
    return { ...state, employeeContact: model, isLoading: true, isSaving: true, error: null};
  }), 
  on(EmployeeContactActions.updateEmployeeContactSuccess, (state: EmployeeContactState, { model }) => {
    return { ...state, employeeContact: model, isLoading: false, isSaving: false, canUpdate: false,  error: null };
  }),
  on(EmployeeContactActions.updateEmployeeContactFailure, (state: EmployeeContactState, { error }) => {
    return { ...state, employeeContact: {}, isLoading: false, isSaving: false, canUpdate: false, error };
  }),
  on(EmployeeContactActions.setEmployeeContactCanUpdate, (state: EmployeeContactState, { canUpdate }) => {
    return { ...state, canUpdate: canUpdate };
  }),
);