import { AppBaseService } from './app-base.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { accessToken } from '../store/state/auth.state';
import { select, Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

export class AppAuthenticatedBaseService extends AppBaseService {
  accessToken!: string | null;  

  constructor(store: Store<RootState>) {
    super(store);
  }

  protected getHttpHeaders() {
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      this.accessToken = accessToken;
    })
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.accessToken}`,
    });
  }


  protected createQueryString(queryParams?: Record<string, unknown>): string {
    if (!queryParams) return '';
    return '?' + Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
      .join('&');
  }
 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  protected handleError<T2>(err: any, _caught: Observable<T2>): ObservableInput<T2> {
    let message: string;
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else if (err.error instanceof ProgressEvent) {      
      message = err.message;
    } else {      
      message = `Error ${err.status}: ${err.error}`;
    }
    return throwError(message);
  }
}
