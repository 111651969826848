<div class="media">
  <div class="media-body ml-05">
    <div class="row">
      <div class="col-md-4"><h4>Add New Support Team</h4></div>
      <div class="col-md-3 offset-md-5 text-right">
        <button type="button" class="btn btn-primary btn-sm" (click)="save()">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving$ | async" aria-hidden="true"></span>
          <i class="fas fa-save ml-025"></i>  Save</button>
        <button class="btn btn-primary btn-sm ml-05" (click)="openCancelModal()">
          <i class="fas fa-undo"></i> Cancel
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <form>
      <app-input-text [cssClass]="'col-6 mt-2 mb-3'"  [fieldName]="'name'" [labelHtml]="'Name'" 
        [(fieldValue)]="model.name"></app-input-text>
      <app-input-phone [cssClass]="'col-6 mt-2 mb-3'"  [fieldName]="'phone'" [labelHtml]="'Phone'" 
        [(fieldValue)]="model.phone"></app-input-phone>
      <app-input-text [cssClass]="'col-6 mt-2 mb-3'"  [fieldName]="'email'" [labelHtml]="'Email'" 
        [(fieldValue)]="model.email"></app-input-text>
      <div class="row">
        <div class="col-md-6">
          <div class="md-form form-group mb-0 mt-0">
            <label for="coordinatorId">Coordinator</label> <br/>
            <ng-select [items]="(coordinatorEmployees$ | async)!"
                      bindLabel="displayName"
                      [trackByFn]="trackByFn"
                      [minTermLength]="2"
                      [loading]="(employeesIsLoading$ | async) ?? false"
                      typeToSearchText="Type to search coordinator"
                      [typeahead]="coordinatorEmployeeInput$"
                      [(ngModel)]="model.coordinator"
                      labelForId="coordinatorId"
                      name="coordinator" >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="md-form form-group mb-0 mt-0">
            <label for="backupCoordinatorId">Backup Coordinator</label> <br/>
            <ng-select [items]="(backupCoordinatorEmployees$ | async)!"
                      bindLabel="displayName"
                      [trackByFn]="trackByFn"
                      [minTermLength]="2"
                      [loading]="(employeesIsLoading$ | async) ?? false"
                      typeToSearchText="Type to search coordinator"
                      [typeahead]="backupCoordinatorEmployeeInput$"
                      [(ngModel)]="model.backupCoordinator"
                      labelForId="backupCoordinatorId"
                      name="backupCoordinator">
            </ng-select>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>