import { Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { Observable, of } from 'rxjs';
import { ICounty } from 'src/app/models/county.model';
import { IEmployeeNotary } from 'src/app/models/employee-notary.model';
import { Profile } from 'src/app/models/user.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { DeleteConfirmComponent } from '../helpers/delete-confirm/delete-confirm.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { RootState } from 'src/app/store/root.state';
import { getAllCounty } from 'src/app/store/actions/county.actions';
import { createEmployeeNotary, deleteEmployeeNotary, getAllEmployeeNotary, setAllEmployeeNotary, setEmployeeNotaryCanUpdate, updateAllEmployeeNotary } from 'src/app/store/actions/employee-notary.actions';
import { selectors } from 'src/app/store/selectors/selectors';

@Component({
  selector: 'app-employee-notary',
  templateUrl: './employee-notary.component.html',
  styleUrls: ['./employee-notary.component.scss']
})
export class EmployeeNotaryComponent implements OnInit, OnChanges {
  
  @Input()  
  employeeId: string | null | undefined;

  user$!: Observable<Profile | null>;
  canUpdate$: Observable<boolean> = of(false);  
  counties$: Observable<ICounty[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  isCountyLoading$: Observable<boolean> = of(false);
  employeeNotaries$: Observable<IEmployeeNotary[]> = of([]);
  employeeNotaries: IEmployeeNotary[] = [];
  employeeNotariesCopy: IEmployeeNotary[] = [];  
  notaryDeleteId: number | undefined;
  newNotary: IEmployeeNotary = {};
  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;
  deleteconfirmRef: MdbPopconfirmRef<DeleteConfirmComponent> | null = null;
  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;

  templateModalRef!: MdbModalRef<Element>;
  @ViewChild('template') modalTemplate!: TemplateRef<Element>;
  
  constructor(private store: Store<RootState>
              , private popconfirmService: MdbPopconfirmService
              , private readonly modalService: MdbModalService) 
  {}

  ngOnInit(): void {
    this.canUpdate$ = this.store.select(selectors.employeeNotary.canUpdate);
    this.isLoading$ = this.store.select(selectors.employeeNotary.isLoading);
    this.employeeNotaries$ = this.store.select(selectors.employeeNotary.getAll);
    this.isCountyLoading$ = this.store.select(selectors.county.isLoading);
    this.counties$ = this.store.select(selectors.county.getAll);
    this.user$ = this.store.pipe(select(user));
    this.store.dispatch(getAllCounty({ queryParams: {} }));
    this.store.select(selectors.employeeNotary.getAll).subscribe((employeeNotaries: IEmployeeNotary[]) => {
      if(!employeeNotaries) return;
      this.employeeNotaries = employeeNotaries.map(notary => {
        const item = {...notary};
        if(item.expireDate){
          item.date = new Date(item.expireDate);
        }
        return item;
      });
      this.employeeNotariesCopy = [...employeeNotaries];
    });
  }

  ngOnChanges() {
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken && this.employeeId) {
        this.getEmployeeNotaries(this.employeeId);
      }
    });
  }

  getEmployeeNotaries(id: string) {
    this.store.dispatch(getAllEmployeeNotary({ queryParams: { employeeId: id } }));
  }

  onSave() {
    this.employeeNotaries.forEach((item) => {
      item.expireDate = item.date;
    });
    this.store.dispatch(updateAllEmployeeNotary({ models: this.employeeNotaries }));
  }

  onEdit() {
    this.store.dispatch(setEmployeeNotaryCanUpdate({canUpdate: true}));
  }

  onCancelCheck(){
    return (JSON.stringify(this.employeeNotaries) === JSON.stringify(this.employeeNotariesCopy));
  }

  onCancel() {
    this.store.dispatch(setAllEmployeeNotary({ models: this.employeeNotariesCopy }));
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }

  setDeleteId(id: number | undefined){
    this.notaryDeleteId = id;
  }

  deletePopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.deleteconfirmRef = this.popconfirmService.open(DeleteConfirmComponent, target, { popconfirmMode: 'modal' });
    this.deleteconfirmRef.onConfirm.subscribe(() => {
      this.remove();
    });
  }

  remove() {
    if(this.notaryDeleteId){
      this.store.dispatch(deleteEmployeeNotary({ id: this.notaryDeleteId }));
    }    
  }

  add() {
    if(this.employeeId){
      this.newNotary.employeeId = parseInt(this.employeeId);
      this.newNotary.expireDate = this.newNotary.date; 
      this.store.dispatch(createEmployeeNotary({ model: this.newNotary, includeChildren: false }));    
      this.newNotary = {};
    }
    this.templateModalRef.close();
  }

  openAddNewModal() {
    this.templateModalRef = this.modalService.open(this.modalTemplate);
  }
  
}