import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { ISbu } from '../models/sbu.model';
import { Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class SbuService extends AppAuthenticatedCrudBaseService<ISbu> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'Sbu', store);
  }
}