import { createReducer, on } from "@ngrx/store";
import * as EmployeeTypeActions from "../actions/employee-type.actions";
import { EmployeeTypeState, initialEmployeeTypeState } from "../state/employee-type.state";

export const employeeTypeReducer = createReducer(
  initialEmployeeTypeState,
  on(EmployeeTypeActions.getAllEmployeeType, (state: EmployeeTypeState) => {
    return { ...state, employeeTypes: [], isLoading: true, isSaving: false, error: null };
  }),
  on(EmployeeTypeActions.getAllEmployeeTypeSuccess, (state: EmployeeTypeState, { models }) => {
    return { ...state, employeeTypes: models, isLoading: false, isSaving: false, error: null };
  }),
  on(EmployeeTypeActions.getAllEmployeeTypeFailure, (state: EmployeeTypeState, { error }) => {
    return { ...state, isLoading: false, isSaving: false, error };
  }),

);