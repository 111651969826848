import { createAction, props } from "@ngrx/store";

export const toastSuccessAction = createAction(
  '[Toast] Success',
  props<{ message?: string }>()
);

export const toastErrorAction = createAction(
  '[Toast] Create Error',
  props<{ message?: string }>()
);


export type ToastSuccessAction = ReturnType<typeof toastSuccessAction>;
export type ToastErrorAction = ReturnType<typeof toastErrorAction>;