<form #form="ngForm">
  <div class="d-flex flex-row-reverse">
    <button type="button" class="btn btn-primary btn-sm" 
            *ngIf="hasEditButton" 
            (click)="onEdit()">
      <i class="fas fa-edit"></i> Edit
    </button>
    <button type="button" class="btn btn-primary btn-sm ml-05" *ngIf="canUpdate" (click)="openPopconfirm($event)">
      <i class="fas fa-undo"></i> Cancel
    </button>
    <button type="button" class="btn btn-primary btn-sm" *ngIf="canUpdate" (click)="onSave()">
      <span class="spinner-border spinner-border-sm" role="status" *ngIf="(isSaving$ | async)" aria-hidden="true"></span>
      <i class="fas fa-save ml-025"></i>  Save
    </button>
  </div>
  <div class="row">
    <div class="col-md-3">
      <mdb-form-control class="col-12 margin" >
        <input  mdbInput type="text" id="title" name="title" class="form-control" [(ngModel)]="model.title"  
                [disabled]="hasHrAdminPermissions === false"  
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="title">Title <span class="noteColor">**</span></label>
      </mdb-form-control>     
      <div class="col-md-12">
        <div class="md-form form-group marginSelect" >
					<label for="supervisorEmployeeId">Supervisor <span class="noteColor">**</span></label> <br/>
          <ng-select [items]="(supervisorEmployees$ | async)!"
                     bindLabel="displayName"
                     mdbInput
                     [trackByFn]="trackByFnEmployee"
                     [minTermLength]="2"
                     [loading]="(supervisorEmployeesLoading$ | async) ?? false"
                     typeToSearchText="Type to search supervisor"
                     [typeahead]="supervisorEmployeeInput$"
                     [(ngModel)]="model.supervisorEmployee"
                     labelForId="supervisorEmployeeId"
                     name="supervisorEmployee"
                     [disabled]="isReadOnly || (hasHrAdminPermissions === false)" >
          </ng-select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="md-form form-group marginSelect" >
          <label for="employeeType">Employee Type</label> <br/>
          <ng-select  [items]="(employeeTypes$ | async)"
                      bindLabel="employeeType"
                      bindValue="employeeType"
                      [(ngModel)]="model.employeeType"
                      [loading] = "(employeeTypesLoading$ | async) ?? false"
                      [readonly]="isReadOnly"
                      [disabled]="hasHrAdminPermissions === false"
                      name="employeeType"
                      labelForId="employeeType">
          </ng-select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="md-form form-group marginSelect" >
          <label for="typeOfForm">Type Of Form</label> <br/>
          <ng-select  [items]="typeOfForms"
                      bindValue="value"
                      [(ngModel)]="model.typeOfForm"
                      [readonly]="isReadOnly"
                      [disabled]="!((user$ | async)?.isITSystems) && !((user$ | async)?.isAdmin)"
                      name="typeOfForm" 
                      labelForId="typeOfForm">
          </ng-select>
        </div>
      </div>    
    </div>
    <div class="col-md-3">
      <div class="col-md-12">
        <div class="md-form form-group marginSelect" >
          <label for="officeId">Office <span class="noteColor">**</span></label> <br/>
          <ng-select  [items]="(offices$ | async)!"
                      bindLabel="name"
                      bindValue="officeId"
                      [(ngModel)]="model.officeId" 
                      [loading] = "(officesLoading$ | async) ?? false"
                      name="office"
                      [disabled]="isReadOnly || (hasHrAdminPermissions === false)"
                      labelForId="officeId">
          </ng-select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="md-form form-group marginSelect" >
					<label for="physicalOfficeId">Physical Office</label> <br/>
          <ng-select  [items]="(physicalOffices$ | async)!"
                      bindLabel="name"
                      bindValue="officeId"
                      [(ngModel)]="model.physicalOfficeId" 
                      [loading] = "(officesLoading$ | async) ?? false"
                      name="physicalOffice"
                      [disabled]="isReadOnly || (hasHrAdminPermissions === false)" 
                      labelForId="physicalOfficeId">
          </ng-select>
        </div>
      </div>
      <div class="form-check col-10">
        <input mdbCheckbox class="form-check-input" type="checkbox" name="isHybrid"
              id="isHybrid" [(ngModel)]="model.isHybrid" [ngModelOptions]="{standalone: true}"
              [disabled]="(isReadOnly) || (hasHrAdminPermissions === false)" />
        <label class="form-check-label" for="isHybrid"> Hybrid? </label>
      </div>
      <mdb-form-control class="col-12 margin" >
        <input mdbInput type="text" id="roomNumber" name="roomNumber" class="form-control" [(ngModel)]="model.roomNumber" 
              [disabled]="!((user$ | async)?.isFacilties) && !((user$ | async)?.isAdmin)"  [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="roomNumber">Room#</label>
      </mdb-form-control>
      <mdb-form-control class="col-12 margin" >
        <input mdbInput type="text" id="floor" name="floor" class="form-control" [(ngModel)]="model.floor" 
              [disabled]="!((user$ | async)?.isFacilties) && !((user$ | async)?.isAdmin)"  [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="floor">Floor</label>
      </mdb-form-control>
    </div>
    <div class="col-md-3">
      <div class="col-md-12">
        <div class="md-form form-group marginSelect" >
          <label for="department" class="{{!((user$ | async)?.isHR) ? 'disabled' : ''}}">Department <span class="noteColor">**</span></label> <br/>
          <ng-select  [(ngModel)]="model.departmentId" name="dept" bindlabel="name" 
                      [disabled]="isReadOnly || (hasHrAdminPermissions === false)">
            <ng-option *ngFor="let temp of (departments$ | async)" [value]="temp.departmentId">{{temp.name}}</ng-option>
          </ng-select>      
        </div>
      </div>
      <div class="col-md-12">
        <div class="md-form form-group marginSelect" >
					<label for="sbuId">SBU <span class="noteColor">**</span></label> <br/>
          <ng-select  [items]="(sbus$ | async)!"
                      bindLabel="name"
                      bindValue="sbuId"
                      [(ngModel)]="model.sbuId" 
                      name="sbu"
                      [disabled]="isReadOnly || (hasHrAdminPermissions === false)"
                      labelForId="sbuId">
          </ng-select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="md-form form-group marginSelect" >
          <label for="psc">PSC <span class="noteColor">**</span></label> <br/>
          <ng-select  [items]="(pscs$ | async)!"
                      bindLabel="name"
                      [multiple]="true"
                      [(ngModel)]="pscEmployeeModel" 
                      name="psc"
                      [disabled]="isReadOnly || (hasHrAdminPermissions === false)"
                      labelForId="psc">
          </ng-select>
        </div>
      </div>   
    </div>
    <div class="col-md-3">
      <div class="form-check col-10">
        <input mdbCheckbox class="form-check-input" type="checkbox"  name="isExecutiveBoard"
              id="isExecutiveBoard" [(ngModel)]="model.isExecutiveBoard" [ngModelOptions]="{standalone: true}"
              [disabled]="(isReadOnly) || (hasHrAdminPermissions === false)" />
        <label class="form-check-label" for="isExecutiveBoard"> Member of Executive Board? </label>
      </div>
      <div class="form-check col-10">
        <input mdbCheckbox class="form-check-input" type="checkbox"  name="isSeniorLeadershipTeam"
              id="isSeniorLeadershipTeam" [(ngModel)]="model.isSeniorLeadershipTeam" [ngModelOptions]="{standalone: true}"
              [disabled]="(isReadOnly) || (hasHrAdminPermissions === false)" />
        <label class="form-check-label" for="isSeniorLeadershipTeam"> Member of Senior Leadership Team? </label>
      </div>
      <div class="form-check col-10">
        <input mdbCheckbox class="form-check-input" type="checkbox"  name="isOMP"
              id="isOMP" [(ngModel)]="model.isOMP" [ngModelOptions]="{standalone: true}"
              [disabled]="(isReadOnly) || (hasHrAdminPermissions === false)" />
        <label class="form-check-label" for="isOMP"> Office Managing Partners? </label>
      </div>
      <div class="form-check col-10">
        <input mdbCheckbox class="form-check-input" type="checkbox"  name="isGNC"
              id="isGNC" [(ngModel)]="model.isGNC" [ngModelOptions]="{standalone: true}"
              [disabled]="(isReadOnly) || (hasHrAdminPermissions === false)" />
        <label class="form-check-label" for="isGNC"> Member of Governance Nominating Committee? </label>
      </div>
      <div class="mt-3">
        <mdb-form-control>
          <textarea mdbInput class="form-control" id="textAreaExample" rows="6" name="textAreaExample"
                    [(ngModel)]="model.correspondenceSigDisclaimer"
                    [readonly]="isReadOnly"
                    [disabled]="hasAdminPermissions===false" ></textarea>
          <label mdbLabel class="form-label" for="textAreaExample">Correspondence Sig Disclaimer</label>
        </mdb-form-control>
      </div>
    </div>
  </div>
  <div>
    <span class="text-ukg">** Data is synced nightly from UKG.</span>
  </div>
</form>