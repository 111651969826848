import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { IEmployeeEducation } from 'src/app/models/employee-education.model';
import { IEmployee } from 'src/app/models/employee.model';
import { Profile } from 'src/app/models/user.model';
import { getAllEmployeeEducation } from 'src/app/store/actions/employee-education.actions';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { accessToken, user } from 'src/app/store/state/auth.state';

@Component({
  selector: 'app-employee-education',
  templateUrl: './employee-education.component.html',
  styleUrls: ['./employee-education.component.scss']
})
export class EmployeeEducationComponent implements OnInit {
  
  @Input()  
  employeeId: string | null | undefined;
  
  employeeEducation$: Observable<IEmployeeEducation[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  user$!: Observable<Profile | null>;
  modelCopy: IEmployee = {};

  constructor(private store: Store<RootState>) 
  {}

  ngOnInit(): void {
    this.employeeEducation$ = this.store.select(selectors.employeeEducation.getAll);
    this.isLoading$ = this.store.select(selectors.employeeEducation.isLoading);
    this.user$ = this.store.pipe(select(user));
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
          this.getEmployeeEducation();
      }
    });
  }

  getEmployeeEducation() {
    if(this.employeeId) {
      this.store.dispatch(getAllEmployeeEducation({queryParams:{employeeId: this.employeeId }}));
    }
  }
}