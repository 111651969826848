import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-modal-cancel',
  templateUrl: './modal-cancel.component.html',
  styleUrls: ['./modal-cancel.component.scss']
})

export class ModalCancelComponent {

  title: string | null = null;

  constructor(public modalRef: MdbModalRef<ModalCancelComponent>
              , private readonly router:Router) 
  {}

  redirect(): void {
    if(this.title === "Department"){
      this.router.navigate(['/departments']);
    }
    else if(this.title === "SupportTeam"){
      this.router.navigate(['/support-teams']);
    }
    else if(this.title === "Committee"){
      this.router.navigate(['/committees']);
    }
    else if(this.title === "Office"){
      this.router.navigate(['/offices']);
    }
    else if(this.title === "SBU"){
      this.router.navigate(['/sbus']);
    }
    else if(this.title === "PSC"){
      this.router.navigate(['/pscs']);
    }
    else if(this.title === "Committee-New" 
            || this.title === "SupportTeam-New"
            || this.title === "Department-New"
            || this.title === "Office-New"
            || this.title === "Employee-New"
            || this.title === "Language-New"
            || this.title === "BarAdmission-New")
    {
      this.router.navigate(['/']);
    }
  }

}
