import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeLanguageActions from '../actions/employee-language.actions';
import { EmployeeLanguageService } from 'src/app/services/employee-language.service';
import { IEmployeeLanguage } from 'src/app/models/employee-language.model';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';

@Injectable()
export class EmployeeLanguageEffects {
  
  private actions$ = inject(Actions);

  constructor(
    private employeeLanguageService: EmployeeLanguageService
  ) {}

  createEmployeeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeLanguageActions.createEmployeeLanguage),
      mergeMap((action: EmployeeLanguageActions.CreateEmployeeLanguageAction) => {
        return this.employeeLanguageService.create(action.model, action.includeChildren).pipe(
          concatMap((model: IEmployeeLanguage) => {
            return [
              EmployeeLanguageActions.createEmployeeLanguageSuccess({ model }),
              EmployeeLanguageActions.getAllEmployeeLanguage({ queryParams: { employeeId: model.employeeId } }),
              toastSuccessAction({ message: 'Language added successfully' })  
            ];
          }),
          catchError(error => {
            return of(
              EmployeeLanguageActions.createEmployeeLanguageFailure({ error }),
              toastErrorAction({ message: 'Failed to add language' })
            );
          })
        );
      })
    )
  );

  deleteEmployeeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeLanguageActions.deleteEmployeeLanguage),
      mergeMap((action: EmployeeLanguageActions.DeleteEmployeeLanguageAction) => {
        return this.employeeLanguageService.delete(action.id).pipe(
          concatMap((model: IEmployeeLanguage) => {
            return [
              EmployeeLanguageActions.deleteEmployeeLanguageSuccess(),
              EmployeeLanguageActions.getAllEmployeeLanguage({ queryParams: { employeeId: model.employeeId } }),
              toastSuccessAction({ message: 'Employee language deleted successfully' })
            ];
          }),
          catchError(error => {
            return of(
              EmployeeLanguageActions.deleteEmployeeLanguageFailure({ error }),
              toastErrorAction({ message: 'Failed to delete language' })
            );
          })
        );
      })
    )
  );

  getAllEmployeeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeLanguageActions.getAllEmployeeLanguage),
      mergeMap((action: EmployeeLanguageActions.GetAllEmployeeLanguageAction) => {
        return this.employeeLanguageService.getAll(action.queryParams).pipe(
          map((models: IEmployeeLanguage[]) => {
            return EmployeeLanguageActions.getAllEmployeeLanguageSuccess({ models });
          }),
          catchError(error => {
            return of(
              EmployeeLanguageActions.getAllEmployeeLanguageFailure({ error }),
              toastErrorAction({ message: 'Failed to get languages' })
            );
          })
        );
      })
    )
  );

  updateAllEmployeeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeLanguageActions.updateAllEmployeeLanguage),
      mergeMap((action: EmployeeLanguageActions.UpdateAllEmployeeLanguageAction) => {
        return this.employeeLanguageService.updateAll(action.models).pipe(
          concatMap(() => {
            return [
              EmployeeLanguageActions.getAllEmployeeLanguage({queryParams: { employeeId: action.models[0].employeeId } }),
              EmployeeLanguageActions.updateAllEmployeeLanguageSuccess( { models: action.models }),
              toastSuccessAction({ message: 'Languages updated successfully' })              
            ];
          }),
          catchError(error => {
            return of(
              EmployeeLanguageActions.updateAllEmployeeLanguageFailure({ error }),
              toastErrorAction({ message: 'Failed to update languages' })
            );
          })
        );
      })
    )
  );  
  
}