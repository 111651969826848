import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IOffice } from '../../models/office.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { select, Store } from '@ngrx/store';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';
import { Observable, map, of } from 'rxjs';
import { Profile } from 'src/app/models/user.model';
import { Title } from '@angular/platform-browser';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.scss']
})
export class OfficesComponent implements OnInit {

  @ViewChild('table') table!: MdbTableDirective<IOffice>;
  offices$: Observable<IOffice[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  headers = ['name', 'Phone', 'Address', 'Admin', 'Active'];
  user$!: Observable<Profile | null>;

  constructor(private readonly title: Title
              , private readonly store: Store<RootState>
              , private readonly router:Router) { }

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectors.offices.isLoading);
    this.offices$ = this.store.select(selectors.offices.get).pipe(
      map(offices => Array.isArray(offices) ? offices : [])
    );
    this.user$ = this.store.pipe(select(user));
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
          this.store.dispatch(CrudActions.get(Feature.Offices)());
        }
    })
    this.title.setTitle(`Offices`);
  }

  handleClick(office: IOffice) {
    this.router.navigate(['/offices/', office.officeId]);
  }

  normalizePhoneFormat(phone? : string) {
    phone = phone?.replace(/[^\d]/g, "");
    if (phone?.length == 10) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return null;
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.table.search(searchTerm);
  }

}
