<form #form="ngForm">
  <div class="d-flex flex-row-reverse">
    <button type="button" class="btn btn-primary btn-sm" 
            *ngIf="hasEditButton" 
            (click)="onEdit()">
      <i class="fas fa-edit"></i> Edit
    </button>
    <button type="button" class="btn btn-primary btn-sm ml-05" *ngIf="canUpdate" 
            (click)="openPopconfirm($event)">
      <i class="fas fa-undo"></i> Cancel
    </button>
    <button type="button" class="btn btn-primary btn-sm" *ngIf="canUpdate" (click)="onSave()">
      <span class="spinner-border spinner-border-sm" role="status" *ngIf="(isSaving$ | async)" aria-hidden="true"></span>
      <i class="fas fa-save ml-025"></i>  Save
    </button>
  </div>
	<div class="row ml-1 mt-1 mb-1 mr-1">
    <div class="col-md-2"> 
      <div class="form-check col-12 mt-1">
        <input mdbCheckbox class="form-check-input" type="checkbox"  
              id="isActive" [(ngModel)]="model.isActive" [ngModelOptions]="{standalone: true}"
              [disabled]="isReadOnly || (hasHrItAdminPermissions === false)"/>
        <label class="form-check-label" for="isActive"> Active? </label>
      </div>
    </div>
		<div class="col-md-4">
    <app-datepicker datepickerName="startdatepicker" 
                    fieldName="startDate"
                    [(inputModel)]="model.startDate"
                    [disabled]="isReadOnly || (hasHrItAdminPermissions === false)"
                    [fromHR]="true"
                    label="Start Date"
                    [showClearButton]="true"
                    className="col-10 margin">
    </app-datepicker>

    <app-datepicker datepickerName="anniversarydatepicker" 
                    fieldName="anniversaryDate"
                    [(inputModel)]="model.anniversaryDate"
                    [disabled]="isReadOnly || (hasHrItAdminPermissions === false)"
                    [fromHR]="true"
                    label="Anniversary Date"
                    [showClearButton]="false"
                    className="col-10 margin">
    </app-datepicker>
    
    <app-datepicker datepickerName="resignationdatepicker" 
                    fieldName="resignationDate"
                    [(inputModel)]="model.resignationDate"
                    [disabled]="isReadOnly || (hasHrItAdminPermissions === false)"
                    [fromHR]="true"
                    label="Resignation Date"
                    [showClearButton]="true"
                    className="col-10 margin">
    </app-datepicker>
    <app-datepicker datepickerName="rehiredatepicker" 
                    fieldName="rehireDate"
                    [(inputModel)]="model.rehireDate"
                    [disabled]="isReadOnly || (hasHrItAdminPermissions === false)"
                    [fromHR]="true"
                    label="Rehire Date"
                    [showClearButton]="true"
                    className="col-10 margin">
    </app-datepicker>
		</div>	
	</div>
  <div>
    <span class="text-ukg">** Data is synced nightly from UKG.</span>
  </div>
</form>