<div class="media">
  <div class="media-body ml-05">
    <div class="row">
      <div class="col-md-4"><h4>Add New Office</h4></div>
      <div class="col-md-3 offset-md-5 text-end">
        <button type="button" class="btn btn-primary btn-sm" (click)="save()">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving$ | async" aria-hidden="true"></span>
          <i class="fas fa-save ml-025"></i>  Save</button>
        <button class="btn btn-primary btn-sm ml-05" (click)="openCancelModal()">
          <i class="fas fa-undo"></i> Cancel
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <form>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'name'" [labelHtml]="'Name'" [(fieldValue)]="model.name" [maxLength]="100"></app-input-text>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'description'" [labelHtml]="'Description'" [(fieldValue)]="model.description"></app-input-text>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'officeCode'" [labelHtml]="'Office Code'" [(fieldValue)]="model.officeCode"  [maxLength]="10"></app-input-text>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="isVirtual" [(ngModel)]="model.isVirtual" [ngModelOptions]="{standalone: true}"   />
        <label class="form-check-label" for="isVirtual"> Virtual? </label>
      </mdb-form-control>       
      <app-input-number [cssClass]="'col-6'"  [fieldName]="'extensionPrefix'" [labelHtml]="'Extension Prefix'" [(fieldValue)]="model.extensionPrefix"></app-input-number>     
      <app-input-phone [cssClass]="'col-6'"  [fieldName]="'phone'" [labelHtml]="'Phone'" [(fieldValue)]="model.phone"></app-input-phone>     
      <app-input-phone [cssClass]="'col-6'"  [fieldName]="'fax'" [labelHtml]="'Fax'" [(fieldValue)]="model.fax"></app-input-phone>     
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'street1'" [labelHtml]="'Street1'" [(fieldValue)]="model.street1" [maxLength]="100"></app-input-text>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'street2'" [labelHtml]="'Street2'" [(fieldValue)]="model.street2" [maxLength]="100"></app-input-text>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'street3'" [labelHtml]="'Street3'" [(fieldValue)]="model.street3" [maxLength]="100"></app-input-text>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'city'" [labelHtml]="'City'" [(fieldValue)]="model.city" [maxLength]="100"></app-input-text>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'state'" [labelHtml]="'State'" [(fieldValue)]="model.state" [maxLength]="100"></app-input-text>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'zip'" [labelHtml]="'Zip'" [(fieldValue)]="model.zip" [maxLength]="50"></app-input-text>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'country'" [labelHtml]="'Country'" [(fieldValue)]="model.country" [maxLength]="100"></app-input-text>
      <div class="md-form col-md-6">
        <label for="administrator">Administrator</label><br/>
        <ng-select [items]="(administrators$ | async)!"
                   bindLabel="displayName"
                   [trackByFn]="trackByFn"
                   [minTermLength]="2"
                   [loading]="administratorsLoading"
                   typeToSearchText="Please enter 2 or more characters"
                   [typeahead]="administratorInput$"
                   [(ngModel)]="model.administrator"
                   labelForId="administrator"
                   mdbInput
                   name="administrator">
        </ng-select>
        <br />
        <br />
      </div>
    </form>
  </div>
</div>