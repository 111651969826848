import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountInfo, AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { CustomNavigationClient } from '../custom-navigation';
import { protectedResources } from '../auth-config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

	constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
							private msalAuthService: MsalService
							,private readonly router: Router
							,private readonly http: HttpClient) {
		const customNavigationClient = new CustomNavigationClient(this.router);
    this.msalAuthService.instance.setNavigationClient(customNavigationClient);
	}

	getAllAccounts(): AccountInfo[] {
		return this.msalAuthService.instance.getAllAccounts();
	}

	isUserLoggedIn(): boolean {
		return (this.getAllAccounts().length > 0);
  }

	getActiveAccount(): AccountInfo | null {
		return this.msalAuthService.instance.getActiveAccount();
	}

	setActiveAccount(account: AccountInfo) {
		this.msalAuthService.instance.setActiveAccount(account);
	}

	login() {
		if (this.msalGuardConfig.authRequest){
			this.msalAuthService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
		} else {
			this.msalAuthService.loginRedirect();
		}
  }

  logout() {
    this.msalAuthService.logout();
  }


	getTokenSilent() : Promise<AuthenticationResult>{ 
    const currentAccount = this.getActiveAccount();
    const silentRequest = {
      scopes: [...protectedResources.profileApi.scopes],
      account: currentAccount || undefined,
      forceRefresh: true
    };
		return this.msalAuthService.instance.acquireTokenSilent(silentRequest); 
  }
	
	
}