import { HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { apiBaseURL } from '../utils/app-config';
import { RootState } from '../store/root.state';

export class AppBaseService {
  serviceUrl = apiBaseURL;

  constructor(protected store: Store<RootState>) {
  }

  protected getHttpHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  protected getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: this.getHttpHeaders()
    };
  }
}