import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { IEmployeeSupportTeam } from '../models/employee-support-team';
import { Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSupportTeamService extends AppAuthenticatedCrudBaseService<IEmployeeSupportTeam> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'EmployeeSupportTeam', store);
  }

  createDto(model: IEmployeeSupportTeam): IEmployeeSupportTeam {    
    const result: IEmployeeSupportTeam =
    {
      supportTeamEmployeeId: model.supportTeamEmployeeId,
      employeeId: model.employeeId,
      supportTeamId: model.supportTeamId,
      primaryContactSupportTeamMemberId: model.primaryContactSupportTeamMemberId
    };

    return result;
  }

  createListDto(listItems: IEmployeeSupportTeam[]) : IEmployeeSupportTeam[] {
    const results : IEmployeeSupportTeam[] = [];
    for (let i = 0; i < listItems.length; i++) {
      results.push(this.createDto(listItems[i]));
    }
    return results;
  }
}