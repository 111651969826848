
export interface IEmployeeKeyDates {
  employeeId?: number;
  anniversaryDate?: Date;  
  startDate?: Date;
  resignationDate? : Date | null;
  rehireDate? : Date;
  isActive?: boolean;
}

export const EmployeeKeyDatesFeature = {
  FeatureName: 'EmployeeKeyDates',
  ControllerPath: 'EmployeeKeyDates',
  GetFailureMessage: 'Employee Key Dates retrieval failed',
  UpdateSuccessMessage: 'Employee Key Dates updated successfully',
  UpdateFailureMessage: 'Employee Key Dates update failed',
}