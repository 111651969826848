import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { accessToken, azureStorageKey } from 'src/app/store/state/auth.state';
import { azureStorageURL } from 'src/app/utils/app-config';
import { IEmployeeDetailsView } from 'src/app/models/employee-details-view.model';
import { Title } from '@angular/platform-browser';
import { BaseComponent } from '../base/base.component';
import { RootState } from 'src/app/store/root.state';
import { NgSelectComponent } from '@ng-select/ng-select';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EmployeesComponent  extends BaseComponent implements OnInit {
  @ViewChild('scrollViewport', {static: true }) scrollViewport!: CdkVirtualScrollViewport;
  @ViewChild('employeeSearchSelect') ngSelectComponent!: NgSelectComponent;

  filterEmployeeInput$ = new Subject<string>();
  filteredEmloyees$: Observable<IEmployeeDetailsView[]> = of([]);
  isLoadingFiltered$: Observable<boolean> = of(false);
  azureKey$: Observable<string> = new Observable<string>();
  azureImageURL = azureStorageURL;
  
  constructor(private readonly title: Title
              , private store: Store<RootState>) {
    super();
  }

  ngOnInit(): void {
    this.isLoadingFiltered$ = this.store.select(selectors.employees.isLoading );
    this.store.pipe(select(accessToken), takeUntil(this._destroying$)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.searchEmployees();
      }
      this.azureKey$ = this.store.pipe(select(azureStorageKey));
    });
    this.title.setTitle(`Employees`);
  }
  
  private searchEmployees() {
    this.filteredEmloyees$ = this.filterEmployeeInput$.pipe(
      distinctUntilChanged(),
      switchMap((term: string) => {
        this.store.dispatch(CrudActions.get(Feature.Employees)(undefined, { isActive: true, name: term, employeeId: term }));  
        return this.store.select(selectors.employees.get);
      }),
      map(employees => Array.isArray(employees) ? employees : []),
      switchMap((employees: IEmployeeDetailsView[]) => {
        return this.azureKey$.pipe(
          map((azureKey: string) => {
            if(azureKey && employees){
              return employees.map((employee) =>{
                const photoPath = employee.photoName ? employee.photoName : 'null.jpg';
                return {
                  ...employee,
                  imageUrl: `${this.azureImageURL}/employeephotos/${photoPath}${azureKey}`
                }
              })
            }
            return employees;
          })
        );
      })
    );
  }

  onClear(){
    this.ngSelectComponent.handleClearClick();
    this.filterEmployeeInput$.next('');
    this.searchEmployees();
  }

  normalizePhoneFormat(phone? : string) {
    phone = phone?.replace(/[^\d]/g, "");
    if (phone?.length == 10) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return null;
  }
}