import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { IEmployeeBarAdmission } from '../models/employee-bar-admission.model';
import { Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class EmployeeBarAdmissionsService extends AppAuthenticatedCrudBaseService<IEmployeeBarAdmission> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'EmployeeBarAdmission', store);
  }

  createDto(model: IEmployeeBarAdmission): IEmployeeBarAdmission {    
     return model;
  }

  createListDto(employeeBarAdmissions: IEmployeeBarAdmission[]) : IEmployeeBarAdmission[] {
    return employeeBarAdmissions.map(employeeBarAdmission => this.createDto(employeeBarAdmission)); 
  }
}