import { HttpErrorResponse } from "@angular/common/http";

export interface BaseState {
  isLoading: boolean;
  isSaving: boolean;
  canUpdate: boolean;
  error: Error | HttpErrorResponse | null;
}

export const initialBaseState: BaseState = {
  isLoading: false,
  isSaving: false,
  canUpdate: false,
  error: null,
};


export interface CrudState<T> {
  isLoading: boolean;
  isSaving: boolean;
  canUpdate: boolean;
  error: Error | null;
  model: T;
}

export function initialCrudState<T>(): CrudState<T> {
  return {
    ...initialCrudState,
    isLoading: false,
    isSaving: false,
    canUpdate: false,
    error: null,
    model: {} as T, 
  }
}
  
