import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent  {
  @Input() inputType = "text";
  @Input() cssClass: string;
  @Input() labelHtml: string;
  @Input() fieldName: string;
  @Input() fieldValue: string | undefined;  
  @Input() maxLength?: number;
  @Input() pattern?: string;
  @Input() isReadOnly: boolean | null;
  @Input() disabled: boolean | null;
  @Output() fieldValueChange = new EventEmitter<string | undefined>();

  constructor() { 
    this.cssClass = "";
    this.labelHtml = "Field";
    this.fieldName = "";
    this.isReadOnly = false;
    this.disabled = false;
  }
}