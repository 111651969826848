import { createReducer, on } from "@ngrx/store";
import * as EmployeeEducationActions from "../actions/employee-education.actions";
import { EmployeeEducationState, initialEmployeeEducationState } from "../state/employee-education.state";

export const employeeEducationReducer = createReducer(
  initialEmployeeEducationState,
  on(EmployeeEducationActions.getAllEmployeeEducation, (state: EmployeeEducationState) => {
    return { ...state, isLoading: true };
  }),
  on(EmployeeEducationActions.getAllEmployeeEducationSuccess, (state: EmployeeEducationState, { models }) => {
    return { ...state, employeeEducation: models, isLoading: false };
  }),   
  on(EmployeeEducationActions.getAllEmployeeEducationFailure, (state: EmployeeEducationState, { error }) => {
    return { ...state, error: error, isLoading: false };
  }),
);