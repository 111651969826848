import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { EmployeeEducationState } from '../state/employee-education.state';

const state = (state: RootState) => state.employeeEducation;

const isLoading = createSelector(
  state,
  (state: EmployeeEducationState) => state.isLoading
);

const getAll = createSelector(
  state,
  (state: EmployeeEducationState) => state.employeeEducation
);

export const employeeEducationSelectors = {
  isLoading,
  getAll
};