
import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/crud.actions';
import { CrudState } from '../state/crud.state';
import { IFeature } from 'src/app/models/features.model';

function generateBaseReducer<T>(
  initialState: CrudState<T>,
  createAction: Actions.CreateActionType<T>,
  createSuccessAction: Actions.CreateSuccessActionType<T>,
  createFailureAction: Actions.CreateFailureActionType,
  getAction: Actions.GetActionType,
  getSuccessAction: Actions.GetSuccessActionType<T>,
  getFailureAction: Actions.GetFailureActionType,
  updateAction: Actions.UpdateActionType<T>,
  updateSuccessAction: Actions.UpdateSuccessActionType<T>,
  updateFailureAction: Actions.UpdateFailureActionType,
  deleteAction: Actions.DeleteActionType,
  deleteSuccessAction: Actions.DeleteSuccessActionType<T>,
  deleteFailureAction: Actions.DeleteFailureActionType,
  setCanUpdateAction: Actions.SetCanUpdateActionType,
  setIsLoadingAction: Actions.SetIsLoadingActionType,
  setIsSavingAction: Actions.SetIsSavingActionType
) {
  return createReducer(
    initialState,
    on(createAction, (state: CrudState<T>, { model }) => {
      return { ...state, model, isLoading: true, isSaving: true, error: null };
    }),
    on(createSuccessAction, (state: CrudState<T>, { model }) => {
      return { ...state, model, isLoading: false, isSaving: false, error: null };
    }),
    on(createFailureAction, (state: CrudState<T>, { error }) => {
      return { ...state, isLoading: false, isSaving: false, error };
    }),
    on(getAction, (state: CrudState<T>) => {
      return { ...state, isLoading: true, isSaving: false, error: null };
    }),
    on(getSuccessAction, (state: CrudState<T>, { model }) => {
      return { ...state, model, isLoading: false, isSaving: false, error: null };
    }),
    on(getFailureAction, (state: CrudState<T>, { error }) => {
      return { ...state, isLoading: false, isSaving: false, error };
    }),
    on(updateAction, (state: CrudState<T>, { model }) => {
      return { ...state, model, isLoading: true, isSaving: true, error: null };
    }),
    on(updateSuccessAction, (state: CrudState<T>, { model }) => {
      return { ...state, model, isLoading: false, isSaving: false, error: null };
    }),
    on(updateFailureAction, (state: CrudState<T>, { error }) => {
      return { ...state, isLoading: false, isSaving: false, error };
    }),
    on(deleteAction, (state: CrudState<T>) => {
      return { ...state, isLoading: true, isSaving: false, error: null };
    }),
    on(deleteSuccessAction, (state: CrudState<T>, { model }) => {
      return { ...state, model, isLoading: false, isSaving: false, error: null };
    }),
    on(deleteFailureAction, (state: CrudState<T>, { error }) => {
      return { ...state, isLoading: false, isSaving: false, error };
    }),
    on(setCanUpdateAction, (state: CrudState<T>, { canUpdate }) => {
      return { ...state, canUpdate };
    }),
    on(setIsLoadingAction, (state: CrudState<T>, { isLoading }) => {
      return { ...state, isLoading };
    }),
    on(setIsSavingAction, (state: CrudState<T>, { isSaving }) => {
      return { ...state, isSaving };
    })
  );
}

export function generateCrudReducer<T>(initialState: CrudState<T>, feature: IFeature) {
  return (state = initialState, action: Action) => generateBaseReducer<T>(
    state, 
    Actions.CrudActions.create<T>(feature),
    Actions.CrudActions.createSuccess<T>(feature),
    Actions.CrudActions.createFailure(feature), 
    Actions.CrudActions.get(feature),
    Actions.CrudActions.getSuccess<T>(feature),
    Actions.CrudActions.getFailure(feature),
    Actions.CrudActions.update<T>(feature),
    Actions.CrudActions.updateSuccess<T>(feature),
    Actions.CrudActions.updateFailure(feature),
    Actions.CrudActions.delete(feature),
    Actions.CrudActions.deleteSuccess<T>(feature),
    Actions.CrudActions.deleteFailure(feature),
    Actions.CrudActions.setCanUpdate(feature),
    Actions.CrudActions.setIsLoading(feature),
    Actions.CrudActions.setIsSaving(feature)
  )(state, action);
}