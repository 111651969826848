import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { Observable, filter, of, tap } from 'rxjs';
import { IEmployee } from 'src/app/models/employee.model';
import { Profile } from 'src/app/models/user.model';
import { user } from 'src/app/store/state/auth.state';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';

@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss']
})
export class EmployeeProfileComponent implements OnInit, OnChanges {

  @Input()
  model!: IEmployee;

  @Output()
  save = new EventEmitter<IEmployee>();

  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;
  user$!: Observable<Profile | null>;  
  isSaving$: Observable<boolean> = of(false);
  canUpdate$: Observable<boolean> = of(false);
  canUpdate = false;
  isReadOnly = true;
  hasEditButton = false;
  hasHrItAdminPermissions = false;
  hasHrAdminPermissions = false;
  modelCopy: IEmployee = {};
  
  constructor(private store: Store<RootState>
              ,private popconfirmService: MdbPopconfirmService) 
  {}

  ngOnInit(): void {
    this.canUpdate$ = this.store.select(selectors.employee.canUpdate);
    this.isSaving$ = this.store.select(selectors.employee.isSaving);
    this.user$ = this.store.pipe(select(user));
    this.user$.subscribe((user: Profile | null) => {
      if(user) {
        this.hasHrAdminPermissions = user?.isHR|| user?.isAdmin;
        this.hasHrItAdminPermissions = user?.isHR|| user?.isITSystems || user?.isAdmin;
        this.hasEditButton = this.isReadOnly && (this.hasHrItAdminPermissions || this.hasHrAdminPermissions);
      }
    });

    this.user$ = this.store.pipe(
      select(user),
      filter((user: Profile | null): user is Profile => !!user),
      tap((user: Profile) => {
        this.hasHrAdminPermissions = user?.isHR || user?.isAdmin;
        this.hasHrItAdminPermissions = user?.isHR || user?.isITSystems || user?.isAdmin;
        this.hasEditButton = this.isReadOnly && (this.hasHrItAdminPermissions || this.hasHrAdminPermissions);
      })  
    );

    this.canUpdate$.subscribe((canUpdate: boolean) => {
      this.isReadOnly = !canUpdate;
      this.canUpdate = canUpdate;
      this.hasEditButton = this.isReadOnly && (this.hasHrItAdminPermissions || this.hasHrAdminPermissions);
    });
  }

  ngOnChanges() {
    this.modelCopy = {...this.model};
  }

  onEdit() {
    this.store.dispatch(CrudActions.setCanUpdate(Feature.Employee)(true));
  }

  onSave() {    
    this.save.emit({ ...this.model });
    this.store.dispatch(CrudActions.setCanUpdate(Feature.Employee)(false));
  }

  onCancelCheck(){
    return JSON.stringify(this.model) === JSON.stringify(this.modelCopy);
  }

  onCancel() {
    this.model = { ...this.modelCopy };
    this.store.dispatch(CrudActions.setCanUpdate(Feature.Employee)(false));
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }

}