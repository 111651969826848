import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { OfficeService } from '../../services/office.service';
import { IOffice } from '../../models/office.model';
import { EmployeeService } from "../../services/employee.service";
import { IEmployee } from "../../models/employee.model";
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { accessToken } from 'src/app/store/state/auth.state';
import { setImagefolder, setImageUniqueId, setPhotoName } from 'src/app/store/actions/auth.actions';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;
  
  id: string | null = null;
  office: IOffice = { officeId: '', isVirtual: false, isActive: true }
  model: IOffice = { officeId: '', isVirtual: false, isActive: true }
  isLoading = false;
  isLoaded = false;
  isSaving = false;

  constructor(private readonly officeService: OfficeService
                , private readonly  store: Store<RootState>
                , private readonly employeeService: EmployeeService
                , private readonly activatedRoute: ActivatedRoute
                , private notificationService: MdbNotificationService
                , private modalService: MdbModalService
                , private readonly title: Title
                , private readonly router:Router) {  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken && id !== null)
        {
          this.isLoading = true;
          this.getOffice(id);
          this.loadAdministrators();
        }
    })
  }

  administrators$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  administratorsLoading = false;
  administratorInput$ = new Subject<string>();

  private loadAdministrators() {
    this.administrators$ = concat(
      of([]),
      this.administratorInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.administratorsLoading = true),
        switchMap((term: string) => this.employeeService.getAll({ isActive: true, name: term }).pipe(
          catchError(() => of([])),
          tap(() => this.administratorsLoading = false)
        ))
      )
    );
  }

  trackByFn(item: IEmployee) {
    return item.employeeId;
  }

  getOffice(id: string) {
    this.officeService.get(id, { includeAdministrator: true }).subscribe({
      next: (office: IOffice) => {
        this.office = office;
        this.model = { ...office };
        this.setTitle();
        this.isLoading = false;
        this.isLoaded = true;
        this.store.dispatch(setPhotoName({photoName: this.model.photoName ?  this.model.photoName : 'No_Image_Available.jpg'}));
        this.store.dispatch(setImagefolder({folderLocation: 'officephotos'}));
        this.store.dispatch(setImageUniqueId({imageUniqueId: new Date().getTime()}));
      },
      error: () => {
        this.isLoading = false;
        this.isLoaded = false;
      }
    });
   
  }

  setTitle() {
    this.title.setTitle(`Office - ${this.office.name}`);
  }

  updateOffice() {
    this.isSaving = true;
    this.model.administratorEmployeeId = this.model.administrator?.employeeId;
    this.officeService.update(this.model, this.model.officeId).subscribe({
      next: () => {
        this.office = { ...this.model };
        this.setTitle();
        this.isSaving = false;
        this.showSuccess();
      },
      error: () => {
        this.isSaving = false;        
        this.showError();
      }
    });
  }

  save() {
    this.updateOffice();
  }

  cancelUpdate() {
    this.model = { ...this.office };
    this.router.navigate(['/offices']);
  }

  onCancelCheck(){
    return JSON.stringify(this.office) === JSON.stringify(this.model);
  }

  saveFilename(filename?: string) {
    this.office.photoName = filename;
    this.model.photoName = filename;
    this.officeService.update(this.office, this.office.officeId, false).subscribe({
      error: () => {
        this.showError();
      }
    });
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'Office'},
    });    
  }
     
  showSuccess() {    
    this.notificationSuccessRef = this.notificationService.open(
      ToastSuccessComponent,
      { data: { text: 'Office updated!'},
      position: 'bottom-center',
      autohide: true });
  }

  showError() {
    this.notificationSuccessRef = this.notificationService.open(
      ToastErrorComponent,
      { data: { text: 'Something went wrong. Office could not be updated!'},
      position: 'bottom-center',
      autohide: true });
  }

}