import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEmployeeEducation } from 'src/app/models/employee-education.model';

export const getAllEmployeeEducation = createAction(
  '[EmployeeEducation] Get All',
  props<{ queryParams: Record<string, unknown> }>()
);

export const getAllEmployeeEducationSuccess = createAction(
  '[EmployeeEducation] Get All Success',
  props<{ models: IEmployeeEducation[] }>()
);

export const getAllEmployeeEducationFailure = createAction(
  '[EmployeeEducation] Get All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export type GetAllEmployeeEducationAction = ReturnType<typeof getAllEmployeeEducation>;
export type GetAllEmployeeEducationSuccessAction = ReturnType<typeof getAllEmployeeEducationSuccess>;
export type GetAllEmployeeEducationFailureAction = ReturnType<typeof getAllEmployeeEducationFailure>;
