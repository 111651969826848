import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEmployeeType } from 'src/app/models/employee-type.model';

export const getAllEmployeeType = createAction(
  '[EmployeeType] Get All',
  props<{ queryParams: Record<string, unknown> }>()
);

export const getAllEmployeeTypeSuccess = createAction(
  '[EmployeeType] Get All Success',
  props<{ models: IEmployeeType[] }>()
);

export const getAllEmployeeTypeFailure = createAction(
  '[EmployeeType] Get All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export type GetAllEmployeeTypeAction = ReturnType<typeof getAllEmployeeType>;
export type GetAllEmployeeTypeSuccessAction = ReturnType<typeof getAllEmployeeTypeSuccess>;
export type GetAllEmployeeTypeFailureAction = ReturnType<typeof getAllEmployeeTypeFailure>;
