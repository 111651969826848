<div class="d-flex align-items-center" *ngIf="isLoading">
  <strong>Loading...</strong>
  <div class="spinner-border text-success ml-15px" role="status" aria-hidden="true"></div>
</div>

<div class="d-flex">
  <!-- Image -->
  <div class="flex-shrink-0">
    <app-image  [code]="model.officeCode" 
                [isSmallCard]="false"
                [isEmployeePhoto]="false"
                folder="officephotos"
                (save)="saveFilename($event)">
    </app-image>
  </div>
  <!-- Body -->
  <div class="flex-grow-1 ms-3 ml-20px">
    <div class="row">
      <div class="col-md-4"><h4>{{model.name}}</h4></div>
      <div class="col-md-3 offset-md-5 text-end">
        <button type="button" class="btn btn-primary btn-sm" (click)="save()">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving" aria-hidden="true"></span>
          <i class="fas fa-save ml-025"></i> Save
        </button>
        <button type="button" 
                class="btn btn-primary btn-sm ml-05"
                (click)="!onCancelCheck() ? openCancelModal() : cancelUpdate()">
          <i class="fas fa-undo"></i> Cancel
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <form>     
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="name" name="name" class="form-control" [(ngModel)]="model.name" />
        <label mdbLabel class="form-label" for="name">Name</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="description" name="description" class="form-control" [(ngModel)]="model.description" />
        <label mdbLabel class="form-label" for="description">Description</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="officeCode" name="officeCode" class="form-control" [(ngModel)]="model.officeCode" />
        <label mdbLabel class="form-label" for="officeCode">Office Code</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="virtual" [ngModelOptions]="{standalone: true}" [(ngModel)]="model.isVirtual"  />
        <label class="form-check-label" for="virtual"> Virtual? </label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="active" [ngModelOptions]="{standalone: true}" [(ngModel)]="model.isActive"  />
        <label class="form-check-label" for="active"> Active? </label>
      </mdb-form-control>      
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="extensionPrefix" name="extensionPrefix" class="form-control" [(ngModel)]="model.extensionPrefix" />
        <label mdbLabel class="form-label" for="extensionPrefix">Extension Prefix</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="phone" name="phone" class="form-control" [(ngModel)]="model.phone" />
        <label mdbLabel class="form-label" for="phone">Phone</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="fax" name="fax" class="form-control" [(ngModel)]="model.fax" />
        <label mdbLabel class="form-label" for="fax">Fax</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="street1" name="street1" class="form-control" [(ngModel)]="model.street1" />
        <label mdbLabel class="form-label" for="street1">Street 1</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="street2" name="street2" class="form-control" [(ngModel)]="model.street2" />
        <label mdbLabel class="form-label" for="street2">Street 2</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="street3" name="street3" class="form-control" [(ngModel)]="model.street3" />
        <label mdbLabel class="form-label" for="street3">Street 3</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="city" name="city" class="form-control" [(ngModel)]="model.city" />
        <label mdbLabel class="form-label" for="city">City</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="state" name="state" class="form-control" [(ngModel)]="model.state" />
        <label mdbLabel class="form-label" for="state">State</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="zip" name="zip" class="form-control" [(ngModel)]="model.zip" />
        <label mdbLabel class="form-label" for="description">Zip</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-2 mb-3">
        <input mdbInput type="text" id="country" name="country" class="form-control" [(ngModel)]="model.country" />
        <label mdbLabel class="form-label" for="country">Country</label>
      </mdb-form-control>
      <div class="col-8">
        <label for="administrator">Administrator</label><br/>
        <ng-select [items]="(administrators$ | async)!"
                   bindLabel="displayName"
                   [trackByFn]="trackByFn"
                   [minTermLength]="2"
                   [loading]="administratorsLoading"
                   typeToSearchText="Please enter 2 or more characters"
                   [typeahead]="administratorInput$"
                   [(ngModel)]="model.administrator"
                   labelForId="administrator"
                   mdbInput
                   name="administrator">
        </ng-select>
        <br />
        <br />
      </div>
    </form>
  </div>
</div>