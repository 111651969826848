import { createReducer, on } from "@ngrx/store";
import { ProficiencyState, initialProficiencyState } from "../state/proficiency.state";
import * as ProficiencyActions from "../actions/proficiency.actions";

export const proficiencyReducer = createReducer(
  initialProficiencyState,
  on(ProficiencyActions.getAllProficiency, (state: ProficiencyState) => {
    return { ...state, proficiencies: [], isLoading: true, isSaving: false, error: null };
  }),
  on(ProficiencyActions.getAllProficiencySuccess, (state: ProficiencyState, { models }) => {
    return { ...state, proficiencies: models, isLoading: false, isSaving: false, error: null };
  }),
  on(ProficiencyActions.getAllProficiencyFailure, (state: ProficiencyState, { error }) => {
    return { ...state, isLoading: false, isSaving: false, error };
  }),

);