import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEmployeeLanguage } from '../models/employee-language.model';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})

export class EmployeeLanguageService extends AppAuthenticatedCrudBaseService<IEmployeeLanguage> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'EmployeeLanguage', store);
  }

  createDto(model: IEmployeeLanguage): IEmployeeLanguage {    
    const result: IEmployeeLanguage =
    {
      id: model.id,
      employeeId: model.employeeId,
      languageId: model.languageId,
      sortOrder: model.sortOrder,
      proficiency: model.proficiency
    };

    return result;
  }

  createListDto(listItems: IEmployeeLanguage[]) : IEmployeeLanguage[] {
    return listItems.map(this.createDto);
  }
}