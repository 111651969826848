<mdb-form-control class="{{cssClass}} verticalMargin">
  <input  mdbInput 
          type="text"
          maxlength={{maxLength}}
          id={{fieldName}} 
          name={{fieldName}} 
          class="form-control" 
          [attr.maxLength]="maxLength"
          [attr.pattern]="pattern"
          [(ngModel)]="fieldValue"
          [disabled]="disabled ?? false"
          [readonly]="isReadOnly ?? false"
          (ngModelChange)="fieldValueChange.emit(fieldValue)"
          />
  <label mdbLabel class="form-label" for={{fieldName}} [innerHTML]="labelHtml"></label>  
</mdb-form-control>