import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEmployeeContact } from '../models/employee-contact.model';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})

export class EmployeeContactService extends AppAuthenticatedCrudBaseService<IEmployeeContact> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'EmployeeContact', store);
  }

  createDto(model: IEmployeeContact): IEmployeeContact {    
    const result: IEmployeeContact =
    {     
      id: model.id,
      employeeId: model.employeeId,
      directDial: model.directDial,
      workPhone: model.workPhone,
      mobilePhone: model.mobilePhone,
      fax: model.fax,
      homePhone: model.homePhone,
      emergencyPhone: model.emergencyPhone,
      emergencyText: model.emergencyText,
      emergencyEmail: model.emergencyEmail,
      homeAddress1: model.homeAddress1,
      homeAddress2: model.homeAddress2,
      homeCity: model.homeCity,
      homeState: model.homeState,
      homeZip: model.homeZip,
      keepHomePhoneConfidential : model.keepHomePhoneConfidential,
      keepMobilePhoneConfidential : model.keepMobilePhoneConfidential,
    };
    return result;
  }

}
