import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEmployeeLanguage } from 'src/app/models/employee-language.model';

export const createEmployeeLanguage = createAction(
  '[EmployeeLanguage] Create',
  props<{ model: IEmployeeLanguage, includeChildren: boolean }>()
);

export const createEmployeeLanguageSuccess = createAction(
  '[EmployeeLanguage] Create Success',
  props<{ model: IEmployeeLanguage }>()
);

export const createEmployeeLanguageFailure = createAction(
  '[EmployeeLanguage] Create Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const getAllEmployeeLanguage = createAction(
  '[EmployeeLanguage] Get All',
  props<{ queryParams: Record<string, unknown> }>()
);

export const getAllEmployeeLanguageSuccess = createAction(
  '[EmployeeLanguage] Get All Success',
  props<{ models: IEmployeeLanguage[] }>()
);

export const getAllEmployeeLanguageFailure = createAction(
  '[EmployeeLanguage] Get All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const updateAllEmployeeLanguage = createAction(
  '[EmployeeLanguage] Update All',
  props<{ models: IEmployeeLanguage[] }>()
);

export const updateAllEmployeeLanguageSuccess = createAction(
  '[EmployeeLanguage] Update All Success',
  props<{ models: IEmployeeLanguage[] }>()
);

export const updateAllEmployeeLanguageFailure = createAction(
  '[EmployeeLanguage] Update All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const deleteEmployeeLanguage = createAction(
  '[EmployeeLanguage] Delete',
  props<{ id: string | number }>()
);

export const deleteEmployeeLanguageSuccess = createAction(
  '[EmployeeLanguage] Delete Success'  
);

export const deleteEmployeeLanguageFailure = createAction(
  '[EmployeeLanguage] Delete Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const setEmployeeLanguageCanUpdate = createAction(
  '[EmployeeLanguage] Set Can Update',
  props<{ canUpdate: boolean }>()
);

export const setAllEmployeeLanguage = createAction(
  '[EmployeeLanguage] Set All',
  props<{ models: IEmployeeLanguage[] }>()
);

export type CreateEmployeeLanguageAction = ReturnType<typeof createEmployeeLanguage>;
export type CreateEmployeeLanguageSuccessAction = ReturnType<typeof createEmployeeLanguageSuccess>;
export type CreateEmployeeLanguageFailureAction = ReturnType<typeof createEmployeeLanguageFailure>;

export type GetAllEmployeeLanguageAction = ReturnType<typeof getAllEmployeeLanguage>;
export type GetAllEmployeeLanguageSuccessAction = ReturnType<typeof getAllEmployeeLanguageSuccess>;
export type GetAllEmployeeLanguageFailureAction = ReturnType<typeof getAllEmployeeLanguageFailure>;

export type UpdateAllEmployeeLanguageAction = ReturnType<typeof updateAllEmployeeLanguage>;
export type UpdateAllEmployeeLanguageSuccessAction = ReturnType<typeof updateAllEmployeeLanguageSuccess>;
export type UpdateAllEmployeeLanguageFailureAction = ReturnType<typeof updateAllEmployeeLanguageFailure>;

export type DeleteEmployeeLanguageAction = ReturnType<typeof deleteEmployeeLanguage>;
export type DeleteEmployeeLanguageSuccessAction = ReturnType<typeof deleteEmployeeLanguageSuccess>;
export type DeleteEmployeeLanguageFailureAction = ReturnType<typeof deleteEmployeeLanguageFailure>;

export type SetEmployeeLanguageCanUpdateAction = ReturnType<typeof setEmployeeLanguageCanUpdate>;
export type SetAllEmployeeLanguageAction = ReturnType<typeof setAllEmployeeLanguage>;
