import { ISbuLeader } from "./sbu-leader.model";
import { IEmployee } from "./employee.model";
import { IManagingDirector } from "./managing-director.model";

export interface ISbu {
  sbuId?: number;
  sbuCode?: string;
  name?: string;
  photoName?: string;
  isActive: boolean;
  sbuLeaders?: ISbuLeader[];
  employees?: IEmployee[];
  managingDirectors?: IManagingDirector[];
}

export const SbuFeature = {
  FeatureName: 'Sbu',
  ControllerPath: 'sbu',
  CreateSuccessMessage: 'Sbu created successfully',
  CreateFailureMessage: 'Sbu creation failed',
  GetFailureMessage: 'Failed to retrieve Sbus',
  UpdateSuccessMessage: 'Sbu updated successfully',
  UpdateFailureMessage: 'Sbu update failed',
  DeleteSuccessMessage: 'Sbu deleted successfully',
  DeleteFailureMessage: 'Sbu deletion failed',
};

export const SbusFeature = {
  FeatureName: 'Sbus',
  ControllerPath: 'sbu',
  GetFailureMessage: 'Failed to retrieve Sbus',
};