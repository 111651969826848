<div class="d-flex align-items-center" *ngIf="isLoading">
  <strong>Loading...</strong>
  <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
</div>
<div class="d-flex">
  <!-- Image -->
  <div class="flex-shrink-0">
    <app-image  [code]="model.sbuCode" 
                [isSmallCard]="false"
                [isEmployeePhoto]="false"
                folder="sbuphotos"
                (save)="saveFilename($event)">
    </app-image>
  </div>
  <!-- Body -->
  <div class="flex-grow-1 ms-3 ml-05">
    <div class="row">
      <div class="col-md-4"><h4>{{model.name}}</h4></div>
      <div class="col-md-3 offset-md-5 text-right">
        <button type="button" class="btn btn-primary btn-sm" (click)="save()">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving" aria-hidden="true"></span>
          <i class="fas fa-save ml-025"></i> Save
        </button>
        <button type="button" 
                class="btn btn-primary btn-sm ml-05" 
                (click)="!onCancelCheck() ? openCancelModal() : cancelUpdate()">
          <i class="fas fa-undo"></i> Cancel
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <form>     
      <mdb-form-control class="col-8 mt-1 mb-2">
        <input mdbInput type="text" id="name" name="name" class="form-control" [(ngModel)]="model.name" />
        <label mdbLabel class="form-label" for="name">Name</label>
      </mdb-form-control>
      <mdb-form-control class="col-8 mt-1 mb-2">
        <input mdbCheckbox class="form-check-input" type="checkbox" value="" id="active" [(ngModel)]="model.isActive" [ngModelOptions]="{standalone: true}" />
        <label class="form-check-label" for="active"> Active? </label>
      </mdb-form-control>
      <div class="col-8">
        <label for="sbuLeaders">Leaders</label><br/>
        <ng-select [items]="(sbuLeaders$ | async)!"
                   bindLabel="displayName"
                   [trackByFn]="trackByFn"
                   [minTermLength]="2"
                   [loading]="sbuLeadersLoading"
                   typeToSearchText="Please enter 2 or more characters"
                   [typeahead]="sbuLeadersInput$"
                   [(ngModel)]="sbuLeadersModel"
                   labelForId="sbuLeaders"
                   mdbInput
                   name="sbuLeaders"                   
                   [multiple]="true"
                   [maxSelectedItems]="2">
        </ng-select>
      </div>
      <div class="col-8">
        <label for="managingDirectors">Managing Directors</label><br/>
        <ng-select [items]="(managingDirectors$ | async)!"
                   bindLabel="displayName"
                   [trackByFn]="trackByFn"
                   [minTermLength]="2"
                   [loading]="managingDirectorsLoading"
                   typeToSearchText="Please enter 2 or more characters"
                   [typeahead]="managingDirectorsInput$"
                   [(ngModel)]="managingDirectorsModel"
                   labelForId="managingDirectors"
                   mdbInput
                   name="managingDirectors"                   
                   [multiple]="true"
                   [maxSelectedItems]="2">
        </ng-select>
      </div>
      <div class="col-8">
        <label for="employees">Employees</label><br/>
        <ng-select [items]="(employees$ | async)!"
                   bindLabel="displayName"
                   [trackByFn]="trackByFn"
                   [minTermLength]="2"
                   [loading]="employeesLoading"
                   typeToSearchText="Please enter 2 or more characters"
                   [typeahead]="employeesInput$"
                   [(ngModel)]="employeesModel"
                   labelForId="employees"
                   mdbInput
                   name="employees"                   
                   [multiple]="true">
        </ng-select>
        <br />
        <br />        
      </div>   
    </form>
  </div>
</div>