<div class="media">
  <div class="ml-05">
    <div class="col-md-4"><h4>Submit Request</h4></div>
    <div>
      <span>Please enter a description of the change.</span>
    </div>
    <div class="col-md-9">
      <div class="mb-3">
        <mdb-form-control>
          <textarea mdbInput class="form-control" id="textAreaExample" rows="4" [(ngModel)]="model.request"></textarea>        
        </mdb-form-control>
      </div>
      <div class="clearfix"></div>
      <button type="button" class="btn btn-primary btn-sm" (click)="save()">
        <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving$ | async" aria-hidden="true"></span>
        <i class="fas fa-paper-plane ml-025"></i> Send
      </button>
    </div>
  </div>
</div>