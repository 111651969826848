import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { Observable, of } from 'rxjs';
import { IEmployeeSupportTeam } from 'src/app/models/employee-support-team';
import { ISupportTeam } from 'src/app/models/support-team.model';
import { Profile } from 'src/app/models/user.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { DeleteConfirmComponent } from '../helpers/delete-confirm/delete-confirm.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { RootState } from 'src/app/store/root.state';
import { createEmployeeSupportTeam, deleteEmployeeSupportTeam, getAllEmployeeSupportTeam, setAllEmployeeSupportTeam, setEmployeeSupportTeamCanUpdate, updateAllEmployeeSupportTeam } from 'src/app/store/actions/employee-support-team.actions';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';

@Component({
  selector: 'app-employee-support-team',
  templateUrl: './employee-support-team.component.html',
  styleUrls: ['./employee-support-team.component.scss']
})
export class EmployeeSupportTeamComponent implements OnInit {

  @Input()
  employeeId: string | null | undefined;
  
  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;
  deleteconfirmRef: MdbPopconfirmRef<DeleteConfirmComponent> | null = null;
  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
  templateModalRef!: MdbModalRef<Element>;
  user$!: Observable<Profile | null>;
  canUpdate$: Observable<boolean> = of(false);  
  isLoading$: Observable<boolean> = of(false);
  isSupportTeamLoading$: Observable<boolean> = of(false);
  employeeSupportTeams$: Observable<IEmployeeSupportTeam[]> = of([]);
  employeeSupportTeams: IEmployeeSupportTeam[] = [];
  employeeSupportTeamsCopy: IEmployeeSupportTeam[] = [];
  supportTeams$: Observable<ISupportTeam[]> = of([]);
  newEmployeeSupportTeam: IEmployeeSupportTeam = {};
  employeeSupportTeamDeleteId : number | undefined;
  
  @ViewChild('template') modalTemplate!: TemplateRef<Element>;

  constructor(  private readonly store: Store<RootState>
              , private readonly modalService: MdbModalService
              , private popconfirmService: MdbPopconfirmService ) 
  {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectors.employeeSupportTeam.isLoading);    
    this.employeeSupportTeams$ = this.store.select(selectors.employeeSupportTeam.getAll)
    this.canUpdate$ = this.store.select(selectors.employeeSupportTeam.canUpdate);
    this.isSupportTeamLoading$ = this.store.select(selectors.supportTeams.isLoading);    
    this.supportTeams$ = this.store.select(selectors.supportTeams.get);
    this.user$ = this.store.pipe(select(user));
    this.store.dispatch(CrudActions.get(Feature.SupportTeams)());
    this.store.select(selectors.employeeSupportTeam.getAll).subscribe((employeeSupportTeams: IEmployeeSupportTeam[]) => {
      if(!employeeSupportTeams) return;
      this.employeeSupportTeams = employeeSupportTeams.map((supportTeam: IEmployeeSupportTeam) => ({ ...supportTeam }));
      this.employeeSupportTeamsCopy = [...employeeSupportTeams];
    });
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken && this.employeeId) {
        this.getEmployeeSupportTeams(this.employeeId);
      }
    })
  }
  
  getEmployeeSupportTeams(id: string) {
    this.store.dispatch(getAllEmployeeSupportTeam({ queryParams: { employeeId: id } }));
  }

  remove() {
    if(this.employeeSupportTeamDeleteId){
      this.store.dispatch(deleteEmployeeSupportTeam({ id: this.employeeSupportTeamDeleteId }));
    }
  }

  add() {
    if(this.employeeId){
      this.newEmployeeSupportTeam.employeeId = parseInt(this.employeeId);
      this.newEmployeeSupportTeam.supportTeamId = this.newEmployeeSupportTeam?.supportTeam?.supportTeamId;
      this.store.dispatch(createEmployeeSupportTeam({ model: this.newEmployeeSupportTeam, includeChildren: false }));
      this.newEmployeeSupportTeam = {};
    }
    this.templateModalRef.close();
  }

  onSave() {
    this.employeeSupportTeams.forEach((item) =>{
      item.supportTeamId = item.supportTeam?.supportTeamId;
    })
    this.store.dispatch(updateAllEmployeeSupportTeam({ models: this.employeeSupportTeams })); 
  }
  
  onEdit() {
   this.store.dispatch(setEmployeeSupportTeamCanUpdate({canUpdate: true}));
  }

  onCancelCheck(){
    return JSON.stringify(this.employeeSupportTeams) === JSON.stringify(this.employeeSupportTeamsCopy);
  }

  onCancel() {
    this.store.dispatch(setAllEmployeeSupportTeam({ models: this.employeeSupportTeamsCopy }));
  }

  setDeleteId(id: number | undefined){
    this.employeeSupportTeamDeleteId = id;
  }

  onChangeSupportTeam(supportteam :IEmployeeSupportTeam){    
    supportteam.primaryContactSupportTeamMemberId = undefined;
  }
  onChange() {
    this.newEmployeeSupportTeam.primaryContactSupportTeamMemberId = undefined;
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }

  deletePopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.deleteconfirmRef = this.popconfirmService.open(DeleteConfirmComponent, target, { popconfirmMode: 'modal' });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.deleteconfirmRef.onConfirm.subscribe(() => {            
      this.remove();
    });
  }
 
  openAddNewModal() {
    this.templateModalRef = this.modalService.open(this.modalTemplate);
  }

}
