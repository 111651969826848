import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IPsc } from '../../models/psc.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { select, Store } from '@ngrx/store';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';
import { Observable, map, of } from 'rxjs';
import { Profile } from 'src/app/models/user.model';
import { Title } from '@angular/platform-browser';
import { RootState } from 'src/app/store/root.state';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';
import { selectors } from 'src/app/store/selectors/selectors';

@Component({
  selector: 'app-pscs',
  templateUrl: './pscs.component.html',
  styleUrls: ['./pscs.component.scss']
})
export class PscsComponent implements OnInit {

  @ViewChild('table') table!: MdbTableDirective<IPsc>;
  pscs$: Observable<IPsc[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  user$!: Observable<Profile | null>;
  headers = ['name', 'code', 'active'];

  constructor(private readonly title: Title
              , private readonly store: Store<RootState>
              , private readonly router:Router
  ) { }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(user));
    this.pscs$ = this.store.select(selectors.pscs.get).pipe(
      map(pscs => Array.isArray(pscs) ? pscs : [])  
    );
    this.isLoading$ = this.store.select(state => state.pscs.isLoading);
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.store.dispatch(CrudActions.get(Feature.Pscs)());
      }
    });
    this.title.setTitle(`Pscs`);
  }

  handleClick(psc: IPsc) {
    this.router.navigate(['/pscs/', psc.pscId]);
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.table.search(searchTerm);
  }
  
}
