import { createReducer, on } from "@ngrx/store";
import * as EmployeeSupportTeamActions from "../actions/employee-support-team.actions";
import { EmployeeSupportTeamState, initialEmployeeSupportTeamState } from "../state/employee-support-team.state";

export const employeeSupportTeamReducer = createReducer(
  initialEmployeeSupportTeamState,
  on(EmployeeSupportTeamActions.createEmployeeSupportTeam, (state: EmployeeSupportTeamState, { model }) => {
    return { ...state, 
        employeeSupportTeams: [...state.employeeSupportTeams, model], 
        isLoading: true, 
        canUpdate: false, 
        error: null};
  }),
  on(EmployeeSupportTeamActions.createEmployeeSupportTeamSuccess, (state: EmployeeSupportTeamState, { model }) => {
    return { ...state, 
      currentEmployeeSupportTeam: model, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeSupportTeamActions.createEmployeeSupportTeamFailure, (state: EmployeeSupportTeamState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeSupportTeamActions.deleteEmployeeSupportTeam, (state: EmployeeSupportTeamState) => { 
    return { ...state, 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeSupportTeamActions.deleteEmployeeSupportTeamSuccess, (state: EmployeeSupportTeamState) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeSupportTeamActions.deleteEmployeeSupportTeamFailure, (state: EmployeeSupportTeamState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeSupportTeamActions.getAllEmployeeSupportTeam, (state: EmployeeSupportTeamState) => {
    return { ...state, 
      employeeSupportTeams: [], 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeSupportTeamActions.getAllEmployeeSupportTeamSuccess, (state: EmployeeSupportTeamState, { models }) => {
    return { ...state, 
      employeeSupportTeams: models, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeSupportTeamActions.getAllEmployeeSupportTeamFailure, (state: EmployeeSupportTeamState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeSupportTeamActions.setEmployeeSupportTeamCanUpdate, (state: EmployeeSupportTeamState, { canUpdate }) => {
    return { ...state, canUpdate };
  }),
  on(EmployeeSupportTeamActions.setAllEmployeeSupportTeam, (state: EmployeeSupportTeamState, { models }) => {
    return { ...state, 
      employeeSupportTeams: models,
      isLoading: false, 
      canUpdate: false, 
     };
  }),
  on(EmployeeSupportTeamActions.updateAllEmployeeSupportTeam, (state: EmployeeSupportTeamState, { models }) => {
    return { ...state, 
      employeeSupportTeams: models, 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeSupportTeamActions.updateAllEmployeeSupportTeamSuccess, (state: EmployeeSupportTeamState, { models }) => {
    return { ...state, 
      employeeSupportTeams: models, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeSupportTeamActions.updateAllEmployeeSupportTeamFailure, (state: EmployeeSupportTeamState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }), 
 
);