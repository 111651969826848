import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ICommitteeEmployee } from 'src/app/models/committee-employee.model';
import { ICommittee } from 'src/app/models/committee.model';
import { IEmployee } from 'src/app/models/employee.model';
import { CommitteeService } from 'src/app/services/committee.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { accessToken } from 'src/app/store/state/auth.state';
import { setImagefolder, setImageUniqueId, setPhotoName } from 'src/app/store/actions/auth.actions';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'app-committee',
  templateUrl: './committee.component.html',
  styleUrls: ['./committee.component.scss']
})
export class CommitteeComponent implements OnInit {

  modalRef: MdbModalRef<ModalCancelComponent> | null = null;
  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
  
  id: string | null = null;
  committee: ICommittee = { isActive: true, committeeEmployees: [] }
  model: ICommittee = { isActive: true, committeeEmployees: [] }
  committeeMembersModel: IEmployee[] = [];
  mode = 'read';  
  file: File | null = null;
  defaultPhoto = '';
  isLoading = false;
  isLoaded = false;
  isSaving = false;

  constructor(private readonly committeeService: CommitteeService
              , private readonly store: Store<RootState>
              , private notificationService: MdbNotificationService
              , private modalService: MdbModalService
              , private readonly employeeService: EmployeeService
              , private readonly activatedRoute: ActivatedRoute
              , private readonly title: Title
              , private readonly router:Router) 
  {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken && id !== null)
        {
          this.isLoading = true;
          this.getCommittee(id);
          this.loadEmployees();
        }
    })   
  }

  committeeMembers$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  committeeMembersLoading = false;
  committeeMembersInput$ = new Subject<string>();

  private loadEmployees() {
    this.committeeMembers$ = concat(
      of([]),
      this.committeeMembersInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.committeeMembersLoading = true),
        switchMap((term: string) => this.employeeService.getAll({ isActive: true, name: term }).pipe(
          catchError(() => of([])),
          tap(() => this.committeeMembersLoading = false)
        ))
      )
    );
  }

  trackByFn(item: IEmployee) {
    return item.employeeId;
  }

  getCommittee(id: string) {
    this.committeeService.get(id, { includeEmployees: true, includeLeaders: true }).subscribe({
      next: (committee: ICommittee) => {
        this.committee = committee;
        this.model = { ...committee };
        this.setTitle();
  
        this.committeeMembersModel = (committee.committeeEmployees) ? committee.committeeEmployees
            .filter((ce: ICommitteeEmployee) => ce.employee)
            .map((ce: ICommitteeEmployee) => ce.employee as IEmployee) : [];      
  
        this.store.dispatch(setPhotoName({photoName: this.model.photoName ?  this.model.photoName : 'No_Image_Available.jpg'}));
        this.store.dispatch(setImagefolder({folderLocation: 'committeephotos'}));
        this.store.dispatch(setImageUniqueId({imageUniqueId: new Date().getTime()}));  
        this.isLoaded = true;
        this.isLoading = false;        
      },
      error: () => {
        this.showError();
        this.isLoading = false;
      }
    });
  }

  setTitle() {
    this.title.setTitle(`Committee - ${this.committee.name}`);
  }

  updateCommittee() {
    this.isSaving = true;
    this.committeeService.update(this.model, this.model.committeeId, true, {includeEmployees: true }).subscribe({
      next: () => {
        this.committee = { ...this.model };
        this.setTitle();
        this.mode = 'read';
        this.isSaving = false;
        this.showSuccess();
      },
      error: () => {
        this.isSaving = false;
        this.showError();
      }
    
    });
  }

  edit() {
    this.mode = 'update';
  }

  save() {
    if (this.model.committeeEmployees) {
      const committeeMembers = this.model.committeeEmployees.filter((ce: ICommitteeEmployee) => this.committeeMembersModel.find(cem => cem.employeeId === ce.employeeId));

      const newEmployees = this.committeeMembersModel.filter((pe: IEmployee) => !(this.model.committeeEmployees?.find(cem => cem.employeeId === pe.employeeId)));
      const newCommitteeEmployees = newEmployees
        .filter((pe: IEmployee) => pe.employeeId !== undefined && this.model.committeeId !== undefined)
        .map<ICommitteeEmployee>((pe: IEmployee) => ({employeeId: pe.employeeId as number, committeeId: this.model.committeeId as number}));

      this.model.committeeEmployees = [...committeeMembers, ...newCommitteeEmployees];
    }

    this.updateCommittee();
  }

  onCancelCheck(){
    return JSON.stringify(this.committee) === JSON.stringify(this.model);
  }

  cancelUpdate() {
    this.model = { ...this.committee };
    this.committeeMembersModel = (this.committee.committeeEmployees) ? this.committee.committeeEmployees
      .filter((ce: ICommitteeEmployee) => ce.employee)
      .map((ce: ICommitteeEmployee)=> ce.employee as IEmployee) : [];
    this.router.navigate(['/committees']);
  }

  saveFilename(filename?: string) {
    this.committee.photoName = filename;
    this.model.photoName = filename;
    this.committeeService.update(this.committee, this.committee.committeeId, false).subscribe({
      next: () => {
        this.showSuccess();
      },
      error: () => {
        this.showError();
      }
    });
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'Committee'},
    });    
  }

  showSuccess() {    
    this.notificationSuccessRef = this.notificationService.open(
      ToastSuccessComponent,
      { data: { text: 'Committee updated!'},
      position: 'bottom-center',
      autohide: true });
  }
  
  showError() {
    this.notificationSuccessRef = this.notificationService.open(
      ToastErrorComponent,
      { data: { text: 'Something went wrong. Committee could not be updated!'},
      position: 'bottom-center',
      autohide: true });
  }

}
