import { IEmployeeBarAdmission } from "src/app/models/employee-bar-admission.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface EmployeeBarAdmissionState extends BaseState {
  employeeBarAdmissions: IEmployeeBarAdmission[];
}

export const initialEmployeeBarAdmissionState: EmployeeBarAdmissionState = {
  ...initialBaseState,
  employeeBarAdmissions: [],
};
