import { IEmployeeType } from "src/app/models/employee-type.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface EmployeeTypeState extends BaseState {
  employeeTypes: IEmployeeType[];
}

export const initialEmployeeTypeState: EmployeeTypeState = {
  ...initialBaseState,
  employeeTypes: []
};
