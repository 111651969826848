import { Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { IEmployee } from './models/employee.model';
import { EmployeeService } from './services/employee.service';
import { select, Store } from '@ngrx/store';
import { accessToken } from './store/state/auth.state';
import { setAccessToken, setAzureStorageKey, setLoginDisplay, setUser } from './store/actions/auth.actions';
import { Profile } from './models/user.model';
import { MemberGroup } from './models/member-group.model';
import { GraphService } from './services/graph.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { SettingsService } from './services/settings.service';
import { azureStorageURL } from './utils/app-config';
import { ISupportTeam } from './models/support-team.model';
import { MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import { AuthService } from './services/auth.service';
import { RootState } from './store/root.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Employee';
  isIframe = false;
  isLoginDisplay = false;
  loginDisplay$!: Observable<boolean>;
  userName: string | undefined;
  user$!: Observable<Profile | null>;
  memberOf$!: Observable<Array<MemberGroup> | [] >;  
  private readonly _destroying$ = new Subject<void|null>();
  employee: IEmployee = {};
  azureKey: string | undefined;
  isAdmin!: boolean | false;
  isHR!: boolean | false;
  azureImageURL = azureStorageURL;
  newSupportTeam: ISupportTeam = { name:'', isActive: true }  

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly employeeService: EmployeeService
    ,private readonly authService: AuthService
    ,private graphService: GraphService
    ,private msalBroadcastService: MsalBroadcastService
    ,private settingsService: SettingsService
    ,private bnIdle: BnNgIdleService
    ,private store: Store<RootState>)
  {
    this.bnIdle.startWatching(30).subscribe(() => {
      if (localStorage.getItem('tokenTimeout') != null) {
        if(Date.now() > new Date(JSON.parse(localStorage.getItem('tokenTimeout') || "")).getTime() - (5 * 60 * 1000)){
            if(this.authService.getActiveAccount()) {
              this.getTokenSilent();
            } else {
              this.checkAndSetActiveAccount();
            }
        }
      }
    })
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
 
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.setActiveAccount(payload.account);
        this.getTokenSilent();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if(!this.authService.isUserLoggedIn()) {
          this.authService.login();
        }
        this.checkAndSetActiveAccount();
        this.getProfile();
      });
  }
 
  checkAndSetActiveAccount() {
    const activeAccount = this.authService.getActiveAccount();
    if (!activeAccount && this.authService.isUserLoggedIn()) {
      const accounts = this.authService.getAllAccounts();
      this.authService.setActiveAccount(accounts[0]);
      this.authService.login();
    } else {
      this.getTokenSilent();
    }
  }
  
  getTokenSilent() { 
    this.authService.getTokenSilent().then((result: AuthenticationResult) => {
      this.store.dispatch(setAccessToken({accessToken: result.accessToken}));
      localStorage.setItem('tokenTimeout', JSON.stringify(result.expiresOn))
    });
  }

  getProfile(): void {
    this.graphService.getProfile()
    .subscribe({
      next: (profile: Profile) => {
        this.store.dispatch(setUser({ user: profile }));
        this.userName = profile.fullName;
        this.isAdmin = profile.isAdmin;
        this.isHR = profile.isHR;
        this.findEmployee(profile.userPrincipalName);
        this.store.dispatch(setLoginDisplay({loginDisplay: true}));
        this.getAzureStorageKey();
      }
    });
  }

  getAzureStorageKey(): void{
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
          this.settingsService.getAll().subscribe(settings => {
            const azureStorageKey = settings.find(s => s.name === "azureStorageKey")?.value || '';
            this.azureKey = azureStorageKey;           
            this.store.dispatch(setAzureStorageKey({ azureStorageKey: azureStorageKey }));
          });
        }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  findEmployee(email: string) {
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.employeeService.getAll({ isActive: true, email: email }).subscribe(employees => {
          if (employees && employees.length > 0){
            this.employee = employees[0];
            if(this.employee.photoName)
              this.employee.imageUrl = `${this.azureImageURL}/employeephotos/${this.employee.photoName}${this.azureKey}`;
            else
              this.employee.imageUrl = `${this.azureImageURL}/employeephotos/null.jpg${this.azureKey}`;
          }
          else this.employee = { keepBirthDateConfidential:false };
        });
      }
    })
  }
}