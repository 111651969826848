import { createAction, props } from '@ngrx/store';
import { Profile } from '../../models/user.model';

export const SET_LOGIN_DISPLAY = '[shared state] set login display';
export const SET_USER = '[shared state] set user info';

export const SET_ACCESS_TOKEN = '[shared state] set user access token';
export const SET_AZURE_STORAGE_KEY = '[shared state] set azure storage key';

export const SET_PHOTO_NAME = '[shared state] set photo name';
export const SET_IMAGE_FOLDER = '[shared state] set image storage folder';
export const SET_IMAGE_UNIQUE_ID = '[shared state] set image unique timestamp';

export const SET_IS_SAVING = '[shared state] set saving flag';
export const SET_IS_LOADING = '[shared state] set loading flag';

export const setLoginDisplay = createAction(
	SET_LOGIN_DISPLAY,
	props<{ loginDisplay: boolean }>()
);

export const setUser = createAction(
	SET_USER,
	props<{ user: Profile }>()
)

export const setAccessToken = createAction(
	SET_ACCESS_TOKEN,
	props<{ accessToken: string }>()
)

export const setAzureStorageKey = createAction(
	SET_AZURE_STORAGE_KEY,
	props<{ azureStorageKey: string }>()
)

export const setPhotoName = createAction(
	SET_PHOTO_NAME,
	props<{ photoName: string }>()
)

export const setImagefolder = createAction(
	SET_IMAGE_FOLDER,
	props<{ folderLocation: string }>()
)

export const setImageUniqueId = createAction(
	SET_IMAGE_UNIQUE_ID,
	props<{ imageUniqueId: number }>()
)

export const setIsSaving = createAction(
	SET_IS_SAVING,
	props<{ isSaving: boolean }>()
)

export const setIsLoading = createAction(
	SET_IS_LOADING,
	props<{ isLoading: boolean }>()
)