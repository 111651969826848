<div id="table" class="table-editable">
  <div class="d-flex flex-row-reverse mb-3">   
    <button type="button" class="btn btn-primary btn-sm ml-05"
            *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isLST))"
            (click)="openAddNewModal()">
      <i class="fas fa-plus"></i>
      Add New Support Team
    </button>
    <button type="button" 
            class="btn btn-primary btn-sm"  
            *ngIf="(canUpdate$ | async) === false && employeeSupportTeams.length > 0 && (((user$ | async)?.isAdmin) || ((user$ | async)?.isLST)) "
            (click)="onEdit()">
      <i class="fas fa-edit"></i> Edit
    </button>
    <button type="button" 
            class="btn btn-primary btn-sm ml-05"  
            *ngIf="(canUpdate$ | async)"
            (click)="!onCancelCheck() ? openPopconfirm($event) : onCancel()">
      <i class="fas fa-undo"></i>  Cancel
    </button>
    <button type="button" 
            class="btn btn-primary btn-sm" 
            *ngIf="(canUpdate$ | async)" 
            (click)="onSave()">
      <i class="fas fa-save ml-025"></i> Save
    </button>
    
  </div>
  <div class="d-flex align-items-center" *ngIf="(isLoading$ | async)">
    <strong>Loading...</strong>
    <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
  </div>
  <div *ngIf="employeeSupportTeams.length === 0 && (isLoading$ | async) === false">
    <h6>No support teams found. </h6>
    <h6 *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isLST))"> Click on "Add New Support Team" to add a new support team. </h6>
  </div>
  <div class="col-md-9 ml-1 mt-1">
    <table class="table table-bordered table-responsive-md table-striped" *ngIf="employeeSupportTeams.length > 0 && (isLoading$ | async) === false" >
      <tr>
        <th class="text-center">Support Team</th>
        <th class="text-center">Primary Contact</th>
        <th *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isLST))"></th>
      </tr>
      <tr *ngFor="let supportTeam of employeeSupportTeams; let id = index">
        <td class="text-center support-team-width">
          <ng-select  [items]="(supportTeams$ | async)"
                      bindLabel="name"
                      mdbInput
                      [(ngModel)]="supportTeam.supportTeam"
                      labelForId="supportTeamID"
                      name="supportTeam"
                      [loading]="(isSupportTeamLoading$ | async) ?? false"
                      [disabled]="(canUpdate$ | async) === false"
                      (change)="onChangeSupportTeam(supportTeam)" >
          </ng-select>
        </td>
        <td class="text-center primary-contact-width">
          <ng-select  [(ngModel)]="supportTeam.primaryContactSupportTeamMemberId"
                      [readonly]="(canUpdate$ | async) === false"
                      name="primaryContactSupportTeamMemberId"
                      bindlabel="name">
            <ng-option *ngFor="let temp of supportTeam?.supportTeam?.supportTeamMembers" [value]="temp.supportTeamMemberId">{{temp?.employee?.displayName}}</ng-option>
          </ng-select>
        </td>
        <td class="text-center delete-width" *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isLST))">
          <span>        
            <a href="#!" role="button" class="icon-selectable" 
                *ngIf="(canUpdate$ | async)" 
                (click)="setDeleteId(supportTeam.supportTeamEmployeeId);deletePopconfirm($event)">
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
            <a *ngIf="(canUpdate$ | async) === false" class="disabled icon-disabled">
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
          </span>
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add new support team</h5>
    <button type="button" class="btn-close-custom" aria-label="Close" (click)="templateModalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label for="supportTeamId">Select a Support Team: </label>
        <ng-select  [items]="(supportTeams$ | async)"
                    bindLabel="name"
                    mdbInput
                    [(ngModel)]="newEmployeeSupportTeam.supportTeam"
                    labelForId="supportTeamId"
                    name="supportTeam"
                    [loading]="(isSupportTeamLoading$ | async) ?? false"
                    (change)="onChange()">
        </ng-select>
      </div>
      <div class="col-md-12">
        <label for="primaryContactSupportTeamMemberId">Select a Primary Contact:</label>
        <ng-select  [(ngModel)]="newEmployeeSupportTeam.primaryContactSupportTeamMemberId"
                    name="primaryContactSupportTeamMemberId"
                    bindlabel="name"
                    labelForId="primaryContactSupportTeamMemberId">
          <ng-option *ngFor="let temp of newEmployeeSupportTeam?.supportTeam?.supportTeamMembers" [value]="temp.supportTeamMemberId">{{temp?.employee?.displayName}}</ng-option>
        </ng-select>
      </div>
    </div> 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="templateModalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="add()">Add</button>
  </div>
</ng-template>