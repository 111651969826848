  
import { NavigationClient, NavigationOptions } from "@azure/msal-browser";
import { Router } from "@angular/router";

export class CustomNavigationClient extends NavigationClient {
    private router: Router;

    constructor(router: Router) {
        super();
        this.router = router;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async navigateInternal(url:string, options: NavigationOptions): Promise<boolean> {
        this.router.navigateByUrl(url);
        return Promise.resolve(false);
    }
}