<mdb-form-control class="{{cssClass}} verticalMargin">
  <input  mdbInput 
          type="tel"
          maxlength="12"
          placeholder="xxx-xxx-xxxx"
          id={{fieldName}} 
          name={{fieldName}} 
          class="form-control" 
          [(ngModel)]="fieldValue"
          [disabled]="disabled ?? false"
          [readonly]="readonly ?? false"
          (ngModelChange)="fieldValueChange.emit(fieldValue)"
          />
  <label mdbLabel class="form-label" for={{fieldName}} [innerHtml]="labelHtml"></label>  
</mdb-form-control>