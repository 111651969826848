import { IFeature } from "./features.model";

export interface IChangeRequest {
  id?: number;
  request?:string; 
}

export const ChangeRequestFeature: IFeature = {
  FeatureName: 'ChangeRequest',
  ControllerPath: 'changerequest',
  CreateSuccessMessage: 'Change request submitted successfully.',
  CreateFailureMessage: 'Failed to submit change request.',
};