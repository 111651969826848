import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { IOffice } from '../models/office.model';
import { Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class OfficeService extends AppAuthenticatedCrudBaseService<IOffice> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'Office',store);
  }

  createDto(model: IOffice): IOffice {
    const result: IOffice =
    {
      officeId: model.officeId,
      name: model.name,
      description: model.description,
      photoName: model.photoName,
      administratorEmployeeId: model.administratorEmployeeId,
      extensionPrefix: model.extensionPrefix,
      phone: model.phone,
      fax: model.fax,
      street1: model.street1,
      street2: model.street2,
      street3: model.street3,
      city: model.city,
      state: model.state,
      zip: model.zip,
      country: model.country,
      isVirtual: model.isVirtual,
      isActive: model.isActive,
      officeCode: model.officeCode
    };
    return result;
  }
}
