import { IEmployeeNotary } from "src/app/models/employee-notary.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface EmployeeNotaryState extends BaseState  {
  employeeNotaries: IEmployeeNotary[];
}

export const initialEmployeeNotaryState: EmployeeNotaryState = {
  ...initialBaseState,
  employeeNotaries: []
};
