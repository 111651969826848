import { IFeature } from "./features.model";

export interface ILanguage {
  id?: number;
  name?: string;
}

export const LanguageFeature: IFeature = {
  FeatureName: 'Language',
  ControllerPath: 'utility/language',
  CreateSuccessMessage: 'Language created successfully.',
  CreateFailureMessage: 'Failed to create Language.',
};

export const LanguagesFeature: IFeature = {
  FeatureName: 'Languages',
  ControllerPath: 'utility/language',
  GetFailureMessage: 'Failed to get Languages.',
};
