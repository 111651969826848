import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IPhoto } from 'src/app/models/photo.model';
import { Profile } from 'src/app/models/user.model';
import { PhotoService } from 'src/app/services/photo.service';
import { imageURL, user } from 'src/app/store/state/auth.state';
import { setImagefolder, setImageUniqueId, setPhotoName } from 'src/app/store/actions/auth.actions';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input()
  code: string | undefined;

  @Input()
  isSmallCard: boolean | undefined;

  @Input()
  isEmployeePhoto: boolean | undefined;

  @Input()
  folder: string | undefined;
  
  @Output()
  save = new EventEmitter<string>();
  
  file: File | null = null;
  maxFileSize = 1000000;
  mode = 'read';
  imageSrc = '';
  isSaving = false;
  allowedExtensions = ['.jpg', '.png'];
  isMaxSize = false;
  allowedExtensionError = false;
  user$!: Observable<Profile | null>;
  canHrChangePic = false;
  
  constructor(private readonly photoService: PhotoService
              , private readonly  store: Store<RootState>) { }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(user));
    this.store.pipe(select(imageURL)).subscribe((imageURL) => {  
      this.imageSrc = imageURL;
    });
  }

  edit() {
    this.mode = 'update';
  }

  cancelUpdate() {
    this.isSaving = false;
    this.allowedExtensionError = false;
    this.isMaxSize = false;
    this.store.pipe(select(imageURL)).subscribe((imageURL) => {
      this.imageSrc = imageURL;
    });
    this.mode = 'read';
  }

  onFileAdd(files: File[]) {
    this.isSaving = true;
    this.file = files[0];
    const extension = files[0].name.substring(files[0].name.lastIndexOf('.'));

    if(files[0].size > this.maxFileSize)
      this.isMaxSize = true;

    if(this.allowedExtensions.indexOf(extension) < 0)
      this.allowedExtensionError = true;

    if(!this.allowedExtensionError && !this.isMaxSize){
      const reader = new FileReader();
      reader.onloadend = () => {
        const encodedFile = reader.result;
        let content = encodedFile as string;
        content = content.substring(content.indexOf(',') + 1);

        const filename = `${this.code}${extension}`;
        const model: IPhoto =
        {
          location: this.folder || '',
          filename: filename,
          content: content,
          contentLength: files[0].size,
          contentType: files[0].type,
        }
        this.photoService.create(model).subscribe({
          next: async () => {
            this.updateStateForImage(filename);
            this.save.emit(filename);
            this.isSaving = false;
          },
          error: () => {
            this.isSaving = false;
          }
        });
      };
      reader.readAsDataURL(files[0]);
      this.mode = 'read';
    }
  }

  updateStateForImage(filename?: string) {
    this.store.dispatch(setPhotoName({photoName: filename || 'No_Image_Available.jpg' }));
    this.store.dispatch(setImagefolder({folderLocation: this.folder || ''}));
    this.store.dispatch(setImageUniqueId({imageUniqueId: new Date().getTime()}));
  }

  onFileRemove() {
    this.isSaving = false;
    this.allowedExtensionError = false;
    this.isMaxSize = false;
    this.file = null;
    this.store.pipe(select(imageURL)).subscribe((imageURL) => {
      this.imageSrc = imageURL;
    });  
  }
}