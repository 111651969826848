<div class="d-flex flex-row-reverse">
  <button type="button" class="btn btn-primary btn-sm" 
          *ngIf="mode==='read' && (((user$ | async)?.isHR) || ((user$ | async)?.isAdmin))" 
          (click)="onEdit()">
    <i class="fas fa-edit"></i> Edit
  </button>
  <button type="button" class="btn btn-primary btn-sm ml-05" 
          *ngIf="mode ==='update'"
          (click)="!onCancelCheck() ? openPopconfirm($event) : onCancel()">
    <i class="fas fa-undo"></i> Cancel
  </button>
  <button type="button" class="btn btn-primary btn-sm" *ngIf="mode ==='update'" (click)="onSave()">
    <i class="fas fa-save ml-025"></i> Save
  </button>
</div>

<mdb-wysiwyg  #wysiwyg  
              [fixed]="true"
              [disabled]="mode === 'read'"
              [toolbarOptions]="disableToolbarOptions"
              [(ngModel)]="model.bioBodyAdmin">
</mdb-wysiwyg>