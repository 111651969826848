import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { ProficiencyState } from '../state/proficiency.state';

const state = (state: RootState) => state.proficiency;

const isLoading = createSelector(
  state,
  (state: ProficiencyState) => state.isLoading
);

const isSaving = createSelector(
  state,
  (state: ProficiencyState) => state.isSaving
);

const getAll = createSelector(
  state,
  (state: ProficiencyState) => state.proficiencies
);

export const proficiencySelectors = {
  isLoading,
  isSaving,
  getAll 
};