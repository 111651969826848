import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IChangeRequest } from 'src/app/models/change-request.model';
import { RootState } from 'src/app/store/root.state';
import { Store } from '@ngrx/store';
import { Observable, of, tap } from 'rxjs';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';
import { toastErrorAction } from 'src/app/store/actions/toast.actions';

@Component({
  selector: 'app-change-request',
  templateUrl: './change-request.component.html',
  styleUrls: ['./change-request.component.scss']
})
export class ChangeRequestComponent implements OnInit {
  
  isSaving$: Observable<boolean> = of(false);
  newChangeRequest$: Observable<IChangeRequest> = of({});
  model: IChangeRequest = {}

  constructor(private readonly store: Store<RootState>
              , private readonly title: Title
  ) {}

  ngOnInit(): void {
    this.isSaving$ = this.store.select(selectors.changeRequest.isSaving);    
    this.newChangeRequest$ = this.store.select(selectors.changeRequest.get)
      .pipe(tap((model: IChangeRequest) => {
          this.model = {...model};
      }));
      this.newChangeRequest$.subscribe();
    this.title.setTitle(`Change Request`);
  }

  save() {
    const action = this.model.request?.trim() ?
      CrudActions.create<IChangeRequest>(Feature.ChangeRequest)(this.model) : 
      toastErrorAction({ message: 'A request is required!' })
    this.store.dispatch(action);
  }

}
