import { ICommitteeEmployee } from "./committee-employee.model";
import { IFeature } from "./features.model";

export interface ICommittee {
  committeeId?:number;
  name?:string;
  photoName?:string;
  isActive?:boolean;
  committeeEmployees?: ICommitteeEmployee[];
}

export const CommitteeFeature: IFeature = {
  FeatureName: 'Committee',
  ControllerPath: 'Committees',
  CreateSuccessMessage: 'Committee created successfully.',
  CreateFailureMessage: 'Failed to create Committee.',
};

export const CommitteesFeature: IFeature = {
  FeatureName: 'Committees',
  ControllerPath: 'Committees',
  GetFailureMessage: 'Failed to get Committees.',
};