import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProficiencyActions from '../actions/proficiency.actions';
import { ProficiencyService } from 'src/app/services/proficiency.service';
import { IProficiency } from 'src/app/models/proficiency.model';
import { toastErrorAction } from '../actions/toast.actions';

@Injectable()
export class ProficiencyEffects {
  private actions$ = inject(Actions);
  
  constructor(
    private proficiencyService: ProficiencyService
  ) {}
  
  getAllProficiency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProficiencyActions.getAllProficiency),
      mergeMap((action:ProficiencyActions.GetAllProficiencyAction) => {
        return this.proficiencyService.getAll(action.queryParams).pipe(
          map((models: IProficiency[]) => {
            return ProficiencyActions.getAllProficiencySuccess({ models });
          }),
          catchError(error => {
            return of(
              ProficiencyActions.getAllProficiencyFailure({ error }),
              toastErrorAction({ message: 'Failed to get proficiencies' })
            );
          })
        );
      })
    )
  );
  
}