import { Component } from '@angular/core';
import { MdbPopconfirmRef } from 'mdb-angular-ui-kit/popconfirm';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  constructor(public popconfirmRef: MdbPopconfirmRef<ConfirmComponent>) {}

  confirm(): void {
    const closeMessage = 'Popconfirm confirmed';
    this.popconfirmRef.confirm(closeMessage)
  }
}
