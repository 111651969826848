import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeSupportTeamActions from '../actions/employee-support-team.actions';
import { EmployeeSupportTeamService } from 'src/app/services/employee-support-team-service';
import { IEmployeeSupportTeam } from 'src/app/models/employee-support-team';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';

@Injectable()
export class EmployeeSupportTeamEffects {
  
  private actions$ = inject(Actions);

  constructor(
    private employeeLanguageService: EmployeeSupportTeamService
  ) {}

  createEmployeeSupportTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeSupportTeamActions.createEmployeeSupportTeam),
      mergeMap((action: EmployeeSupportTeamActions.CreateEmployeeSupportTeamAction) => {
        return this.employeeLanguageService.create(action.model, action.includeChildren).pipe(
          concatMap((model: IEmployeeSupportTeam) => {
            return [
              EmployeeSupportTeamActions.createEmployeeSupportTeamSuccess({ model }),
              EmployeeSupportTeamActions.getAllEmployeeSupportTeam({ queryParams: { employeeId: model.employeeId } }),
              toastSuccessAction({ message: 'Support team added successfully' })
            ];
          }),
          catchError(error => {
            return of(
              EmployeeSupportTeamActions.createEmployeeSupportTeamFailure({ error }),
              toastErrorAction({ message: 'Failed to add support team' })
            );
          })
        );
      })
    )
  );

  deleteEmployeeSupportTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeSupportTeamActions.deleteEmployeeSupportTeam),
      mergeMap((action: EmployeeSupportTeamActions.DeleteEmployeeSupportTeamAction) => {
        return this.employeeLanguageService.delete(action.id).pipe(
          concatMap((model: IEmployeeSupportTeam) => {
            return [
              EmployeeSupportTeamActions.deleteEmployeeSupportTeamSuccess(),
              EmployeeSupportTeamActions.getAllEmployeeSupportTeam({ queryParams: { employeeId: model.employeeId } }),
              toastSuccessAction({ message: 'Employee support team deleted successfully' }) 
            ];
          }),
          catchError(error => {
            return of(
              EmployeeSupportTeamActions.deleteEmployeeSupportTeamFailure({ error }),
              toastErrorAction({ message: 'Failed to delete support team' })
            );
          })
        );
      })
    )
  );

  getAllEmployeeSupportTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeSupportTeamActions.getAllEmployeeSupportTeam),
      mergeMap((action: EmployeeSupportTeamActions.GetAllEmployeeSupportTeamAction) => {
        return this.employeeLanguageService.getAll(action.queryParams).pipe(
          map((models: IEmployeeSupportTeam[]) => {
            return EmployeeSupportTeamActions.getAllEmployeeSupportTeamSuccess({ models });
          }),
          catchError(error => {
            return of(
              EmployeeSupportTeamActions.getAllEmployeeSupportTeamFailure({ error }),
              toastErrorAction({ message: 'Failed to get support teams' }) 
            );
          })
        );
      })
    )
  );

  updateAllEmployeeSupportTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeSupportTeamActions.updateAllEmployeeSupportTeam),
      mergeMap((action: EmployeeSupportTeamActions.UpdateAllEmployeeSupportTeamAction) => {
        return this.employeeLanguageService.updateAll(action.models).pipe(
          concatMap(() => {            
            return [
              EmployeeSupportTeamActions.updateAllEmployeeSupportTeamSuccess({ models: action.models }),
              toastSuccessAction({ message: 'Support teams updated successfully' }) 
            ];
          }),
          catchError(error => {
            return of(
              EmployeeSupportTeamActions.updateAllEmployeeSupportTeamFailure({ error }),
              toastErrorAction({ message: 'Failed to update support teams' })
            );
          })
        );
      })
    )
  );
 
}