import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { EmployeeBarAdmissionState } from '../state/employee-bar-admission.state';

const state = (state: RootState) => state.employeeBarAdmission;

const isLoading = createSelector(
  state,
  (state: EmployeeBarAdmissionState) => state.isLoading
);

const isSaving = createSelector(
  state,
  (state: EmployeeBarAdmissionState) => state.isSaving
);

const canUpdate = createSelector(
  state,
  (state: EmployeeBarAdmissionState) => state.canUpdate
);

const getAll = createSelector(
  state,
  (state: EmployeeBarAdmissionState) => state.employeeBarAdmissions
);

export const employeeBarAdmissionSelectors = {
  isLoading,
  isSaving,
  canUpdate,
  getAll
};