import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { EmployeeComponent } from './components/employee/employee.component';
import { EmployeesComponent } from './components/employees/employees.component';
import { OfficesComponent } from './components/offices/offices.component';
import { OfficeComponent } from './components/office/office.component';
import { SupportTeamComponent } from './components/support-team/support-team.component';
import { SupportTeamsComponent } from './components/support-teams/support-teams.component';
import { PscComponent } from './components/psc/psc.component';
import { PscsComponent } from './components/pscs/pscs.component';
import { SbuComponent } from './components/sbu/sbu.component';
import { SbusComponent } from './components/sbus/sbus.component';
import { HomeComponent } from './components/home/home.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { DepartmentsComponent } from './components/departments/departments.component';
import { CommitteesComponent } from './components/committees/committees.component';
import { CommitteeComponent } from './components/committee/committee.component';
import { DepartmentComponent } from './components/department/department.component';
import { SupportTeamNewComponent } from './components/support-team-new/support-team-new.component';
import { CommitteeNewComponent } from './components/committee-new/committee-new.component';
import { OfficeNewComponent } from './components/office-new/office-new.component';
import { DepartmentNewComponent } from './components/department-new/department-new.component';
import { EmployeeNewComponent } from './components/employee-new/employee-new.component';
import { ChangeRequestComponent } from './components/change-request/change-request.component';
import { LanguageNewComponent } from './components/language-new/language-new.component';
import { BarAdmissionNewComponent } from './components/bar-admission-new/bar-admission-new.component';
import { EmployeeInactiveComponent } from './components/employee-inactive/employee-inactive.component';

const routes: Routes = [
  { path: 'employees/:id',
    component: EmployeeComponent,
    canActivate: [ MsalGuard ]
  },
  { path: 'employees',
    component: EmployeesComponent,
    canActivate: [ MsalGuard ]
  },
  { path: 'employee-new', component: EmployeeNewComponent },
  { path: 'employee-activate', component: EmployeeInactiveComponent },
  { path: 'offices/:id', component: OfficeComponent },
  { path: 'offices', component: OfficesComponent },
  { path: 'office-new', component: OfficeNewComponent },
  { path: 'pscs/:id', component: PscComponent },
  { path: 'pscs', component: PscsComponent },
  { path: 'sbus/:id', component: SbuComponent },
  { path: 'sbus', component: SbusComponent },
  { path: 'departments/:id', component: DepartmentComponent },
  { path: 'departments', component: DepartmentsComponent },
  { path: 'department-new', component: DepartmentNewComponent },
  { path: 'support-teams/:id', component: SupportTeamComponent },
  { path: 'support-teams', component: SupportTeamsComponent },
  { path: 'support-teams-new', component: SupportTeamNewComponent },
  { path: 'committees/:id', component: CommitteeComponent },
  { path: 'committees', component: CommitteesComponent },
  { path: 'committee-new', component: CommitteeNewComponent },
  { path: 'language-new', component: LanguageNewComponent },
  { path: 'bar-admission-new', component: BarAdmissionNewComponent },
  { path: 'change-request', component: ChangeRequestComponent },
  { path: 'auth', component: MsalRedirectComponent },
  { path: '', component: HomeComponent, canActivate: [ MsalGuard ]},
  {
    component: HomeComponent,
    matcher: (url: UrlSegment[]) => {
      return url.length === 1 && (url[0].path.startsWith('code'))
        ? { consumed: url }
        : null;
    },
  },
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true,
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled'
})],
  exports: [RouterModule]
})

export class AppRoutingModule {}