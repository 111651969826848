import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';
import { Observable, map, of } from 'rxjs';
import { IDepartment } from 'src/app/models/department.model';
import { Feature } from 'src/app/models/features.model';
import { Profile } from 'src/app/models/user.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { accessToken, user } from 'src/app/store/state/auth.state';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})

export class DepartmentsComponent implements OnInit {

  @ViewChild('table') table!: MdbTableDirective<IDepartment>;
  departments$: Observable<IDepartment[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  departments: IDepartment[] = [];
  headers = ['name', 'active'];  
  user$!: Observable<Profile | null>;
  
  constructor(private readonly router:Router
              , private readonly title: Title
              , private readonly store: Store<RootState>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectors.departments.isLoading);
    this.departments$ = this.store.select(selectors.departments.get).pipe(
      map((departments: IDepartment[]) => Array.isArray(departments) ? departments : [])
    );
    this.user$ = this.store.pipe(select(user));
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.store.dispatch(CrudActions.get(Feature.Departments)());
      }
    });
    this.title.setTitle(`Departments`);
  }

  handleClick(department: IDepartment) {
    this.router.navigate(['/departments/', department.departmentId]);
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.table.search(searchTerm);
  }

}