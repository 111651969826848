import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as CountyActions from '../actions/county.actions';
import { CountyService } from 'src/app/services/county.service';
import { ILanguage } from 'src/app/models/language.model';
import { toastErrorAction } from '../actions/toast.actions';

@Injectable()
export class CountyEffects {
  private actions$ = inject(Actions);
  
  constructor(
    private countyService: CountyService
  ) {}

  getAllCounty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountyActions.getAllCounty),
      mergeMap((action: CountyActions.GetAllCountyAction) => {
        return this.countyService.getAll(action.queryParams).pipe(
          map((models: ILanguage[]) => {
            return CountyActions.getAllCountySuccess({ models });
          }),
          catchError(error => {
            return of(
              CountyActions.getAllCountyFailure({ error }),
              toastErrorAction({ message: 'Failed to get counties' })
            );
          })
        );
      })
    )
  );

}