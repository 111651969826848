import { RootState } from "../root.state";
import { createSelector } from "@ngrx/store";
import { CrudState } from "../state/crud.state";

export function createCrudSelectors<T>(featureState: (state: RootState) => CrudState<T>) {
 
  const canUpdate = createSelector(
    featureState,
    (state: CrudState<T>) => state.canUpdate
  );

  const get = createSelector(
    featureState,
    (state: CrudState<T>) => state.model
  );

  const isLoading = createSelector(
    featureState,
    (state: CrudState<T>) => state.isLoading
  );

  const isSaving = createSelector(
    featureState,
    (state: CrudState<T>) => state.isSaving
  );
  
  return {
    canUpdate,
    get,
    isLoading,
    isSaving,
  };
  
}

