import { IFeature } from "./features.model";

export interface IDepartment
{
  departmentId?: number;
  name?:string;
  photoName?: string;
  isActive?:boolean;
}

export const DepartmentFeature: IFeature = {
  FeatureName: 'Department',
  ControllerPath: 'Department',
  GetFailureMessage: 'There was a problem loading the department.',
  UpdateSuccessMessage: 'Department updated successfully.',
  UpdateFailureMessage: 'Failed to update department.',
};

export const DepartmentsFeature: IFeature = {
  FeatureName: 'Departments',
  ControllerPath: 'Department',
  GetFailureMessage: 'There was a problem loading the departments.',
};