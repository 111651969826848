<div class="d-flex align-items-center" *ngIf="isLoading">
  <strong>Loading...</strong>
  <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
</div>

<div class="d-flex">
  <!-- Image -->
  <div class="flex-shrink-0">
    <app-image  [code]="model.name" 
                [isSmallCard]="false"
                [isEmployeePhoto]="false"
                folder="departmentphotos"
                (save)="saveFilename($event)">
    </app-image>
  </div>
  <!-- Body -->
  <div class="flex-grow-1 ms-3 ml-05">
    <div class="row">
      <div class="col-md-4"><h4>{{model.name}}</h4></div>
      <div class="col-md-3 offset-md-5 text-right">
        <button type="button" class="btn btn-primary btn-sm" (click)="save()">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving" aria-hidden="true"></span>
          <i class="fas fa-save ml-025"></i> Save
        </button>
        <button type="button" 
                class="btn btn-primary btn-sm ml-05" 
                (click)="!onCancelCheck() ? openCancelModal() : cancelUpdate()">
          <i class="fas fa-undo"></i> Cancel
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <form>     
      <app-input-text [cssClass]="'col-8'"  [fieldName]="'name'" [labelHtml]="'Name'" [(fieldValue)]="model.name"></app-input-text>
      <app-input-checkbox [cssClass]="'col-8'" [fieldName]="'active'" [labelHtml]="' Active? '"
          [(fieldValue)]="model.isActive" (fieldValueChange)="model.isActive=$event"/>
    </form>
  </div>
</div>