import { Injectable } from '@angular/core';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ToastSuccessComponent } from '../components/helpers/toast-success/toast-success.component';
import { ToastErrorComponent } from '../components/helpers/toast-error/toast-error.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService  {

  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
    
  constructor(private notificationService: MdbNotificationService) {}

  showSuccess(message?: string) {
    this.notificationSuccessRef = this.notificationService.open(
      ToastSuccessComponent  
      ,{ data: { text: message ?? "Success!"}
      ,position: 'bottom-center'
      ,autohide: true });
  }

  showError(message?: string) {
    this.notificationErrorRef = this.notificationService.open(
      ToastErrorComponent  
      ,{ data: { text: message ?? "An error occurred."}
      ,position: 'bottom-center'
      ,autohide: true });
  }

  
}
