import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEmployeeBarAdmission } from 'src/app/models/employee-bar-admission.model';

export const createEmployeeBarAdmission = createAction(
  '[EmployeeBarAdmission] Create',
  props<{ model: IEmployeeBarAdmission, includeChildren: boolean }>()
);

export const createEmployeeBarAdmissionSuccess = createAction(
  '[EmployeeBarAdmission] Create Success',
  props<{ model: IEmployeeBarAdmission }>()
);

export const createEmployeeBarAdmissionFailure = createAction(
  '[EmployeeBarAdmission] Create Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const getAllEmployeeBarAdmission = createAction(
  '[EmployeeBarAdmission] Get All',
  props<{ queryParams: Record<string, unknown> }>()
);

export const getAllEmployeeBarAdmissionSuccess = createAction(
  '[EmployeeBarAdmission] Get All Success',
  props<{ models: IEmployeeBarAdmission[] }>()
);

export const getAllEmployeeBarAdmissionFailure = createAction(
  '[EmployeeBarAdmission] Get All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const updateAllEmployeeBarAdmission = createAction(
  '[EmployeeBarAdmission] Update All',
  props<{ models: IEmployeeBarAdmission[] }>()
);

export const updateAllEmployeeBarAdmissionSuccess = createAction(
  '[EmployeeBarAdmission] Update All Success',
  props<{ models: IEmployeeBarAdmission[] }>()
);

export const updateAllEmployeeBarAdmissionFailure = createAction(
  '[EmployeeBarAdmission] Update All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const deleteEmployeeBarAdmission = createAction(
  '[EmployeeBarAdmission] Delete',
  props<{ id: string | number }>()
);

export const deleteEmployeeBarAdmissionSuccess = createAction(
  '[EmployeeBarAdmission] Delete Success'  
);

export const deleteEmployeeBarAdmissionFailure = createAction(
  '[EmployeeBarAdmission] Delete Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const setEmployeeBarAdmissionCanUpdate = createAction(
  '[EmployeeBarAdmission] Set Can Update',
  props<{ canUpdate: boolean }>()
);

export const setAllEmployeeBarAdmission = createAction(
  '[EmployeeBarAdmission] Set All',
  props<{ models: IEmployeeBarAdmission[] }>()
);

export type CreateEmployeeBarAdmissionAction = ReturnType<typeof createEmployeeBarAdmission>;
export type CreateEmployeeBarAdmissionSuccessAction = ReturnType<typeof createEmployeeBarAdmissionSuccess>;
export type CreateEmployeeBarAdmissionFailureAction = ReturnType<typeof createEmployeeBarAdmissionFailure>;

export type GetAllEmployeeBarAdmissionAction = ReturnType<typeof getAllEmployeeBarAdmission>;
export type GetAllEmployeeBarAdmissionSuccessAction = ReturnType<typeof getAllEmployeeBarAdmissionSuccess>;
export type GetAllEmployeeBarAdmissionFailureAction = ReturnType<typeof getAllEmployeeBarAdmissionFailure>;

export type UpdateAllEmployeeBarAdmissionAction = ReturnType<typeof updateAllEmployeeBarAdmission>;
export type UpdateAllEmployeeBarAdmissionSuccessAction = ReturnType<typeof updateAllEmployeeBarAdmissionSuccess>;
export type UpdateAllEmployeeBarAdmissionFailureAction = ReturnType<typeof updateAllEmployeeBarAdmissionFailure>;

export type DeleteEmployeeBarAdmissionAction = ReturnType<typeof deleteEmployeeBarAdmission>;
export type DeleteEmployeeBarAdmissionSuccessAction = ReturnType<typeof deleteEmployeeBarAdmissionSuccess>;
export type DeleteEmployeeBarAdmissionFailureAction = ReturnType<typeof deleteEmployeeBarAdmissionFailure>;

export type SetEmployeeBarAdmissionCanUpdateAction = ReturnType<typeof setEmployeeBarAdmissionCanUpdate>;
export type SetAllEmployeeBarAdmissionAction = ReturnType<typeof setAllEmployeeBarAdmission>;



