import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeKeyDatesActions from '../actions/employee-key-dates.actions';
import { IEmployeeEducation } from 'src/app/models/employee-education.model';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';
import { CrudService } from 'src/app/services/crud.service';
import { Feature } from 'src/app/models/features.model';
import { IEmployeeKeyDates } from 'src/app/models/employee-key-dates.model';
import { CrudActions } from '../actions/crud.actions';
import { Store } from '@ngrx/store';
import { RootState } from '../root.state';

@Injectable()
export class EmployeeKeyDatesEffects {
  
  private actions$ = inject(Actions);
  
  constructor(
    private crudService: CrudService<IEmployeeEducation>,
    private store: Store<RootState>,    
  ) {}

  getEmployeeToReactivate$ = createEffect(() =>
    
    this.actions$.pipe(
      ofType(EmployeeKeyDatesActions.getEmployeeToReactivate),
      tap(() => this.store.dispatch(CrudActions.setIsLoading(Feature.Employees)(true))),
      mergeMap((action: EmployeeKeyDatesActions.GetEmployeeToReactivateAction) => {
        return this.crudService.get(Feature.EmployeeKeyDates, action.id).pipe(
          map((model: IEmployeeKeyDates) => {
            const updatedModel = { ...model, isActive: true };
            return EmployeeKeyDatesActions.reactivateEmployee({model: updatedModel, id: action.id});
          }),
          catchError(() => {
            return of(
              toastErrorAction({ message: 'Failed to reactivate' }),
              CrudActions.setIsLoading(Feature.Employees)(false)
            );
          })
        );
      })
    )
  );

  reactivateEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeKeyDatesActions.reactivateEmployee),
      mergeMap((action: EmployeeKeyDatesActions.ReactivateEmployeeAction) => {
        return this.crudService.update(Feature.EmployeeKeyDates, action.model, action.id).pipe(
          concatMap(() => {
            return [
              CrudActions.get(Feature.Employees)(undefined, {isActive: false}),              
              toastSuccessAction({ message: 'Employee successfully reactivated.' }),
              CrudActions.setIsLoading(Feature.Employees)(false)
            ];
          }),
          catchError(() => {
            return of(
              toastErrorAction({ message: 'Failed to update' }),
              CrudActions.setIsLoading(Feature.Employees)(false)
            );
          })
        );
      })
    )
  );
 
}