import { IEmployeeSupportTeam } from "src/app/models/employee-support-team";
import { BaseState, initialBaseState } from "./crud.state";

export interface EmployeeSupportTeamState extends BaseState {
  employeeSupportTeams: IEmployeeSupportTeam[];
}

export const initialEmployeeSupportTeamState: EmployeeSupportTeamState = {
  ...initialBaseState,
  employeeSupportTeams: []
};
