import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IProficiency } from '../models/proficiency.model';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class ProficiencyService extends AppAuthenticatedCrudBaseService<IProficiency> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'Utility/Proficiency', store);
  }
}
