import { IEmployee } from './employee.model';

export interface IOffice {
  officeId?: string;
  name?: string;
  officeCode?:string,
  description?: string;
  photoName?: string;
  administratorEmployeeId?: number;
  extensionPrefix?: number;
  phone?: string;
  fax?: string;
  street1?: string;
  street2?: string;
  street3?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  isVirtual: boolean;
  isActive: boolean;
  administrator?: IEmployee;
  adminName?:string;
}

export const OfficeFeature = {
  FeatureName: 'Office',
  ControllerPath: 'office',
  CreateSuccessMessage: 'Office created successfully.',
  CreateFailureMessage: 'Failed to create Office.',
  GetFailureMessage: 'Failed to get Offices.',
  DeleteSuccessMessage: 'Office deleted successfully.',
  DeleteFailureMessage: 'Failed to delete Office.',
  UpdateSuccessMessage: 'Office updated successfully.',
  UpdateFailureMessage: 'Failed to update Office.',
};

export const OfficesFeature = {
  FeatureName: 'Offices',
  ControllerPath: 'office',
  GetFailureMessage: 'Failed to get Offices.',
};