import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent  {
  @Input() cssClass!: string;
  @Input() labelHtml!: string;
  @Input() fieldName!: string;
  @Input() fieldValue: boolean | undefined;  
  @Input() readonly!: boolean | null;
  @Input() disabled!: boolean | null;
    @Output() fieldValueChange = new EventEmitter<boolean | undefined>();

}