<div class="form-check {{cssClass}} verticalMargin">
<mdb-form-control>
  <input  mdbCheckbox
          class="form-check-input"
          type="checkbox"
          id={{fieldName}} 
          name={{fieldName}} 
          [(ngModel)]="fieldValue"
          [ngModelOptions]="{standalone: true}"
          [disabled]="disabled ?? false"
          [readonly]="readonly ?? false"
          (ngModelChange)="fieldValueChange.emit(fieldValue)"
          />
  <label class="form-check-label" for={{fieldName}} [innerHtml]="labelHtml"></label>  
</mdb-form-control>
</div>