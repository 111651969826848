import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { Observable, of } from 'rxjs';
import { IEmployeeContact } from 'src/app/models/employee-contact.model';
import { Profile } from 'src/app/models/user.model';
import { user } from 'src/app/store/state/auth.state';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { RootState } from 'src/app/store/root.state';
import { getEmployeeContact, setEmployeeContactCanUpdate, updateEmployeeContact } from 'src/app/store/actions/employee-contact.actions';
import { selectors } from 'src/app/store/selectors/selectors';

@Component({
  selector: 'app-employee-contact',
  templateUrl: './employee-contact.component.html',
  styleUrls: ['./employee-contact.component.scss']
})
export class EmployeeContactComponent implements OnInit {
  @Input()  
  employeeId: string | null | undefined;

  employeeContact$: Observable<IEmployeeContact> = of({});
  canUpdate$: Observable<boolean> = of(false);
  isSaving$: Observable<boolean> = of(false);
  user$!: Observable<Profile | null>;
  isReadOnly = true;
  hasEditButton = false;
  hasHrItAdminPermissions = false;
  hasHrAdminPermissions = false;
  hasItAdminPermissions = false;
  canUpdate = false;
  model: IEmployeeContact = {};
    
  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
  
  constructor(private store: Store<RootState>
              , private popconfirmService: MdbPopconfirmService
              , private notificationService: MdbNotificationService) 
  {}

  ngOnInit(): void {
    this.employeeContact$ = this.store.select(selectors.employeeContact.get);
    this.canUpdate$ = this.store.select(selectors.employeeContact.canUpdate);
    this.isSaving$ = this.store.select(selectors.employeeContact.isSaving);       
    this.user$ = this.store.pipe(select(user));
    this.user$.subscribe((user: Profile | null) => {
      if(user) {
        this.hasItAdminPermissions = user?.isITSystems || user?.isAdmin;
        this.hasHrAdminPermissions = user?.isHR|| user?.isAdmin;
        this.hasHrItAdminPermissions = user?.isHR|| user?.isITSystems || user?.isAdmin;
        this.hasEditButton = this.isReadOnly && (
          this.hasItAdminPermissions || this.hasHrItAdminPermissions || this.hasHrAdminPermissions 
        );
      }
      if(this.employeeId) {
        this.getEmployeeContact();
      }
    });

    this.employeeContact$.subscribe((employeeContact: IEmployeeContact) => {
      if(employeeContact) {
        employeeContact = {...employeeContact};
        employeeContact.workPhone = employeeContact.workPhone ? this.normalizePhoneFormat(employeeContact.workPhone) : undefined;
        employeeContact.fax = employeeContact.fax ? this.normalizePhoneFormat(employeeContact.fax) : undefined;
        employeeContact.mobilePhone = employeeContact.mobilePhone ? this.normalizePhoneFormat(employeeContact.mobilePhone) : undefined;
        employeeContact.emergencyPhone = employeeContact.emergencyPhone ? this.normalizePhoneFormat(employeeContact.emergencyPhone) : undefined;
        employeeContact.emergencyText = employeeContact.emergencyText ? this.normalizePhoneFormat(employeeContact.emergencyText) : undefined;
        this.model = {...employeeContact};
      }
    });

    this.canUpdate$.subscribe((canUpdate: boolean) => {
      this.isReadOnly = !canUpdate;
      this.canUpdate = canUpdate;
      this.hasEditButton = this.isReadOnly && (this.hasHrItAdminPermissions || this.hasHrAdminPermissions);
    });
  
  
  }
  
  getEmployeeContact() {
    if(this.employeeId) {
      this.store.dispatch(getEmployeeContact({id: this.employeeId}));
    }
  }

  onSave() {
    if (!this.validatePhoneNumbers()) {
      return;
    }
    this.model.employeeId = Number(this.employeeId);
    this.model.workPhone = this.model.workPhone?.replace(/-/g, "");
    this.model.fax = this.model.fax?.replace(/-/g, "");
    this.model.mobilePhone = this.model.mobilePhone?.replace(/-/g, "");
    this.model.homePhone = this.model.homePhone?.replace(/-/g, "");
    this.model.emergencyPhone = this.model.emergencyPhone?.replace(/-/g, "");
    this.model.emergencyText = this.model.emergencyText?.replace(/-/g, "");    
    this.store.dispatch(updateEmployeeContact({model: this.model, id: this.model.id, includeChildren: false, queryParams: {}}));
  }

  onEdit() {    
    this.store.dispatch(setEmployeeContactCanUpdate({ canUpdate: true }));
  }
  
  onCancel() {
    this.getEmployeeContact();
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }

  normalizePhoneFormat(phone? : string) {
    phone = phone?.replace(/[^\d]/g, "");
    if (phone?.length == 10) {
      return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
    return phone;
  }

  isValidPhoneNumber(phone? : string) {
    phone = phone?.replace(/[^\d]/g, "");
    if (phone && phone.length > 0 && !phone?.match(/^\d{10}$/)) {
        return false;
    }
    return true;    
  }

  validatePhoneNumbers() {
    const fields = ['workPhone', 'fax', 'mobilePhone', 'homePhone', 'emergencyPhone', 'emergencyText'];
    const friendlyNames = ['Work Phone', 'Fax', 'Mobile Phone', 'Home Phone','Emergency Phone', 'Emergency Text'];
    const invalidFields = [];

    for (const field of fields) {
      if (!this.isValidPhoneNumber(String(this.model[field as keyof IEmployeeContact]))) {
        invalidFields.push(friendlyNames[fields.indexOf(field)]);
      }
    }

    if (invalidFields.length > 0) {
        const errorMessage = 'Invalid phone number format. Please enter a valid phone number for the following fields: ' + invalidFields.join(', ');
        this.notificationErrorRef = this.notificationService.open(
            ToastErrorComponent,
            { data: { text: errorMessage },
            position: 'bottom-center',
            autohide: true });
        return false;
    }

    return true;
  } 
}