import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeEducationActions from '../actions/employee-education.actions';
import { EmployeeEducationService } from 'src/app/services/employee-education.service';
import { IEmployeeEducation } from 'src/app/models/employee-education.model';
import { toastErrorAction } from '../actions/toast.actions';

@Injectable()
export class EmployeeEducationEffects {
  
  private actions$ = inject(Actions);

  constructor(
    private employeeEducationService: EmployeeEducationService
  ) {}

  getAllEmployeeEducation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeEducationActions.getAllEmployeeEducation),
      mergeMap((action: EmployeeEducationActions.GetAllEmployeeEducationAction) => {
        return this.employeeEducationService.getAll(action.queryParams).pipe(
          map((models: IEmployeeEducation[]) => {
            return EmployeeEducationActions.getAllEmployeeEducationSuccess({ models });
          }),
          catchError(error => {
            return of(
              EmployeeEducationActions.getAllEmployeeEducationFailure({ error }),
              toastErrorAction({ message: 'Failed to get education' })
            );
          })
        );
      })
    )
  );
 
}