import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';
import { Observable, map, of } from 'rxjs';
import { ICommittee } from 'src/app/models/committee.model';
import { Feature } from 'src/app/models/features.model';
import { Profile } from 'src/app/models/user.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { accessToken, user } from 'src/app/store/state/auth.state';

@Component({
  selector: 'app-committees',
  templateUrl: './committees.component.html',
  styleUrls: ['./committees.component.scss']
})
export class CommitteesComponent implements OnInit {

  @ViewChild('table') committeeTable!: MdbTableDirective<ICommittee>;
  committees$: Observable<ICommittee[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  headers = ['name', 'active'];
  user$!: Observable<Profile | null>;

  constructor(private readonly router:Router
              , private readonly title: Title
              , private readonly store: Store<RootState>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectors.committees.isLoading);
    this.committees$ = this.store.select(selectors.committees.get).pipe(
      map(committees => Array.isArray(committees) ? committees : [])
    );
    this.user$ = this.store.pipe(select(user));
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.store.dispatch(CrudActions.get(Feature.Committees)());
      }
    });
    this.title.setTitle(`Committees`);
  }

  handleClick(committee: ICommittee) {
    this.router.navigate(['/committees/', committee.committeeId]);
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.committeeTable.search(searchTerm);
  }

}
