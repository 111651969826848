import { HttpErrorResponse } from '@angular/common/http';
import { createAction } from '@ngrx/store';
import { ICrudRouteParams } from 'src/app/models/crud-route-params';
import { IFeature } from 'src/app/models/features.model';

export const CrudActions = {
  create: function<T>(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Create`,
      (model: T, routeParams?: ICrudRouteParams<T>) => ({ feature: type, model: model, routeParams: routeParams} as CreateActionProps<T>)
    );
  },
  createSuccess: function<T>(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Create Success`,
      (model: T) => ({ feature: type, model: model } as CreateSuccessActionProps<T>)
    );
  },
  createFailure: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Create Failure`,
      (error: Error | HttpErrorResponse) => ({ feature: type, error: error } as CreateFailureActionProps)
    );
  },
  get: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Get`,
      (id?: number, queryParams?: Record<string, unknown>) => ({  feature: type, id: id, queryParams: queryParams } as GetActionProps)
    );
  },
  getSuccess: function<T>(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Get Success`,
      (model: T) => ({ feature: type, model: model } as GetSuccessActionProps<T>)
    );
  },
  getFailure: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Get Failure`,
      (error: Error | HttpErrorResponse) => ({ feature: type, error: error } as GetFailureActionProps)
    );
  },
  update: function<T>(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Update`,
      (model: T, id?: number, queryParams?: Record<string, unknown>) => ({ feature: type, model: model, id: id, queryParams: queryParams } as UpdateActionProps<T>)
    );
  },
  updateSuccess: function<T>(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Update Success`,
      (model: T) => ({ feature: type, model: model } as UpdateSuccessActionProps<T>)
    );
  },
  updateFailure: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Update Failure`,
      (error: Error | HttpErrorResponse) => ({ feature: type, error: error } as UpdateFailureActionProps)
    );
  },
  delete: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Delete`,
      (id: number) => ({ feature: type, id: id } as DeleteActionProps)
    );
  },
  deleteSuccess: function<T>(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Delete Success`,
      (model: T) => ({ feature: type, model: model } as DeleteSuccessActionProps<T>)
    );
  },
  deleteFailure: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Delete Failure`,
      (error: Error | HttpErrorResponse) => ({ feature: type, error: error } as DeleteFailureActionProps)
    );
  },
  setCanUpdate: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Set Can Update`,
      (canUpdate: boolean) => ({ feature: type, canUpdate: canUpdate } as SetCanUpdateActionProps)
    );
  },
  setIsLoading: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Set Is Loading`,
      (isLoading: boolean) => ({ feature: type, isLoading: isLoading } as SetIsLoadingActionProps)
    );
  },
  setIsSaving: function(type: IFeature) {
    return createAction(
      `[${type.FeatureName}] Set Is Saving`,
      (isSaving: boolean) => ({ feature: type, isSaving: isSaving } as SetIsSavingActionProps)
    );
  }
};

export type CreateActionProps<T> = { feature: IFeature, model: T, routeParams?: ICrudRouteParams<T> };
export type CreateSuccessActionProps<T> = { feature: IFeature, model: T };
export type CreateFailureActionProps = { feature: IFeature, error: Error | HttpErrorResponse };
export type GetActionProps = { feature: IFeature, id?: number, queryParams?: Record<string, unknown> };
export type GetSuccessActionProps<T> = { feature: IFeature, model: T };
export type GetFailureActionProps = { feature: IFeature, error: Error | HttpErrorResponse };
export type UpdateActionProps<T> = { feature: IFeature, model: T, id?: number, queryParams?: Record<string, unknown> };
export type UpdateSuccessActionProps<T> = { feature: IFeature, model: T };
export type UpdateFailureActionProps = { feature: IFeature, error: Error | HttpErrorResponse };
export type DeleteActionProps = { feature: IFeature, id: number  };
export type DeleteSuccessActionProps<T> = { feature: IFeature, model: T };
export type DeleteFailureActionProps = { feature: IFeature, error: Error | HttpErrorResponse };
export type SetCanUpdateActionProps = { feature: IFeature, canUpdate: boolean };
export type SetIsLoadingActionProps = { feature: IFeature, isLoading: boolean };
export type SetIsSavingActionProps = { feature: IFeature, isSaving: boolean };

export type CreateActionType<T> = ReturnType<typeof CrudActions.create<T>>;
export type CreateSuccessActionType<T> = ReturnType<typeof CrudActions.createSuccess<T>>;
export type CreateFailureActionType = ReturnType<typeof CrudActions.createFailure>;
export type GetActionType = ReturnType<typeof CrudActions.get>;
export type GetSuccessActionType<T> = ReturnType<typeof CrudActions.getSuccess<T>>;
export type GetFailureActionType = ReturnType<typeof CrudActions.getFailure>;
export type UpdateActionType<T> = ReturnType<typeof CrudActions.update<T>>;
export type UpdateSuccessActionType<T> = ReturnType<typeof CrudActions.updateSuccess<T>>;
export type UpdateFailureActionType = ReturnType<typeof CrudActions.updateFailure>;
export type DeleteActionType = ReturnType<typeof CrudActions.delete>;
export type DeleteSuccessActionType<T> = ReturnType<typeof CrudActions.deleteSuccess<T>>;
export type DeleteFailureActionType = ReturnType<typeof CrudActions.deleteFailure>;
export type SetCanUpdateActionType = ReturnType<typeof CrudActions.setCanUpdate>;
export type SetIsLoadingActionType = ReturnType<typeof CrudActions.setIsLoading>;
export type SetIsSavingActionType = ReturnType<typeof CrudActions.setIsSaving>;