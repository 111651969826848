import { IEmployeeOrg } from "src/app/models/employee-org.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface EmployeeOrgState extends BaseState {
  employeeOrg: IEmployeeOrg;
}

export const initialEmployeeOrgState: EmployeeOrgState = {
  ...initialBaseState,
  employeeOrg: {}
};
