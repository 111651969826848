import { createAction, props } from '@ngrx/store';
import { IEmployeeKeyDates } from 'src/app/models/employee-key-dates.model';

export const getEmployeeToReactivate  = createAction(
  '[EmployeeKeyDates] Get to reactivate',
  props<{ id: number }>()
);

export const reactivateEmployee  = createAction(
  '[EmployeeKeyDates] Update to reactivate',
  props<{ model: IEmployeeKeyDates, id: number }>()
);

export type GetEmployeeToReactivateAction = ReturnType<typeof getEmployeeToReactivate>;
export type ReactivateEmployeeAction = ReturnType<typeof reactivateEmployee>;

