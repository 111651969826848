import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SbuService } from '../../services/sbu.service';
import { ISbu } from '../../models/sbu.model';
import { EmployeeService } from "../../services/employee.service";
import { IEmployee } from "../../models/employee.model";
import { ISbuLeader } from "../../models/sbu-leader.model";
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { accessToken } from 'src/app/store/state/auth.state';
import { setImagefolder, setImageUniqueId, setPhotoName } from 'src/app/store/actions/auth.actions';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { IManagingDirector } from 'src/app/models/managing-director.model';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'app-sbu',
  templateUrl: './sbu.component.html',
  styleUrls: ['./sbu.component.scss']
})
export class SbuComponent implements OnInit {
  
  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;

  id: string | null = null;
  sbu: ISbu = { isActive: true, sbuLeaders: [], managingDirectors: [], employees: [] }
  model: ISbu = { isActive: true, sbuLeaders: [], managingDirectors: [], employees: [] }
  sbuLeadersModel: IEmployee[] = [];
  managingDirectorsModel: IEmployee[] = [];
  employeesModel: IEmployee[] = [];
  mode = 'read';
  defaultPhoto = '';
  searchText2?= '';
  data: IEmployee[] = [];
  isLoading = false;
  isLoaded = false;
  isSaving = false;

  constructor(private readonly sbuService: SbuService
              , private readonly  store: Store<RootState>
              , private notificationService: MdbNotificationService
              , private modalService: MdbModalService
              , private readonly employeeService: EmployeeService
              , private readonly activatedRoute: ActivatedRoute
              , private readonly title: Title
              , private readonly router:Router) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.store.pipe(select(accessToken)).subscribe(async (accessToken: string | null) => {
      if(accessToken && id !== null)
        {
          this.isLoading = true;
          await this.getSbu(id);
          this.loadLeaders();
          this.loadManagingDirectors();
          this.loadEmployees();
        }
    })  
  }

  sbuLeaders$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  sbuLeadersLoading = false;
  sbuLeadersInput$ = new Subject<string>();

  private loadLeaders() {
    this.sbuLeaders$ = concat(
      of([]),
      this.sbuLeadersInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.sbuLeadersLoading = true),
        switchMap(term => this.employeeService.getAll({ isActive: true, name: term }).pipe(
          catchError(() => of([])),
          tap(() => this.sbuLeadersLoading = false)
        ))
      )
    );
  }

  managingDirectors$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  managingDirectorsLoading = false;
  managingDirectorsInput$ = new Subject<string>();

  private loadManagingDirectors() {
    this.managingDirectors$ = concat(
      of([]),
      this.managingDirectorsInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.managingDirectorsLoading = true),
        switchMap(term => this.employeeService.getAll({ isActive: true, name: term }).pipe(
          catchError(() => of([])),
          tap(() => this.managingDirectorsLoading = false)
        ))
      )
    );
  }

  employees$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  employeesLoading = false;
  employeesInput$ = new Subject<string>();

  private loadEmployees() {
    this.employees$ = concat(
      of([]),
      this.employeesInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.employeesLoading = true),
        switchMap((term: string) => this.employeeService.getAll({ isActive: true, name: term }).pipe(
          catchError(() => of([])),
          tap(() => this.employeesLoading = false)
        ))
      )
    );
  }

  trackByFn(item: IEmployee) {
    return item.employeeId;
  }

  getSbu(id: string) {
    this.sbuService.get(id, { includeEmployees: true, includeLeaders: true, includeManagingDirectors: true }).subscribe({
      next: (sbu: ISbu) => {
        this.sbu = sbu;
        this.model = { ...sbu };
        this.setTitle();

        this.managingDirectorsModel = (sbu.managingDirectors) ? sbu.managingDirectors
          .filter((pe: IManagingDirector) => pe.employee)
          .map((pe: IManagingDirector) => pe.employee as IEmployee) : [];
        this.sbuLeadersModel = (sbu.sbuLeaders) ? sbu.sbuLeaders
          .filter((pl: ISbuLeader) => pl.employee)
          .map((pl: ISbuLeader) => pl.employee as IEmployee) : [];
        this.employeesModel = (sbu.employees) ? [...sbu.employees] : [];

        this.store.dispatch(setPhotoName({photoName: this.model.photoName ?  this.model.photoName : 'No_Image_Available.jpg'}));
        this.store.dispatch(setImagefolder({folderLocation: 'sbuphotos'}));
        this.store.dispatch(setImageUniqueId({imageUniqueId: new Date().getTime()}));

        this.isLoaded = true;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.showError();
      }
    });

  }

  setTitle() {
    this.title.setTitle(`Sbu - ${this.sbu.name}`);
  }

  updateSbu() {
    this.isSaving = true;
    this.sbuService.update(this.model, this.model.sbuId, true, {includeLeaders: true, includeManagingDirectors: true, includeEmployees: true}).subscribe({
      next: () => {
        this.sbu = { ...this.model };
        this.setTitle();
        this.mode = 'read';
        this.isSaving = false;
        this.showSuccess();
      },
      error: () => {
        this.isSaving = false;
        this.showError();
      }
    });
    
  }

  edit() {
    this.mode = 'update';
  }

  save() {
    if (this.model.employees) {
      const employees = this.model.employees.filter(pe => this.employeesModel.find(pem => pem.employeeId === pe.employeeId));
      const newEmployees = this.employeesModel.filter(pe => !(this.model.employees?.find(pem => pem.employeeId === pe.employeeId)));
      this.model.employees = [...employees, ...newEmployees];
    }

    if (this.model.sbuLeaders) {
      const sbuLeaders = this.model.sbuLeaders.filter(pe => this.sbuLeadersModel.find(pem => pem.employeeId === pe.employeeId));

      const newSbuLeaderModels = this.sbuLeadersModel.filter(pe => !(this.model.sbuLeaders?.find(pem => pem.employeeId === pe.employeeId)));
      const newSbuLeaders = newSbuLeaderModels
        .filter(pe=> pe.employeeId && this.model.sbuId)
        .map<ISbuLeader>(pe => ({employeeId: pe.employeeId as number, sbuId: this.model.sbuId as number}));

      this.model.sbuLeaders = [...sbuLeaders, ...newSbuLeaders];
    }

    if (this.model.managingDirectors) {
      const managingDirectors = this.model.managingDirectors.filter(pe => this.managingDirectorsModel.find(pem => pem.employeeId === pe.employeeId));

      const newManagingDirectorModels = this.managingDirectorsModel.filter(pe => !(this.model.managingDirectors?.find(pem => pem.employeeId === pe.employeeId)));
      const newManagingDirectors = newManagingDirectorModels
          .flatMap(pe => pe.employeeId && this.model.sbuId ? [{employeeId: pe.employeeId, sbuId: this.model.sbuId}] : []);
      this.model.managingDirectors = [...managingDirectors, ...newManagingDirectors];
    }

    this.updateSbu();
  }

  onCancelCheck(){
    return JSON.stringify(this.sbu) === JSON.stringify(this.model);
  }

  cancelUpdate() {
    this.model = { ...this.sbu };
    this.managingDirectorsModel = this.sbu.managingDirectors
      ? this.sbu.managingDirectors.flatMap(pe => pe.employee ? [pe.employee] : [])
      :[];
    this.sbuLeadersModel = this.sbu.sbuLeaders
      ? this.sbu.sbuLeaders.flatMap(pl => pl.employee ? [pl.employee] : [])
      : [];
  
    this.employeesModel = (this.sbu.employees) ? [...this.sbu.employees] : [];
    this.router.navigate(['/sbus']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'SBU'},
    });    
  }

  saveFilename(filename?: string) {
    this.sbu.photoName = filename;
    this.model.photoName = filename;
    this.sbuService.update(this.sbu, this.sbu.sbuId, false).subscribe({
      next: () => {
        this.showSuccess();
      },
      error: () => {
        this.showError();
      }
    });
  }

  showSuccess() {    
    this.notificationSuccessRef = this.notificationService.open(
      ToastSuccessComponent,
      { data: { text: 'SBU updated!'},
      position: 'bottom-center',
      autohide: true });
  }

  showError() {
    this.notificationSuccessRef = this.notificationService.open(
      ToastErrorComponent,
      { data: { text: 'Something went wrong. SBU could not be updated!'},
      position: 'bottom-center',
      autohide: true });
  }
  
}