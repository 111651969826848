import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { IDepartment } from 'src/app/models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { accessToken } from 'src/app/store/state/auth.state';
import { setImagefolder, setImageUniqueId, setPhotoName } from 'src/app/store/actions/auth.actions';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;
  
  id: string | null = null;
  department: IDepartment = { isActive: true }
  model: IDepartment = { isActive: true }
  mode = 'read';
  file: File | null = null;
  defaultPhoto = '';
  isLoading = false;
  isLoaded = false;
  isSaving = false;


  constructor(private readonly departmentService: DepartmentService
              , private readonly store: Store<RootState>
              , private notificationService: MdbNotificationService
              , private modalService: MdbModalService
              , private readonly activatedRoute: ActivatedRoute
              , private readonly title: Title
              , private readonly router:Router) 
  {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken && id !== null)
        {
          this.isLoading = true;
          this.getDepartment(id);
        }
    })   
  }

  getDepartment(id: string) {
    this.departmentService.get(id).subscribe({
      next: (department) => {
        this.department = department;
        this.model = { ...department };
        this.setTitle();     

        this.store.dispatch(setPhotoName({photoName: this.model.photoName ?  this.model.photoName : 'No_Image_Available.jpg'}));
        this.store.dispatch(setImagefolder({folderLocation: 'departmentphotos'}));
        this.store.dispatch(setImageUniqueId({imageUniqueId: new Date().getTime()}));

        this.isLoaded = true;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.showError();
      }
    });
  }

  setTitle() {
    this.title.setTitle(`Department - ${this.department.name}`);
  }

  updateDepartment() {
    this.departmentService.update(this.model, this.model.departmentId).subscribe({
      next: () => {
        this.department = { ...this.model };
        this.setTitle();
        this.mode = 'read';
        this.isSaving = false;
        this.showSuccess();
      },
      error: () => {
        this.isSaving = false;
        this.showError();
      }
    });    
  }

  edit() {
    this.mode = 'update';
  }

  save() {
    this.updateDepartment();
  }

  onCancelCheck(){
    return JSON.stringify(this.department) === JSON.stringify(this.model);
  }

  cancelUpdate() {
    this.model = { ...this.department };
    this.router.navigate(['/departments']);
  }

  saveFilename(filename?: string) {
    this.department.photoName = filename;
    this.model.photoName = filename;
    this.departmentService.update(this.department, this.department.departmentId).subscribe({
      error: () => {
        this.showError();
      }
    });
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'Department'},
    });    
  }
     
  showSuccess() {    
    this.notificationSuccessRef = this.notificationService.open(
      ToastSuccessComponent,
      { data: { text: 'Department updated!'},
      position: 'bottom-center',
      autohide: true });
  }

  showError() {
    this.notificationSuccessRef = this.notificationService.open(
      ToastErrorComponent,
      { data: { text: 'Something went wrong. Department could not be updated!'},
      position: 'bottom-center',
      autohide: true });
  }
}