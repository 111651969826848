import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppAuthenticatedBaseService } from './app-authenticated-base.service';
import { RootState } from '../store/root.state';

export class AppAuthenticatedCrudBaseService<T> extends AppAuthenticatedBaseService {
  constructor(protected readonly http: HttpClient,
              protected readonly controllerPath: string,
              store: Store<RootState>) {
    super(store);
  }

  count? = 0;

  create(model: T, includeChildren = false): Observable<T> {
    const parameter = includeChildren ? model : this.createDto(model);
    const url = `${this.serviceUrl}/${this.controllerPath}`;
    return this.http.post<T>(url, parameter, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  get(id?: string | number, queryParams?: Record<string, unknown>): Observable<T> {
    const url = `${this.serviceUrl}/${this.controllerPath}/${encodeURIComponent(id ?? "")}${this.createQueryString(queryParams)}`;
    return this.http.get<T>(url, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  getAll(queryParams?: Record<string, unknown>): Observable<T[]> {
    const url = `${this.serviceUrl}/${this.controllerPath}${this.createQueryString(queryParams)}`;
    return this.http.get<T[]>(url, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  update(model: T, id: string | number | null | undefined, includeChildren = false, queryParams?: Record<string, unknown>): Observable<T> {
    const parameter = includeChildren ? model : this.createDto(model);
    const url = `${this.serviceUrl}/${this.controllerPath}/${encodeURIComponent(id ?? "")}${this.createQueryString(queryParams)}`;
    return this.http.put<T>(url, parameter, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  updateAll(listItems: T[], includeChildren = false, queryParams?: Record<string, unknown>): Observable<T[]> {
    const parameter = includeChildren ? listItems : this.createListDto(listItems);
    const url = `${this.serviceUrl}/${this.controllerPath}/${this.createQueryString(queryParams)}`;
    return this.http.put<T[]>(url, parameter, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  delete(id: string| number): Observable<T> {
    const url = `${this.serviceUrl}/${this.controllerPath}/${encodeURIComponent(id)}`;
    return this.http.delete<T>(url, this.getHttpOptions()).pipe(catchError(this.handleError));
  }
  
  protected createDto(model: T): T {
    return model;
  }

  protected createListDto(listItems: T[]) : T[] {
    return listItems;
  }
}

