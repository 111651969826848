import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { IPsc } from '../models/psc.model';
import { IPscEmployee } from '../models/psc-employee.model';
import { IPscLeader } from '../models/psc-leader.model';
import { Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class PscService extends AppAuthenticatedCrudBaseService<IPsc> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'Psc', store);
  }
  createDto(model: IPsc): IPsc {
    let pscLeaders = model.pscLeaders;
    if (model.pscLeaders) pscLeaders = model.pscLeaders.map(pl => {
      return {
        pscLeaderId: pl.pscLeaderId,
        employeeId: pl.employeeId,
        pscId: pl.pscId
      } as IPscLeader;
    });
    let pscEmployees = model.pscEmployees;
    if (model.pscEmployees) pscEmployees = model.pscEmployees.map(pl => {
      return {
        pscEmployeeId: pl.pscEmployeeId,
        employeeId: pl.employeeId,
        pscId: pl.pscId
      } as IPscEmployee;
    });
    const result: IPsc =
    {
      pscId: model.pscId,
      name: model.name,
      pscCode: model.pscCode,
      photoName: model.photoName,
      isActive: model.isActive,
      pscLeaders: pscLeaders,
      pscEmployees: pscEmployees
    };
    return result;
  }
}
