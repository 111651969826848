import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IProficiency } from 'src/app/models/proficiency.model';

export const getAllProficiency = createAction(
  '[Proficiency] Get All',
  props<{ queryParams: Record<string, unknown> }>()
);

export const getAllProficiencySuccess = createAction(
  '[Proficiency] Get All Success',
  props<{ models: IProficiency[] }>()
);

export const getAllProficiencyFailure = createAction(
  '[Proficiency] Get All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export type GetAllProficiencyAction = ReturnType<typeof getAllProficiency>;
export type GetAllProficiencySuccessAction = ReturnType<typeof getAllProficiencySuccess>;
export type GetAllProficiencyFailureAction = ReturnType<typeof getAllProficiencyFailure>;