import { IPscEmployee } from './psc-employee.model';
import { IPscLeader } from './psc-leader.model';

export interface IPsc {
  pscId?: number;
  pscCode?:string;
  name?: string;
  photoName?: string;
  isActive: boolean;
  pscLeaders?: IPscLeader[];
  pscEmployees?: IPscEmployee[];
}

export const PscFeature = {
  FeatureName: 'Psc',
  ControllerPath: 'psc',
  CreateSuccessMessage: 'Psc created successfully',
  CreateFailureMessage: 'Psc creation failed',
  GetFailureMessage: 'Failed to retrieve Pscs',
  UpdateSuccessMessage: 'Psc updated successfully',
  UpdateFailureMessage: 'Psc update failed',
  DeleteSuccessMessage: 'Psc deleted successfully',
  DeleteFailureMessage: 'Psc deletion failed',
};

export const PscsFeature = {
  FeatureName: 'Pscs',
  ControllerPath: 'psc',
  GetFailureMessage: 'Failed to retrieve Pscs',
};