import { createReducer, on } from "@ngrx/store";
import * as EmployeeNotaryActions from "../actions/employee-notary.actions";
import { EmployeeNotaryState, initialEmployeeNotaryState } from "../state/employee-notary.state";
import { IEmployeeNotary } from "src/app/models/employee-notary.model";

export const employeeNotaryReducer = createReducer(
  initialEmployeeNotaryState,
  on(EmployeeNotaryActions.createEmployeeNotary, (state: EmployeeNotaryState, { model }) => {
    return { ...state, 
        employeeNotaries: [...state.employeeNotaries, model], 
        isLoading: true, 
        canUpdate: false, 
        error: null};
  }),
  on(EmployeeNotaryActions.createEmployeeNotarySuccess, (state: EmployeeNotaryState, { model }) => {
    return { ...state, 
      currentEmployeeNotary: model, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeNotaryActions.createEmployeeNotaryFailure, (state: EmployeeNotaryState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeNotaryActions.deleteEmployeeNotary, (state: EmployeeNotaryState, { id }) => { 
    return { ...state, 
      employeeNotarys: state.employeeNotaries.filter((notary: IEmployeeNotary) => notary.id !== id), 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeNotaryActions.deleteEmployeeNotarySuccess, (state: EmployeeNotaryState) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeNotaryActions.deleteEmployeeNotaryFailure, (state: EmployeeNotaryState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeNotaryActions.getAllEmployeeNotary, (state: EmployeeNotaryState) => {
    return { ...state, 
      employeeNotaries: [], 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeNotaryActions.getAllEmployeeNotarySuccess, (state: EmployeeNotaryState, { models }) => {
    return { ...state, 
      employeeNotaries: models, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeNotaryActions.getAllEmployeeNotaryFailure, (state: EmployeeNotaryState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeNotaryActions.setEmployeeNotaryCanUpdate, (state: EmployeeNotaryState, { canUpdate }) => {
    return { ...state, canUpdate };
  }),
  on(EmployeeNotaryActions.setAllEmployeeNotary, (state: EmployeeNotaryState, { models }) => {
    return { ...state, 
      employeeNotaries: models,
      isLoading: false, 
      canUpdate: false, 
     };
  }),
  on(EmployeeNotaryActions.updateAllEmployeeNotary, (state: EmployeeNotaryState, { models }) => {
    return { ...state, 
      employeeNotarys: models, 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeNotaryActions.updateAllEmployeeNotarySuccess, (state: EmployeeNotaryState, { models }) => {
    return { ...state, 
      employeeNotarys: models, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeNotaryActions.updateAllEmployeeNotaryFailure, (state: EmployeeNotaryState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }), 
 
);