import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneComponent  {
  @Input() cssClass!: string;
  @Input() labelHtml!: string;
  @Input() fieldName!: string;
  @Input() fieldValue: string | undefined;  
  @Input() readonly!: boolean | null;
  @Input() disabled!: boolean | null;
    @Output() fieldValueChange = new EventEmitter<string | undefined>();

}