import { createReducer, on } from "@ngrx/store";
import * as EmployeeBarAdmissionActions from "../actions/employee-bar-admission.actions";
import { EmployeeBarAdmissionState, initialEmployeeBarAdmissionState } from "../state/employee-bar-admission.state";
import { IEmployeeBarAdmission } from "src/app/models/employee-bar-admission.model";

export const employeeBarAdmissionReducer = createReducer(
  initialEmployeeBarAdmissionState,
  on(EmployeeBarAdmissionActions.createEmployeeBarAdmission, (state: EmployeeBarAdmissionState, { model }) => {
    return { ...state, 
        employeeBarAdmissions: [...state.employeeBarAdmissions, model], 
        isLoading: true, 
        canUpdate: false, 
        error: null};
  }),
  on(EmployeeBarAdmissionActions.createEmployeeBarAdmissionSuccess, (state: EmployeeBarAdmissionState, { model }) => {
    return { ...state, 
      currentEmployeeBarAdmission: model, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeBarAdmissionActions.createEmployeeBarAdmissionFailure, (state: EmployeeBarAdmissionState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeBarAdmissionActions.deleteEmployeeBarAdmission, (state: EmployeeBarAdmissionState, { id }) => { 
    return { ...state, 
      employeeBarAdmissions: state.employeeBarAdmissions.filter((admission: IEmployeeBarAdmission) => admission.id !== id), 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeBarAdmissionActions.deleteEmployeeBarAdmissionSuccess, (state: EmployeeBarAdmissionState) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeBarAdmissionActions.deleteEmployeeBarAdmissionFailure, (state: EmployeeBarAdmissionState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeBarAdmissionActions.getAllEmployeeBarAdmission, (state: EmployeeBarAdmissionState) => {
    return { ...state, 
      employeeBarAdmissions: [], 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeBarAdmissionActions.getAllEmployeeBarAdmissionSuccess, (state: EmployeeBarAdmissionState, { models }) => {
    return { ...state, 
      employeeBarAdmissions: models, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeBarAdmissionActions.getAllEmployeeBarAdmissionFailure, (state: EmployeeBarAdmissionState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }),
  on(EmployeeBarAdmissionActions.setEmployeeBarAdmissionCanUpdate, (state: EmployeeBarAdmissionState, { canUpdate }) => {
    return { ...state, canUpdate };
  }),
  on(EmployeeBarAdmissionActions.setAllEmployeeBarAdmission, (state: EmployeeBarAdmissionState, { models }) => {
    return { ...state, 
      employeeBarAdmissions: models,
      isLoading: false, 
      canUpdate: false, 
     };
  }),
  on(EmployeeBarAdmissionActions.updateAllEmployeeBarAdmission, (state: EmployeeBarAdmissionState, { models }) => {
    return { ...state, 
      employeeBarAdmissions: models, 
      isLoading: true, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeBarAdmissionActions.updateAllEmployeeBarAdmissionSuccess, (state: EmployeeBarAdmissionState, { models }) => {
    return { ...state, 
      employeeBarAdmissions: models, 
      isLoading: false, 
      canUpdate: false, 
      error: null };
  }),
  on(EmployeeBarAdmissionActions.updateAllEmployeeBarAdmissionFailure, (state: EmployeeBarAdmissionState, { error }) => {
    return { ...state, 
      isLoading: false, 
      canUpdate: false, 
      error };
  }), 
 
);