import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent  {

  @Input() type!: string;
  @Input() className!: string;
  @Input() fieldName!: string;
  @Input() label!: string;
  @Input() inputModel: string | null | undefined;  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() inputModelChange = new EventEmitter<any>();


}
