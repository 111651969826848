import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SupportTeamService } from '../../services/support-team.service';
import { ISupportTeam } from '../../models/support-team.model';
import { EmployeeService } from "../../services/employee.service";
import { IEmployee } from "../../models/employee.model";
import { accessToken } from 'src/app/store/state/auth.state';
import { select, Store } from '@ngrx/store';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { ISupportTeamMember } from 'src/app/models/support-team-member';
import { setImagefolder, setImageUniqueId, setPhotoName } from 'src/app/store/actions/auth.actions';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'app-support-team',
  templateUrl: './support-team.component.html',
  styleUrls: ['./support-team.component.scss']
})
export class SupportTeamComponent implements OnInit {

  modalRef: MdbModalRef<ModalCancelComponent> | null = null;
  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;

  id: string | null = null;
  supportTeam: ISupportTeam = { isActive: true }
  model: ISupportTeam = { isActive: true }
  mode = 'read';  
  membersModel: ISupportTeamMember[] = [];
  isLoading = false;
  isLoaded = false;
  isSaving = false;

  constructor(private readonly supportTeamService: SupportTeamService
              , private notificationService: MdbNotificationService
              , private readonly employeeService: EmployeeService
              , private modalService: MdbModalService
              , private readonly store: Store<RootState>
              , private readonly activatedRoute: ActivatedRoute
              , private readonly title: Title
              , private readonly router:Router) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken && id !== null)
        {
          this.isLoading = true;
          this.getSupportTeam(id);
          this.loadEmployees();
          this.searchCoordinatorEmployees();
          this.searchBackupCoordinatorEmployees();
        }
    })   
  }

  getSupportTeam(id: string) {
    
    this.supportTeamService.get(id, { includeMembers: true }).subscribe({
      next: (supportTeam) => {
        this.supportTeam = supportTeam;
        this.model = { ...supportTeam };
        this.membersModel = (supportTeam.supportTeamMembers) ? supportTeam.supportTeamMembers
            .filter(m => m.employee !== undefined)
            .map(m => m.employee as IEmployee) : [];
        this.store.dispatch(setPhotoName({photoName: this.model.photoName ?  this.model.photoName : 'No_Image_Available.jpg'}));
        this.store.dispatch(setImagefolder({folderLocation: 'supportteamphotos'}));
        this.store.dispatch(setImageUniqueId({imageUniqueId: new Date().getTime()}));
        this.title.setTitle(`Support Team - ${this.supportTeam.name}`);
        this.isLoaded = true;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.showError();
      }
    });

  }

  members$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  membersLoading = false;
  membersInput$ = new Subject<string>();

  private loadEmployees() {
    this.members$ = concat(
      of([]),
      this.membersInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.membersLoading = true),
        switchMap(term => this.employeeService.getAll({ isActive: true, name: term }).pipe(
          catchError(() => of([])),
          tap(() => this.membersLoading = false)
        ))
      )
    );
  }

  trackByFn(item: IEmployee) {
    return item.employeeId;
  }

  coordinatorEmployees$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  coordinatorEmployeesLoading = false;
  coordinatorEmployeeInput$ = new Subject<string>();

  private searchCoordinatorEmployees() {
    this.coordinatorEmployees$ = concat(
      of([]),
      this.coordinatorEmployeeInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.coordinatorEmployeesLoading = true),
        switchMap(term => this.employeeService.getAll({ isActive: true, name: term }).pipe(
          catchError(() => of([])),
          tap(() => this.coordinatorEmployeesLoading = false)
        ))
      )
    );
  }

  backupCoordinatorEmployees$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  backupCoordinatorEmployeesLoading = false;
  backupCoordinatorEmployeeInput$ = new Subject<string>();

  private searchBackupCoordinatorEmployees() {
    this.backupCoordinatorEmployees$ = concat(
      of([]),
      this.backupCoordinatorEmployeeInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.backupCoordinatorEmployeesLoading = true),
        switchMap(term => this.employeeService.getAll({ isActive: true, name: term }).pipe(
          catchError(() => of([])),
          tap(() => this.backupCoordinatorEmployeesLoading = false)
        ))
      )
    );
  }

  setTitle() {
    this.title.setTitle(`SupportTeam - ${this.supportTeam.name}`);
  }

  updateSupportTeam() {
    this.isSaving = true;    

    this.supportTeamService.update(this.model, this.model.supportTeamId, false, {includeMembers: true}).subscribe({
      next: () => {
        this.supportTeam = { ...this.model };
        this.setTitle();
        this.mode = 'read';
        this.isSaving = false;
        this.showSuccess();
      },
      error: () => {
        this.isSaving = false;
        this.showError();
      }
    });
  }

  edit() {
    this.mode = 'update';
  }

  save() {    
    if (this.model.supportTeamMembers) {
      const members = this.model.supportTeamMembers.filter(pe => this.membersModel.find(pem => pem.employeeId === pe.employeeId));

      const newEmployees = this.membersModel.filter(pe => !(this.model.supportTeamMembers?.find(pem => pem.employeeId === pe.employeeId)));
      const newMembers = newEmployees
          .filter(pe => pe.employeeId !== undefined && this.supportTeam.supportTeamId !== undefined)
          .map<ISupportTeamMember>(pe => ({employeeId: pe.employeeId as number, supportTeamId: this.supportTeam.supportTeamId as number}));

      this.model.supportTeamMembers = [...members, ...newMembers];
    }

    this.model.coordinatorId = this.model.coordinator?.employeeId;
    this.model.backupCoordinatorId = this.model.backupCoordinator?.employeeId;

    this.updateSupportTeam();
  }

  saveFilename(filename?: string) {
    this.supportTeam.photoName = filename;
    this.model.photoName = filename;
    this.supportTeamService.update(this.supportTeam, this.supportTeam.supportTeamId, false).subscribe({
      next: () => {
        this.showSuccess();
      },
      error: () => {
        this.showError();
      }
    })
  }

  onCancelCheck(){
    return JSON.stringify(this.supportTeam) === JSON.stringify(this.model);
  }

  cancelUpdate() {
    this.model = { ...this.supportTeam };
    this.router.navigate(['/support-teams']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'SupportTeam'},
    });    
  }

  showSuccess() {    
    this.notificationSuccessRef = this.notificationService.open(
      ToastSuccessComponent,
      { data: { text: 'Support Team updated!'},
      position: 'bottom-center',
      autohide: true });
  }

  showError() {
    this.notificationSuccessRef = this.notificationService.open(
      ToastErrorComponent,
      { data: { text: 'Something went wrong. Support Team could not be updated!'},
      position: 'bottom-center',
      autohide: true });
  }
}
