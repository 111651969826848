import { IEmployeeEducation } from "src/app/models/employee-education.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface EmployeeEducationState extends BaseState {
  employeeEducation: IEmployeeEducation[];
}

export const initialEmployeeEducationState: EmployeeEducationState = {
  ...initialBaseState,
  employeeEducation: [],
};
