import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ISupportTeam } from '../../models/support-team.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { select, Store } from '@ngrx/store';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';
import { Profile } from 'src/app/models/user.model';
import { Observable, map, of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { Feature } from 'src/app/models/features.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';

@Component({
  selector: 'app-support-teams',
  templateUrl: './support-teams.component.html',
  styleUrls: ['./support-teams.component.scss']
})
export class SupportTeamsComponent implements OnInit {

  @ViewChild('table') table!: MdbTableDirective<ISupportTeam>;

  supportTeams$: Observable<ISupportTeam[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  user$!: Observable<Profile | null>;
  headers = ['name', 'coordinator', 'backup coordinator', 'contact', 'active'];
  
  constructor(private readonly store: Store<RootState>
              , private readonly router:Router
              , private readonly title: Title
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectors.supportTeams.isLoading);
    this.supportTeams$ = this.store.select(selectors.supportTeams.get).pipe(
      map(supportTeams => Array.isArray(supportTeams) ? supportTeams : [])
    );
    this.user$ = this.store.pipe(select(user));
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.store.dispatch(CrudActions.get(Feature.SupportTeams)());
      }
    });
    this.title.setTitle(`Support Teams`);
  }

  handleClick(supportTeam: ISupportTeam) {
    this.router.navigate(['/support-teams/', supportTeam.supportTeamId]);
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.table.search(searchTerm);
  }

}
