import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { CountyState } from '../state/county.state.';

const state = (state: RootState) => state.county;

const isLoading = createSelector(
  state,
  (state: CountyState) => state.isLoading
);

const isSaving = createSelector(
  state,
  (state: CountyState) => state.isSaving
);

const getAll = createSelector(
  state,
  (state: CountyState) => state.counties
);

export const countySelectors = {
  isLoading,
  isSaving,
  getAll
};