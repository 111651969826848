import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEmployeeContact } from 'src/app/models/employee-contact.model';

export const getEmployeeContact = createAction(
  '[EmployeeContact] Get',
  props<{ id: string | number }>()
);

export const getEmployeeContactSuccess = createAction(
  '[EmployeeContact] Get Success',
  props<{ model: IEmployeeContact }>()
);

export const getEmployeeContactFailure = createAction(
  '[EmployeeContact] Get Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const updateEmployeeContact = createAction(
  '[EmployeeContact] Update',
  props<{ model: IEmployeeContact, id: string | number | null | undefined, includeChildren: boolean, queryParams: Record<string, unknown> }>()
);

export const updateEmployeeContactSuccess = createAction(
  '[EmployeeContact] Update Success',
  props<{ model: IEmployeeContact }>()
);

export const updateEmployeeContactFailure = createAction(
  '[EmployeeContact] Update Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const setEmployeeContactCanUpdate = createAction(
  '[EmployeeContact] Set Can Update',
  props<{ canUpdate: boolean }>()
);

export type GetEmployeeContactAction = ReturnType<typeof getEmployeeContact>;
export type GetEmployeeContactSuccessAction = ReturnType<typeof getEmployeeContactSuccess>;
export type GetEmployeeContactFailureAction = ReturnType<typeof getEmployeeContactFailure>;

export type UpdateEmployeeContactAction = ReturnType<typeof updateEmployeeContact>;
export type UpdateEmployeeContactSuccessAction = ReturnType<typeof updateEmployeeContactSuccess>;
export type UpdateEmployeeContactFailureAction = ReturnType<typeof updateEmployeeContactFailure>;

export type SetEmployeeContactCanUpdateAction = ReturnType<typeof setEmployeeContactCanUpdate>;
