import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeOrgActions from '../actions/employee-org.actions';
import { EmployeeOrgService } from 'src/app/services/employee-org.service';
import { IEmployeeOrg } from 'src/app/models/employee-org.model';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';

@Injectable()
export class EmployeeOrgEffects {
  private actions$ = inject(Actions);

  constructor(
    private employeeOrgService: EmployeeOrgService
  ) {}

  getEmployeeOrg$ = createEffect(() =>  
    this.actions$.pipe(
      ofType(EmployeeOrgActions.getEmployeeOrg),
      mergeMap((action: EmployeeOrgActions.GetEmployeeOrgAction) => {
        return this.employeeOrgService.get(action.id).pipe(
          map((model: IEmployeeOrg) => {
            return EmployeeOrgActions.getEmployeeOrgSuccess({ model });
          }),
          catchError(error => {
            return of(
              EmployeeOrgActions.getEmployeeOrgFailure({ error }),
              toastErrorAction({ message: 'Failed to get employee org' })
            );
          })
        );
      })
    )
  );

  updateEmployeeOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeOrgActions.updateEmployeeOrg),
      mergeMap((action: EmployeeOrgActions.UpdateEmployeeOrgAction)  => {
        return this.employeeOrgService.update(action.model, action.id, action.includeChildren, action.queryParams).pipe(
          concatMap((model: IEmployeeOrg) => {
            return [
              EmployeeOrgActions.updateEmployeeOrgSuccess({ model }),
              toastSuccessAction({ message: 'Employee org updated successfully' })
            ];
          }),
          catchError(error => {
            return of(
              EmployeeOrgActions.updateEmployeeOrgFailure({ error }),
              toastErrorAction({ message: 'Failed to update employee org' })
            );
          })
        );
      })
    )
  );

}