<div class="row">
  <div class="col-md-12">
    <div class="d-flex align-items-center col-12 mt-4">
      <div class="d-flex align-items-center" *ngIf="(isLoading$ | async)">
        <strong>Loading...</strong>
        <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
      </div>
      <ng-container *ngIf="employeeEducation$ | async as employeeEducation">
        <div *ngIf="employeeEducation.length === 0 && (isLoading$ | async) === false">
          <h6>No Education found.</h6>
        </div>
        <table class="table align-middle mb-0 bg-white" *ngIf="employeeEducation.length > 0 && (isLoading$ | async) === false" >
          <thead class="bg-light">
            <tr>
              <th>School</th>
              <th>Major</th>
              <th>Year</th>
              <th>Level</th>          
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let education of employeeEducation;let id=index">
              <td>{{education.school}}</td>        
              <td>{{education.major}}</td>
              <td>{{education.year}}</td>
              <td>{{education.education}}</td>
            </tr>
          </tbody>
        </table>    
      </ng-container> 
    </div>  
  </div>
</div>