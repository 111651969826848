import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { IEmployee } from 'src/app/models/employee.model';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';
import { ICrudRouteParams } from 'src/app/models/crud-route-params';

@Component({
  selector: 'app-employee-new',
  templateUrl: './employee-new.component.html',
  styleUrls: ['./employee-new.component.scss']
})
export class EmployeeNewComponent implements OnInit {
  
  modalRef!: MdbModalRef<ModalCancelComponent>;  
  isSaving$: Observable<boolean> = of(false);
  model: IEmployee = {}
  showAlert = false;  
  showRequiredMessage = false;
  
  constructor(private readonly store: Store<RootState>
              , private readonly title: Title
              , private modalService: MdbModalService
              , private readonly router:Router) 
  {}

  ngOnInit(): void {
    this.isSaving$ = this.store.select(selectors.employee.isSaving);
    this.title.setTitle('Add New Employee');    
  }

  save() {
    this.showRequiredMessage = false;
    this.showAlert = false;
    if(this.isEmployeeComplete(this.model)){
      const route: ICrudRouteParams<IEmployee> = { 
       route: ['/employees/'],
       idPropertyName: 'employeeId'
      };
      this.store.dispatch(CrudActions.create<IEmployee>(Feature.Employee)(this.model, route));
    }    
    else {
      this.showRequiredMessage = true;
    }
  }

  cancelUpdate(){
    this.router.navigate(['/']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'Employee-New'},
    });    
  }

  onAlertClose(){
    this.showAlert = false;
  }

  onRequiredMessageClose(){
    this.showRequiredMessage = false;
  }

  isEmployeeComplete(employee: IEmployee){
    return ((employee.firstName != undefined && employee.firstName.trim() != '')
                  && (employee.legalLastName != undefined && employee.legalLastName.trim() !== '')
                  && (employee.email != undefined && employee.email.trim() != '')
                  && (employee.ntId != undefined && employee.ntId.trim() != '')
                  && (employee.timeKeeperNumber != undefined && employee.timeKeeperNumber.trim() != ''));
  }

}