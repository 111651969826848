import { IEmployeeLanguage } from "src/app/models/employee-language.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface EmployeeLanguageState extends BaseState {
  employeeLanguages: IEmployeeLanguage[];
}

export const initialEmployeeLanguageState: EmployeeLanguageState = {
  ...initialBaseState,
  employeeLanguages: [],
};
