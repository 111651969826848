import { IFeature } from "./features.model";

export interface IEmployee
	{
		employeeId?: number;
		ntId?: string;
		email?: string;
		timeKeeperNumber?: string;		
		firstName?: string;
		professionalFirstName?: string;
		middleName?: string;		
		preferredLastName?: string;
		legalLastName?: string;
		nickName?: string;
		suffix?: string;
		prefix?: string;
		initials?: string;
		lastName?: string;
		displayName?: string;
		displayNameLastNameFirst?: string;
		imageUrl?:string,
		photoName?: string;
		language?: string;
		birthDate?: Date;
		isNotary? : boolean;
		texasCertification?: string;
		recognitions?: string;
		professionalAssociations?: string;
		twitterUrl?: string;
		linkedInUrl?: string;
		bioBody?: string;
		bioBodyAdmin?: string;
		experience?: string;
		keepBirthDateConfidential? : boolean;
	}

	export const EmployeeFeature: IFeature = {
		FeatureName: 'Employee',
		ControllerPath: 'employee',
		CreateSuccessMessage: 'Employee created successfully.',
		CreateFailureMessage: 'Failed to create employee.', 
		UpdateSuccessMessage: 'Employee updated successfully.',
		UpdateFailureMessage: 'Failed to update employee.',
		GetFailureMessage	: 'Failed to get employee.',
	};
	
	export const EmployeesFeature: IFeature = {
		FeatureName: 'Employees',
		ControllerPath: 'employee',
		GetFailureMessage: 'Failed to get employees.',
	};