<div class="card" [style.width]="isSmallCard? '210px': '500px'">
  <img src={{imageSrc}} *ngIf="mode==='read'" alt="..." [ngStyle]="isSmallCard ? {'height':'250px', width:'210px'} : {'height':'', width:''}" />
  <mdb-file-upload (fileRemoved)="onFileRemove()" 
                    *ngIf="mode==='update'"
                    [height]="isSmallCard ? 300 : 350"         
                    [acceptedExtensions]="'image/*'"
                    (fileAdded)="onFileAdd($event)">
  </mdb-file-upload>
  <div class="card-body">
    <button type="button" 
            class="btn btn-primary btn-sm float-end small"
            *ngIf="mode==='read' && (isEmployeePhoto ? (((user$ | async)?.isAdmin) || ((user$ | async)?.isHR)) : ((user$ | async)?.isAdmin))"
            (click)="edit()">
      <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving" aria-hidden="true"></span>
      <i class="fas fa-upload"></i> Change Photo
    </button>
    <button type="button" 
            class="btn btn-primary btn-sm float-end small"
            *ngIf="mode==='update'"            
            (click)="cancelUpdate()"><i class="fas fa-times"></i> Cancel</button>
  </div>
  <div *ngIf="isMaxSize"><span class="text-danger"> Your file is too big. Max size allowed is 1MB.</span></div>  
</div>