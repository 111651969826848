import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { IEmployee } from 'src/app/models/employee.model';
import { ISupportTeamMember } from 'src/app/models/support-team-member';
import { ISupportTeam, createSupportTeamDto } from 'src/app/models/support-team.model';
import { accessToken } from 'src/app/store/state/auth.state';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { RootState } from 'src/app/store/root.state';
import { toastErrorAction } from 'src/app/store/actions/toast.actions';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';
import { selectors } from 'src/app/store/selectors/selectors';

@Component({
  selector: 'app-support-team-new',
  templateUrl: './support-team-new.component.html',
  styleUrls: ['./support-team-new.component.scss']
})
export class SupportTeamNewComponent implements OnInit {
  newSupportTeam$: Observable<ISupportTeam> = of({ name:'', isActive: true });
  isSaving$: Observable<boolean> = of(false);

  model: ISupportTeam = { name:'', isActive: true };
  membersModel: ISupportTeamMember[] = [];
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;
  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
    
  employeesIsLoading$: Observable<boolean> = of(false);
  coordinatorEmployees$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  coordinatorEmployeeInput$ = new Subject<string>();
  backupCoordinatorEmployees$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  backupCoordinatorEmployeeInput$ = new Subject<string>();
  
  constructor( private readonly title: Title
              , private modalService: MdbModalService
              , private readonly router:Router
              , private readonly store: Store<RootState> ) { }

  ngOnInit(): void {
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken)
        {
          this.searchCoordinatorEmployees();
          this.searchBackupCoordinatorEmployees();
        }
    });
    this.title.setTitle(`New Support Team`);
  }

  trackByFn(item: IEmployee) {
    return item.employeeId;
  }

  private searchCoordinatorEmployees() {
    this.coordinatorEmployees$ = this.coordinatorEmployeeInput$.pipe(
      distinctUntilChanged(),
      switchMap((term: string) => {
        this.store.dispatch(CrudActions.get(Feature.Employees)(undefined, { isActive: true, name: term }));
        return this.store.select(selectors.employees.get);
      })
    );
  }

  private searchBackupCoordinatorEmployees() {
    this.backupCoordinatorEmployees$ = this.backupCoordinatorEmployeeInput$.pipe(
      distinctUntilChanged(),
      switchMap((term: string) => {
        this.store.dispatch(CrudActions.get(Feature.Employees)(undefined, { isActive: true, name: term }));
        return this.store.select(selectors.employees.get);
      })
    );
  }

  save() {
    if(this.model.name && this.model.name.trim() != ''){
      this.model.coordinatorId = this.model.coordinator?.employeeId;
      this.model.backupCoordinatorId = this.model.backupCoordinator?.employeeId;
      this.store.dispatch(CrudActions.create<ISupportTeam>(Feature.SupportTeams)(createSupportTeamDto(this.model)));
    }
    else {
      this.store.dispatch(toastErrorAction({ message: 'A name is required!' }));
    }  
  }
 
  cancelUpdate(){
    this.model = { name:'', isActive: true };
    this.router.navigate(['/']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'SupportTeam-New'},
    });    
  }
 
  
}
