import { setAccessToken, setAzureStorageKey, setImagefolder, setImageUniqueId, setIsLoading, setIsSaving, setLoginDisplay, setPhotoName, setUser } from '../actions/auth.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthState, initialState } from '../state/auth.state';

const _authReducer = createReducer(
  initialState,
  on(setLoginDisplay, (state, action) => {    
    return {
      ...state,
      loginDisplay: action.loginDisplay,
    };
  }),
  on(setUser, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),
  on(setAzureStorageKey, (state, action) => {
    return {
      ...state,
      azureStorageKey: action.azureStorageKey,
    };
  }),
  on(setAccessToken, (state, action) => {
    return {
      ...state,
      accessToken: action.accessToken,
    };
  }),
  on(setPhotoName, (state, action) => {
    return {
      ...state,
      photoName: action.photoName,
    };
  }),
  on(setImagefolder, (state, action) => {
    return {
      ...state,
      folderLocation: action.folderLocation,
    };
  }),
  on(setImageUniqueId, (state, action) => {
    return {
      ...state,
      imageUniqueId: action.imageUniqueId,
    };
  }),
  on(setIsSaving, (state, action) => {
    return {
      ...state,
      isSaving: action.isSaving,
    };
  }),
  on(setIsLoading, (state, action) => {
    return {
      ...state,
      isLoading: action.isLoading,
    };
  })
);

export function AuthReducer(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}