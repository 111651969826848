<div [class]="className + ' flex'">
  <div class="flex">
    <mdb-form-control>
      <input  mdbInput 
              [mdbDatepicker]="datepickerName"
              type="text" 
              class="form-control" 
              id={{fieldName}}
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="inputModel" 
              (ngModelChange)="inputModelChange.emit(inputModel)"
              [readonly]="disabled"/>

      <label  mdbLabel 
              for={{fieldName}}
              class="form-label"> 
              {{label}}
              <span class="noteColor" *ngIf="fromHR">**</span>
      </label>

      <mdb-datepicker-toggle  [mdbDatepicker]="datepickerName"
                              [disabled]="disabled" >
      </mdb-datepicker-toggle>
      <mdb-datepicker [inline]="true" 
                      [format]="'mm/dd/yyyy'" 
                      #datepickerName></mdb-datepicker>
                      
    </mdb-form-control>
  </div>
  <div *ngIf="showClearButton">
    <button type="button" 
            class="btn btn-danger ml-05" 
            (click)="clearDate()"
            [disabled]="disabled">
      <i class="fas fa-times text-white"></i>
    </button>
  </div>
  <div *ngIf="!showClearButton" class="ml-10">
    {{" "}}
  </div>
</div>