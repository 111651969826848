import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { ISupportTeam } from '../models/support-team.model';
import { ISupportTeamMember } from '../models/support-team-member';
import { Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class SupportTeamService extends AppAuthenticatedCrudBaseService<ISupportTeam> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'SupportTeam',store);
  }

  createDto(model: ISupportTeam): ISupportTeam {
    let members = model.supportTeamMembers;    
    if (model.supportTeamMembers) members = model.supportTeamMembers.map(pl => {
      return {
        supportTeamMemberId: pl.supportTeamMemberId,
        employeeId: pl.employeeId,
        supportTeamId: pl.supportTeamId
      } as ISupportTeamMember;
    });
    const result: ISupportTeam =
    {
      supportTeamId: model.supportTeamId,
      name: model.name,
      phone: model.phone,
      email: model.email,
      photoName: model.photoName,
      isActive: model.isActive,
      coordinatorId: model.coordinatorId,
      backupCoordinatorId: model.backupCoordinatorId,
      supportTeamMembers: members
    };
    return result;
  }
}