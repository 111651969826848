<h1>Inactive Employees</h1>
<div class="d-flex align-items-center" *ngIf="isLoading$ | async">
  <strong>Loading...</strong>
  <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
</div>

<div class="col-md-6 align-self-center ml-05">
  <mdb-form-control>
    <input mdbInput type="text" class="form-control" id="search-input" (keyup)="search($event)" />
    <label mdbLabel class="form-label" for="search-input">Search</label>
  </mdb-form-control>
</div>

<div class="datatable mt-4">
  <table  class="table datatable-table"
          mdbTable
          mdbTableSort
          #table="mdbTable"
          #sort="mdbTableSort"
          [dataSource]="employees$ | async"
          [pagination]="pagination"
          [sort]="sort">
    <thead class="datatable-header">
      <tr>
        <th *ngFor="let header of headers" [mdbTableSortHeader]="header" scope="col">
          {{ header | titlecase }}
        </th>
      </tr>
    </thead>
    <tbody class="datatable-body">
      <tr *ngFor="let employee of table.data">
        <td>
         {{employee.firstName}} {{employee.lastName}}
        </td>
        <td>
          {{employee.title}}
        </td>
        <td>
          {{employee.resignationDate | date: 'MM-dd-yyyy'}}      
        </td>
        <td *ngIf="((user$ | async)?.isAdmin)">
          <button type="button" class="btn btn-primary" (click)="handleClick(employee);">Make Active</button>        
        </td>
      </tr>
    </tbody>
  </table>
  <mdb-table-pagination #pagination></mdb-table-pagination>
</div>