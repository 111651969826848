import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { IEmployee } from 'src/app/models/employee.model';
import { IOffice } from 'src/app/models/office.model';
import { accessToken } from 'src/app/store/state/auth.state';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { RootState } from 'src/app/store/root.state';
import { toastErrorAction } from 'src/app/store/actions/toast.actions';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';
import { selectors } from 'src/app/store/selectors/selectors';

@Component({
  selector: 'app-office-new',
  templateUrl: './office-new.component.html',
})
export class OfficeNewComponent implements OnInit {
  newOffice$: Observable<IOffice> = of({ isVirtual: false, isActive: false });
  isSaving$: Observable<boolean> = of(false);
  administrators$: Observable<IEmployee[]> = new Observable<IEmployee[]>();
  administratorsLoading = false;
  administratorInput$ = new Subject<string>();
  model: IOffice = { isVirtual: false, isActive: false }
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;  
    
  constructor(private readonly store: Store<RootState>
                , private readonly title: Title
                , private modalService: MdbModalService
                , private readonly router:Router) {  }

  ngOnInit(): void {
    this.isSaving$ = this.store.select(selectors.office.isSaving);    
    this.newOffice$ = this.store.select(selectors.office.get)
      .pipe(tap((model: IOffice) => {
          this.model = {...model};
      }));
    this.newOffice$.subscribe();
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.loadAdministrators();
      }
    });
    this.title.setTitle(`New Office`);
  }

  private loadAdministrators() {
    this.administrators$ = this.administratorInput$.pipe(
      distinctUntilChanged(),
      switchMap((term: string) => {
        this.store.dispatch(CrudActions.get(Feature.Employees)(undefined, { isActive: true, name: term }));
        return this.store.select(selectors.employees.get);
      })
    );
  }

  trackByFn(item: IEmployee) {
    return item.employeeId;
  }

  save() {
    if(this.model.name && this.model.name.trim() != ''){
      this.model.administratorEmployeeId = this.model.administrator?.employeeId;
      this.store.dispatch(CrudActions.create<IOffice>(Feature.Office)(this.model));      
    }
    else {
      this.store.dispatch(toastErrorAction({ message: 'A name is required!' }));
    }
  }

  cancelUpdate(){
    this.model = { officeId: '', isVirtual: false, isActive: true };
    this.router.navigate(['/']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'Office-New'},
    });    
  }


  

}
