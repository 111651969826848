import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { EmployeeService } from '../../services/employee.service';
import { IEmployee } from '../../models/employee.model';
import { select, Store } from '@ngrx/store';
import { accessToken, azureStorageKey, isLoading, isSaving, user } from 'src/app/store/state/auth.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Profile } from 'src/app/models/user.model';
import { azureStorageURL } from 'src/app/utils/app-config';
import { setImagefolder, setImageUniqueId, setIsLoading, setIsSaving, setPhotoName } from 'src/app/store/actions/auth.actions';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { BaseComponent } from '../base/base.component';
import { RootState } from 'src/app/store/root.state';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent extends BaseComponent implements OnInit {

  notificationSuccessRef!: MdbNotificationRef<ToastSuccessComponent>;
  notificationErrorRef!: MdbNotificationRef<ToastErrorComponent>;
  
  id: string | null = null;
  model: IEmployee = {}
  mode  = 'read';
  user$!: Observable<Profile | null>;
  azureKey$!: Observable<string | null>;
  isSaving$!: Observable<boolean>;
  isLoading$!: Observable<boolean>;
  @ViewChild('alert', { static: true }) alert!: ElementRef;  
  imageURL: string | undefined;
  azureImageURL = azureStorageURL;

  constructor(private readonly employeeService: EmployeeService
              , private readonly activatedRoute: ActivatedRoute
              , private readonly title: Title
              , private store: Store<RootState>
              , private notificationService: MdbNotificationService) {
    super();
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.store.pipe(select(accessToken), takeUntil(this._destroying$))
              .subscribe((accessToken: string | null) => 
              {
                if(accessToken && this.id !== null)
                {
                  this.getEmployee(this.id);       
                  this.user$ = this.store.pipe(select(user));
                  this.isSaving$ = this.store.pipe(select(isSaving));
                  this.isLoading$ = this.store.pipe(select(isLoading));
                }
              }) 
  }

  getEmployee(id: string) {
    this.store.dispatch(setIsLoading({isLoading: true}));
    this.employeeService.get(id).subscribe({
      next: (employee) => {
        this.model = { ...employee };
        this.store.pipe(select(azureStorageKey), takeUntil(this._destroying$)).subscribe((azureKey) => {
          this.imageURL = `${this.azureImageURL}/employeephotos/${employee.photoName || 'null.jpg'}${azureKey}`
        })
        this.store.dispatch(setPhotoName({photoName: employee.photoName || 'null.jpg'}));
        this.store.dispatch(setImagefolder({folderLocation: 'employeephotos'}));
        this.store.dispatch(setImageUniqueId({imageUniqueId: new Date().getTime()}));
        this.setTitle();
        this.store.dispatch(setIsLoading({isLoading: false}));
      },
      error: () => {
        this.store.dispatch(setIsLoading({isLoading: false}));      
      }
    });
  }

  setTitle() {
    this.title.setTitle(`Employee - ${this.model.displayName}`);
  }

  updateEmployee(employee: IEmployee) {  
    this.store.dispatch(setIsSaving({isSaving: true}));
    this.employeeService.update(employee, employee.employeeId, false).subscribe({
      next: (result) => {
        this.model = { ...result };
        this.setTitle();
        this.store.dispatch(setIsSaving({isSaving: false}));
        this.showSuccess();
      },
      error: () => {
        this.showError();
        this.store.dispatch(setIsSaving({isSaving: false}));      
      }
    });
  }

  saveEmployee(employee: IEmployee) {
    this.updateEmployee(employee);
  }

  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
  }

  showSuccess() {
    this.notificationSuccessRef = this.notificationService.open(
      ToastSuccessComponent,
      { data: { text: 'Employee information updated!'},
      position: 'bottom-center',
      autohide: true });
  }

  showError() {
    this.notificationSuccessRef = this.notificationService.open(
      ToastErrorComponent,
      { data: { text: 'Something went wrong. Employee information could not updated!'},
      position: 'bottom-center',
      autohide: true });
  }

  saveFilename(filename?: string) {
    this.model.photoName = filename;
    this.employeeService.update(this.model, this.model.employeeId, false).subscribe({
      error: () => {
        this.showError();
      }
    });
  }
}