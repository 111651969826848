import { Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { Observable, of, tap } from 'rxjs';
import { IEmployeeLanguage } from 'src/app/models/employee-language.model';
import { ILanguage } from 'src/app/models/language.model';
import { IProficiency } from 'src/app/models/proficiency.model';
import { Profile } from 'src/app/models/user.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { DeleteConfirmComponent } from '../helpers/delete-confirm/delete-confirm.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { RootState } from 'src/app/store/root.state';
import { getAllProficiency } from 'src/app/store/actions/proficiency.actions';
import { createEmployeeLanguage, deleteEmployeeLanguage, getAllEmployeeLanguage, setAllEmployeeLanguage, setEmployeeLanguageCanUpdate, updateAllEmployeeLanguage } from 'src/app/store/actions/employee-language.actions';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';

@Component({
  selector: 'app-employee-language',
  templateUrl: './employee-language.component.html',
  styleUrls: ['./employee-language.component.scss']
})
export class EmployeeLanguageComponent implements OnInit, OnChanges {
  
  @Input()  
  employeeId: string | null | undefined;

  user$!: Observable<Profile | null>;
  canUpdate$: Observable<boolean> = of(false);  
  proficiencies$: Observable<IProficiency[]> = of([]);
  languages$: Observable<ILanguage[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  isLanguageLoading$: Observable<boolean> = of(false);
  isProficiencyLoading$: Observable<boolean> = of(false);
  employeeLanguages$: Observable<IEmployeeLanguage[]> = of([]);
  employeeLanguages: IEmployeeLanguage[] = [];
  employeeLanguagesCopy: IEmployeeLanguage[] = [];  
  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;
  languageDeleteId: number | undefined;
  newLanguage: IEmployeeLanguage = {};
  deleteconfirmRef: MdbPopconfirmRef<DeleteConfirmComponent> | null = null;
  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
  templateModalRef!: MdbModalRef<Element>;

  @ViewChild('template') modalTemplate!: TemplateRef<Element>;
  
  constructor(private store: Store<RootState>
              , private popconfirmService: MdbPopconfirmService
              , private readonly modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.canUpdate$ = this.store.select(selectors.employeeLanguage.canUpdate);
    this.isLoading$ = this.store.select(selectors.employeeLanguage.isLoading);
    this.isLanguageLoading$ = this.store.select(selectors.language.isLoading);
    this.languages$ = this.store.select(selectors.languages.get);
    this.isProficiencyLoading$ = this.store.select(selectors.proficiency.isLoading);
    this.proficiencies$ = this.store.select(selectors.proficiency.getAll);
    this.user$ = this.store.pipe(select(user));
    this.store.dispatch(CrudActions.get(Feature.Languages)());
    this.store.dispatch(getAllProficiency({ queryParams: {} }));
    this.employeeLanguages$ = this.store.select(selectors.employeeLanguage.getAll)
      .pipe(tap((models: IEmployeeLanguage[]) => {
        this.employeeLanguages = models.map((language: IEmployeeLanguage) => ({ ...language }));
        //this.employeeLanguages = [...models]
        this.employeeLanguagesCopy = { ...this.employeeLanguages };
      }));
    this.employeeLanguages$.subscribe();
  }   

  ngOnChanges() {
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken && this.employeeId) {
        this.getEmployeeLanguages(this.employeeId);
      }
    });
  }

  getEmployeeLanguages(id: string) {
    this.store.dispatch(getAllEmployeeLanguage({ queryParams: { employeeId: id } }));
  }

  onSave() {
    this.store.dispatch(updateAllEmployeeLanguage({ models: this.employeeLanguages }));
  }

  onEdit() {
    this.store.dispatch(setEmployeeLanguageCanUpdate({canUpdate: true}));
  }

  onCancelCheck(){    
    return (JSON.stringify(this.employeeLanguages) === JSON.stringify(this.employeeLanguagesCopy));
  }

  onCancel() {    
    this.store.dispatch(setAllEmployeeLanguage({ models: this.employeeLanguagesCopy }));
  }

  setDeleteId(id: number | undefined){
    this.languageDeleteId = id;
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }
 
  deletePopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.deleteconfirmRef = this.popconfirmService.open(DeleteConfirmComponent, target, { popconfirmMode: 'modal' });
    this.deleteconfirmRef.onConfirm.subscribe(() => {
      this.remove();
    });
  }

  remove() {
    if(this.languageDeleteId){
      this.store.dispatch(deleteEmployeeLanguage({ id: this.languageDeleteId }));
    }
  }

  add() {
    if(this.employeeId){
      this.newLanguage.employeeId = parseInt(this.employeeId);
      this.store.dispatch(createEmployeeLanguage({ model: this.newLanguage, includeChildren: false }));
      this.newLanguage = {};
    }
    this.templateModalRef.close();
  }

  openAddNewModal() {
    this.templateModalRef = this.modalService.open(this.modalTemplate);
  }

}