import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppAuthenticatedBaseService } from './app-authenticated-base.service';
import { RootState } from '../store/root.state';
import { IFeature } from '../models/features.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CrudService<T> extends AppAuthenticatedBaseService {
  constructor(protected readonly http: HttpClient,
              store: Store<RootState>) {
    super(store);
  }

  private getUrl(feature: IFeature, id?: number | string | null, queryParams?: Record<string, unknown>): string {
    let url = `${this.serviceUrl}/${feature.ControllerPath}`;
    if (id !== undefined && id !== null) {
      url += `/${encodeURIComponent(id)}`;
    }
    url += this.createQueryString(queryParams);
    return url;
  }

  create(feature: IFeature, model: T): Observable<T> {
    const url = this.getUrl(feature);
    return this.http.post<T>(url, model, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  get(feature: IFeature, id?: number, queryParams?: Record<string, unknown>): Observable<T> {
    const url = this.getUrl(feature, id, queryParams);
    return this.http.get<T>(url, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  update(feature: IFeature, model: T, id?: number, queryParams?: Record<string, unknown>): Observable<T> {
    const url = this.getUrl(feature, id, queryParams);
    return this.http.put<T>(url, model, this.getHttpOptions()).pipe(catchError(this.handleError));
  }
   
  delete(feature: IFeature, id: number): Observable<T> {
    const url = this.getUrl(feature, id);
    return this.http.delete<T>(url, this.getHttpOptions()).pipe(catchError(this.handleError));
  }
  
}