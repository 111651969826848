<div id="table" class="table-editable">
  <div class="d-flex flex-row-reverse mb-4">    
    <button type="button" class="btn btn-primary btn-sm ml-05" 
            (click)="openAddNewModal()" 
            *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))">
      <i class="fas fa-plus"></i>
      Add New Bar Admission
    </button>
    <button type="button" 
            class="btn btn-primary btn-sm"            
            *ngIf="(canUpdate$ | async) === false && employeeBarAdmissions.length > 0 && (((user$ | async)?.isAdmin) || ((user$ | async)?.isHR)) "
            (click)="onEdit()">
      <i class="fas fa-edit"></i> Edit
    </button>
    <button type="button" 
            class="btn btn-primary btn-sm ml-05"
            *ngIf="canUpdate$ | async"
            (click)="!onCancelCheck() ? openPopconfirm($event) : onCancel()">
      <i class="fas fa-undo"></i> Cancel
    </button>
    <button type="button" 
            class="btn btn-primary btn-sm"
            *ngIf="canUpdate$ | async" 
            (click)="onSave()">
      <i class="fas fa-save"></i> Save
    </button>
  </div>
  <div class="d-flex align-items-center" *ngIf="isLoading$ | async">
    <strong>Loading...</strong>    
    <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
  </div>
  <div *ngIf="employeeBarAdmissions.length === 0 && (isLoading$ | async) === false">
    <h6>No bar admissions found.</h6>
    <h6 *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))"> Click on "Add New Bar Admission" to add a new bar admission. </h6>
  </div>
  <div class="col-md-9 mt-1 ml-1">
    <table class="table table-bordered table-responsive-md table-striped" *ngIf="employeeBarAdmissions.length > 0 && (isLoading$ | async) === false">
      <tr>
        <th class="text-center">Bar</th>
        <th class="text-center">Sort Order</th>
        <th class="text-center">Year</th>
        <th class="text-center">Bar Number</th>
        <th *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))"></th>
      </tr>
      <tr *ngFor="let admission of employeeBarAdmissions; let id=index">
        <td class="text-center bar-width">
          <ng-select  [(ngModel)]="admission.barAdmissionId"
                      [readonly]="(canUpdate$ | async) === false"
                      name="bar"
                      [loading]="(isBarAdmissionsLoading$ | async) ?? false"
                      bindlabel="description">
            <ng-option *ngFor="let temp of (admissions$ | async)" [value]="temp.id">{{temp.description}}</ng-option>
          </ng-select>
        </td>
        <td class="text-center sort-order-width">
          <input  type="number" 
                  id="sortOrder" 
                  name="sortOrder" 
                  class="form-control bar-input"
                  [readonly]="(canUpdate$ | async) === false"
                  mdbInput 
                  [(ngModel)]="admission.sortOrder" />
        </td>
        <td class="text-center">
          <input  type="text" 
                  id="year" 
                  name="year" 
                  class="form-control bar-input"
                  [readonly]="(canUpdate$ | async) === false"
                  mdbInput                 
                  [(ngModel)]="admission.year"/>
        </td>
        <td class="text-center bar-number-width" >
          <input  type="text" 
                  id="barNumber" 
                  name="barNumber" 
                  class="form-control bar-input"
                  [readonly]="(canUpdate$ | async) === false"
                  mdbInput 
                  [(ngModel)]="admission.barNumber" />
        </td>
        <td class="text-center delete-width" *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))">
          <span>
            <a  href="#!" role="button" class="icon-selectable"
                *ngIf="canUpdate$ | async" 
                (click)="setDeleteId(admission.id);deletePopconfirm($event)">
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
            <a  class="icon-disabled" *ngIf="(canUpdate$ | async) === false" class="disabled">
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
          </span>
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add new bar admission</h5>
    <button type="button" class="btn-close-custom" aria-label="Close" (click)="templateModalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <ng-select  appearance="outline"
                    [(ngModel)]="newAdmission.barAdmissionId"
                    placeholder="Search to add bar admission"
                    name="bar"
                    [loading]="(isBarAdmissionsLoading$ |async) ?? false"
                    bindlabel="description">
          <ng-option *ngFor="let temp of (admissions$ | async)" [value]="temp.id">{{temp.description}}</ng-option>
        </ng-select>
      </div>
      <div class="col-md-12">
        <mdb-form-control>
          <input mdbInput type="number" id="sortOrder" name="sortOrder" class="form-control" 
                [(ngModel)]="newAdmission.sortOrder" />
          <label mdbLabel class="form-label" for="sortOrder">Sort Order</label>
        </mdb-form-control>       
      </div>
      <div class="col-md-12 mt-3">
        <mdb-form-control>
          <input mdbInput type="text" id="year" name="year" class="form-control" 
                [(ngModel)]="newAdmission.year" />
          <label mdbLabel class="form-label" for="year">Year</label>
        </mdb-form-control>      
      </div>
      <div class="col-md-12 mt-3">
        <mdb-form-control>
          <input mdbInput type="text" id="barNumber" name="barNumber" class="form-control" 
                [(ngModel)]="newAdmission.barNumber" />
          <label mdbLabel class="form-label" for="barNumber">Bar Number</label>
        </mdb-form-control>      
      </div>
    </div> 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="templateModalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="add()">Add</button>
  </div>
</ng-template>