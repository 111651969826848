import { ActionReducerMap } from "@ngrx/store";
import { AuthReducer } from "./reducers/auth.reducer";
import { countyReducer } from "./reducers/county.reducer";
import { employeeBarAdmissionReducer } from "./reducers/employee-bar-admission.reducer";
import { employeeContactReducer } from "./reducers/employee-contact.reducer";
import { employeeEducationReducer } from "./reducers/employee-education.reducer";
import { employeeLanguageReducer } from "./reducers/employee-language.reducer";
import { employeeNotaryReducer } from "./reducers/employee-notary.reducer";
import { employeeOrgReducer } from "./reducers/employee-org.reducer";
import { employeeSupportTeamReducer } from "./reducers/employee-support-team.reducer";
import { employeeTypeReducer } from "./reducers/employee-type.reducer";
import { proficiencyReducer } from "./reducers/proficiency.reducer";
import { generateCrudReducer } from "./reducers/crud.reducer";
import { IBarAdmission } from "../models/bar-admission.model";
import { CrudState, initialCrudState } from "./state/crud.state";
import { Feature } from "../models/features.model";
import { IChangeRequest } from "../models/change-request.model";
import { ICommittee } from "../models/committee.model";
import { IDepartment } from "../models/department.model";
import { IEmployee } from "../models/employee.model";
import { ILanguage } from "../models/language.model";
import { IOffice } from "../models/office.model";
import { ISupportTeam } from "../models/support-team.model";
import { IPsc } from "../models/psc.model";
import { ISbu } from "../models/sbu.model";
import { IEmployeeKeyDates } from "../models/employee-key-dates.model";

export interface RootState {
  auth: ReturnType<typeof AuthReducer>;
  barAdmission: CrudState<IBarAdmission>;
  barAdmissions: CrudState<IBarAdmission[]>;
  changeRequest: CrudState<IChangeRequest>;
  committee: CrudState<ICommittee>;
  committees: CrudState<ICommittee[]>;
  county: ReturnType<typeof countyReducer>;
  department: CrudState<IDepartment>;
  departments: CrudState<IDepartment[]>;
  employee: CrudState<IEmployee>;
  employees: CrudState<IEmployee[]>;
  employeeBarAdmission: ReturnType<typeof employeeBarAdmissionReducer>;
  employeeContact: ReturnType<typeof employeeContactReducer>;
  employeeEducation: ReturnType<typeof employeeEducationReducer>;
  employeeKeyDates: CrudState<IEmployeeKeyDates>;
  employeeLanguage: ReturnType<typeof employeeLanguageReducer>;
  employeeNotary: ReturnType<typeof employeeNotaryReducer>;
  employeeOrg: ReturnType<typeof employeeOrgReducer>;
  employeeType: ReturnType<typeof employeeTypeReducer>;
  employeeSupportTeam: ReturnType<typeof employeeSupportTeamReducer>;
  language: CrudState<ILanguage>;
  languages: CrudState<ILanguage[]>;
  office: CrudState<IOffice>;
  offices: CrudState<IOffice[]>;
  proficiency: ReturnType<typeof proficiencyReducer>;
  psc: CrudState<IPsc>;
  pscs: CrudState<IPsc[]>;
  sbu: CrudState<ISbu>;
  sbus: CrudState<ISbu[]>;
  supportTeam: CrudState<ISupportTeam>;
  supportTeams: CrudState<ISupportTeam[]>;
}

export const reducers: ActionReducerMap<RootState> = {
  auth: AuthReducer,
  barAdmission: generateCrudReducer<IBarAdmission>(initialCrudState<IBarAdmission>(), Feature.BarAdmission),
  barAdmissions: generateCrudReducer<IBarAdmission[]>(initialCrudState<IBarAdmission[]>(), Feature.BarAdmissions),
  changeRequest: generateCrudReducer<IChangeRequest>(initialCrudState<IChangeRequest>(), Feature.ChangeRequest),
  committee: generateCrudReducer<ICommittee>(initialCrudState<ICommittee>(), Feature.Committee),
  committees: generateCrudReducer<ICommittee[]>(initialCrudState<ICommittee[]>(), Feature.Committees),
  county: countyReducer,
  department: generateCrudReducer<IDepartment>(initialCrudState<IDepartment>(), Feature.Department),
  departments: generateCrudReducer<IDepartment[]>(initialCrudState<IDepartment[]>(), Feature.Departments),
  employee: generateCrudReducer<IEmployee>(initialCrudState<IEmployee>(), Feature.Employee),  
  employees: generateCrudReducer<IEmployee[]>(initialCrudState<IEmployee[]>(), Feature.Employees),
  employeeBarAdmission: employeeBarAdmissionReducer,
  employeeContact: employeeContactReducer,
  employeeEducation: employeeEducationReducer,
  employeeKeyDates: generateCrudReducer<IEmployeeKeyDates>(initialCrudState<IEmployeeKeyDates>(), Feature.EmployeeKeyDates),
  employeeLanguage: employeeLanguageReducer,
  employeeNotary: employeeNotaryReducer,
  employeeOrg: employeeOrgReducer,
  employeeType: employeeTypeReducer,
  employeeSupportTeam: employeeSupportTeamReducer,
  language: generateCrudReducer<ILanguage>(initialCrudState<ILanguage>(), Feature.Language),  
  languages: generateCrudReducer<ILanguage[]>(initialCrudState<ILanguage[]>(), Feature.Languages),
  office: generateCrudReducer<IOffice>(initialCrudState<IOffice>(), Feature.Office),
  offices: generateCrudReducer<IOffice[]>(initialCrudState<IOffice[]>(), Feature.Offices),
  proficiency: proficiencyReducer,
  psc: generateCrudReducer<IPsc>(initialCrudState<IPsc>(), Feature.Psc),
  pscs: generateCrudReducer<IPsc[]>(initialCrudState<IPsc[]>(), Feature.Pscs),
  sbu: generateCrudReducer<ISbu>(initialCrudState<ISbu>(), Feature.Sbu),
  sbus: generateCrudReducer<ISbu[]>(initialCrudState<ISbu[]>(), Feature.Sbus),
  supportTeam: generateCrudReducer<ISupportTeam>(initialCrudState<ISupportTeam>(), Feature.SupportTeam),  
  supportTeams: generateCrudReducer<ISupportTeam[]>(initialCrudState<ISupportTeam[]>(), Feature.SupportTeams),  
};