import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { EmployeeLanguageState } from '../state/employee-language.state';

const state = (state: RootState) => state.employeeLanguage;

const isLoading = createSelector(
  state,
  (state: EmployeeLanguageState) => state.isLoading
);

const canUpdate = createSelector(
  state,
  (state: EmployeeLanguageState) => state.canUpdate
);

const getAll = createSelector(
  state,
  (state: EmployeeLanguageState) => state.employeeLanguages
);

export const employeeLanguageSelectors = {
  isLoading,
  canUpdate,
  getAll
};