import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { IEmployee } from '../models/employee.model';
import { Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends AppAuthenticatedCrudBaseService<IEmployee> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'Employee', store);
  }
  
  createDto(model: IEmployee): IEmployee { return model; }

}