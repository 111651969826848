import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICounty } from 'src/app/models/county.model';

export const getAllCounty = createAction(
  '[County] Get All',
  props<{ queryParams: Record<string, unknown> }>()
);

export const getAllCountySuccess = createAction(
  '[County] Get All Success',
  props<{ models: ICounty[] }>()
);

export const getAllCountyFailure = createAction(
  '[County] Get All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export type GetAllCountyAction = ReturnType<typeof getAllCounty>;
export type GetAllCountySuccessAction = ReturnType<typeof getAllCountySuccess>;
export type GetAllCountyFailureAction = ReturnType<typeof getAllCountyFailure>;