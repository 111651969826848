import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { IDepartment } from 'src/app/models/department.model';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { Observable, of, tap } from 'rxjs';
import { RootState } from 'src/app/store/root.state';
import { Store } from '@ngrx/store';
import { Feature } from 'src/app/models/features.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { toastErrorAction } from 'src/app/store/actions/toast.actions';
import { selectors } from 'src/app/store/selectors/selectors';

@Component({
  selector: 'app-department-new',
  templateUrl: './department-new.component.html',
  styleUrls: ['./department-new.component.scss']
})
export class DepartmentNewComponent implements OnInit {

  newDepartment$: Observable<IDepartment> = of({});
  isSaving$: Observable<boolean> = of(false);
  model: IDepartment = {};
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;
     
  constructor(private readonly store: Store<RootState>
              , private readonly title: Title
              , private modalService: MdbModalService
              , private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.isSaving$ = this.store.select(selectors.department.isSaving);
    this.newDepartment$ = this.store.select(selectors.department.get)
      .pipe(tap((model: IDepartment) => {
          this.model = {...model};
      }));
      this.newDepartment$.subscribe();
    this.title.setTitle(`New Department`);
  }

  save() {
    const action = this.model.name?.trim() ?
      CrudActions.create<IDepartment>(Feature.Department)(this.model) : 
      toastErrorAction({ message: 'A department name is required!' })
    this.store.dispatch(action);
  } 

  onCancelCheck(){
    return this.model.name;
  }

  cancelUpdate(){
    this.router.navigate(['/']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'Department-New'},
    });    
  }

}
