import { createReducer, on } from "@ngrx/store";
import * as EmployeeOrgActions from "../actions/employee-org.actions";
import { EmployeeOrgState, initialEmployeeOrgState } from "../state/employee-org.state";


export const employeeOrgReducer = createReducer(
  initialEmployeeOrgState,
  on(EmployeeOrgActions.getEmployeeOrg, (state: EmployeeOrgState) => {
    return { ...state, employeeOrg: {}, isLoading: true, isSaving: false, canUpdate: false, error: null };
  }),
  on(EmployeeOrgActions.getEmployeeOrgSuccess, (state: EmployeeOrgState, { model }) => {
    return { ...state, employeeOrg: model, isLoading: false, isSaving: false, error: null };
  }),
  on(EmployeeOrgActions.getEmployeeOrgFailure, (state: EmployeeOrgState, { error }) => {
    return { ...state, employeeOrg: {}, isLoading: false, isSaving: false, error };
  }),
  on(EmployeeOrgActions.updateEmployeeOrg, (state: EmployeeOrgState, { model }) => {
    return { ...state, employeeOrg: model, isLoading: true, isSaving: true, error: null};
  }), 
  on(EmployeeOrgActions.updateEmployeeOrgSuccess, (state: EmployeeOrgState, { model }) => {
    return { ...state, employeeOrg: model, isLoading: false, isSaving: false, canUpdate: false,  error: null };
  }),
  on(EmployeeOrgActions.updateEmployeeOrgFailure, (state: EmployeeOrgState, { error }) => {
    return { ...state, employeeOrg: {}, isLoading: false, isSaving: false, canUpdate: false, error };
  }),
  on(EmployeeOrgActions.setEmployeeOrgCanUpdate, (state: EmployeeOrgState, { canUpdate }) => {
    return { ...state, canUpdate: canUpdate };
  }),
);