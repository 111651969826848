<h1>Employees</h1>
<div class="col-md-6">
  <div class="d-flex flex-row">
    <div class="flex-grow-1">
      <ng-select  #employeeSearchSelect
                  [isOpen]="false"
                  bindLabel="displayName"
                  mdbInput
                  [minTermLength]="2"
                  placeholder = "Type to search.."
                  [typeahead]="filterEmployeeInput$"
                  labelForId="employeeSearch"
                  name="employeeSearch" >
      </ng-select>
    </div>
    <div class="p-2"> 
      <button type="button" class="btn btn-primary btn-sm" *ngIf="(filterEmployeeInput$ | async)" (click)="onClear()">
        <i class="fas fa-times"></i> Clear
      </button>
    </div>
  </div>
</div>
<div *ngFor="let employee of (filteredEmloyees$ | async)">
  <a  class="list-group-item" 
      routerLink="/employees/{{employee.employeeId}}" >
    <div class="flex-none">
      <div class="item-image" style="background-image: url({{employee.imageUrl}});"></div>
    </div>
    <div class="flex-2">
      <h5>{{employee.firstName}} {{employee.lastName}}</h5>
      <div class="text-muted mb-0">{{employee.title}}</div>
    </div>
    <div class="flex-2">
      <p class="fw-normal mb-1">SBU : {{employee.sbuName }}</p>
      <p class="fw-normal mb-1">Office: {{employee.officeName }} </p>
    </div>
    <div class="flex-2">      
      <p class="fw-normal mb-1"><i class="fas fa-phone"></i> {{normalizePhoneFormat(employee.workPhone)}} {{employee.directDial ? "(x" + employee.directDial + ")" : ""}}</p>
      <p class="fw-normal mb-1"><i class="fas fa-mobile-alt"></i> {{normalizePhoneFormat(employee.mobilePhone)}}</p>
      <p class="fw-normal mb-1"><i class="far fa-envelope"></i> {{employee.email}}</p>
    </div>
  </a>
</div>
<div *ngIf="(isLoadingFiltered$ | async)" class="spinner-item">
  <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
</div>