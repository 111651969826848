import { Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { Observable, of } from 'rxjs';
import { IBarAdmission } from 'src/app/models/bar-admission.model';
import { IEmployeeBarAdmission } from 'src/app/models/employee-bar-admission.model';
import { Profile } from 'src/app/models/user.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { DeleteConfirmComponent } from '../helpers/delete-confirm/delete-confirm.component';
import { ToastErrorComponent } from '../helpers/toast-error/toast-error.component';
import { ToastSuccessComponent } from '../helpers/toast-success/toast-success.component';
import { RootState } from 'src/app/store/root.state';
import { createEmployeeBarAdmission, deleteEmployeeBarAdmission, getAllEmployeeBarAdmission, setAllEmployeeBarAdmission,  setEmployeeBarAdmissionCanUpdate, updateAllEmployeeBarAdmission } from 'src/app/store/actions/employee-bar-admission.actions';
import { selectors } from 'src/app/store/selectors/selectors';
import { Feature } from 'src/app/models/features.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';

@Component({
  selector: 'app-employee-bar',
  templateUrl: './employee-bar.component.html',
  styleUrls: ['./employee-bar.component.scss']
})
export class EmployeeBarComponent implements OnInit, OnChanges {

  @ViewChild('template') modalTemplate!: TemplateRef<Element>;
  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;
  deleteconfirmRef: MdbPopconfirmRef<DeleteConfirmComponent> | null = null;
  notificationSuccessRef: MdbNotificationRef<ToastSuccessComponent> | null = null;
  notificationErrorRef: MdbNotificationRef<ToastErrorComponent> | null = null;
  templateModalRef!: MdbModalRef<Element>;

  @Input()
  employeeId: string | null | undefined;

  user$!: Observable<Profile | null>;
  canUpdate$: Observable<boolean> = of(false);  
  employeeBarAdmissions$: Observable<IEmployeeBarAdmission[]> = of([]);
  admissions$: Observable<IBarAdmission[]> = of([]);
  isLoading$: Observable<boolean> = of(false); 
  isBarAdmissionsLoading$: Observable<boolean> = of(false);  
  employeeBarAdmissions: IEmployeeBarAdmission[] = [];
  employeeBarAdmissionsCopy: IEmployeeBarAdmission[] = [];
  newAdmission: IEmployeeBarAdmission = {};
  admisssionDeleteId: number | undefined;

  constructor(private readonly store: Store<RootState>
              , private readonly modalService: MdbModalService
              , private popconfirmService: MdbPopconfirmService
              ) 
  {}
  
  ngOnInit() {
    this.isLoading$ = this.store.select(selectors.employeeBarAdmission.isLoading);    
    this.employeeBarAdmissions$ = this.store.select(selectors.employeeBarAdmission.getAll)
    this.canUpdate$ = this.store.select(selectors.employeeBarAdmission.canUpdate);
    this.isBarAdmissionsLoading$ = this.store.select(selectors.barAdmission.isLoading);    
    this.admissions$ = this.store.select(selectors.barAdmissions.get);
    this.user$ = this.store.pipe(select(user));
    this.store.dispatch(CrudActions.get(Feature.BarAdmissions)());    
    this.store.select(selectors.employeeBarAdmission.getAll).subscribe((employeeBarAdmissions: IEmployeeBarAdmission[]) => {
      if(!employeeBarAdmissions) return;
      this.employeeBarAdmissions = employeeBarAdmissions.map((admission: IEmployeeBarAdmission) => ({ ...admission }));
      this.employeeBarAdmissionsCopy = [...employeeBarAdmissions];
    });
  }

  ngOnChanges() {
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken && this.employeeId) {
        this.getEmployeeBarAdmissions(this.employeeId);
      }
    });
  }

  getEmployeeBarAdmissions(id: string) {
    this.store.dispatch(getAllEmployeeBarAdmission({ queryParams: { employeeId: id } }));
  }

  remove() {    
    if(this.admisssionDeleteId){
      this.store.dispatch(deleteEmployeeBarAdmission({ id: this.admisssionDeleteId }));
    }
  }

  add() {
    if(this.employeeId){
      this.newAdmission.employeeId = parseInt(this.employeeId);
      this.store.dispatch(createEmployeeBarAdmission({ model: this.newAdmission, includeChildren: false }));
      this.newAdmission = {};
    }    
    this.templateModalRef.close();
  }

  onSave() {
    this.store.dispatch(updateAllEmployeeBarAdmission({ models: this.employeeBarAdmissions })); 
  }
  
  onEdit() {
    this.store.dispatch(setEmployeeBarAdmissionCanUpdate({canUpdate: true}));
  }
  
  onCancelCheck(){
    return JSON.stringify(this.employeeBarAdmissions) === JSON.stringify(this.employeeBarAdmissionsCopy);
  }

  onCancel() {
    this.store.dispatch(setAllEmployeeBarAdmission({ models: this.employeeBarAdmissionsCopy }));
  }

  setDeleteId(id: number | undefined){
    this.admisssionDeleteId = id;
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }

  deletePopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.deleteconfirmRef = this.popconfirmService.open(DeleteConfirmComponent, target, { popconfirmMode: 'modal' });
    this.deleteconfirmRef.onConfirm.subscribe(() => {
      this.remove();
    });
  }

  openAddNewModal() {
    this.templateModalRef = this.modalService.open(this.modalTemplate);
  }

}