import { Component } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'app-toast-success',
  templateUrl: './toast-success.component.html',
  styleUrls: ['./toast-success.component.scss']
})
export class ToastSuccessComponent {

  text: string | null = null; 
  
  constructor(public notificationRef: MdbNotificationRef<ToastSuccessComponent>) {}

}