import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPhoto } from '../models/photo.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppAuthenticatedBaseService } from "./app-authenticated-base.service";
import { Store } from '@ngrx/store';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class PhotoService extends AppAuthenticatedBaseService {
  controllerPath = "Photo";
  constructor(protected readonly http: HttpClient, store: Store<RootState>) {
    super(store);
  }

  create(model: IPhoto, includeChildren = false): Observable<IPhoto> {
    const parameter = includeChildren ? model : this.createDto(model);
    const url = `${this.serviceUrl}/${this.controllerPath}`;
    return this.http.post<IPhoto>(url, parameter, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  get(location: string, filename: string, queryParams?: Record<string, unknown>): Observable<IPhoto> {
    const url = `${this.serviceUrl}/${this.controllerPath}/${encodeURIComponent(location)}/${encodeURIComponent(filename)}${this.createQueryString(queryParams)}`;
    return this.http.get<IPhoto>(url, this.getHttpOptions()).pipe(catchError(this.handleError));
  }

  protected createDto(model: IPhoto): IPhoto {
    return model;
  }
}
