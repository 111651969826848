import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { EmployeeTypeState } from '../state/employee-type.state';

const state = (state: RootState) => state.employeeType;

const isLoading = createSelector(
  state,
  (state: EmployeeTypeState) => state.isLoading
);

const isSaving = createSelector(
  state,
  (state: EmployeeTypeState) => state.isSaving
);

const getAll = createSelector(
  state,
  (state: EmployeeTypeState) => state.employeeTypes
);

export const employeeTypeSelectors = {
  isLoading,
  isSaving,
  getAll 
};