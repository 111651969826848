import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IEmployeeNotary } from 'src/app/models/employee-notary.model';

export const createEmployeeNotary = createAction(
  '[EmployeeNotary] Create',
  props<{ model: IEmployeeNotary, includeChildren: boolean }>()
);

export const createEmployeeNotarySuccess = createAction(
  '[EmployeeNotary] Create Success',
  props<{ model: IEmployeeNotary }>()
);

export const createEmployeeNotaryFailure = createAction(
  '[EmployeeNotary] Create Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const getAllEmployeeNotary = createAction(
  '[EmployeeNotary] Get All',
  props<{ queryParams: Record<string, unknown> }>()
);

export const getAllEmployeeNotarySuccess = createAction(
  '[EmployeeNotary] Get All Success',
  props<{ models: IEmployeeNotary[] }>()
);

export const getAllEmployeeNotaryFailure = createAction(
  '[EmployeeNotary] Get All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const updateAllEmployeeNotary = createAction(
  '[EmployeeNotary] Update All',
  props<{ models: IEmployeeNotary[] }>()
);

export const updateAllEmployeeNotarySuccess = createAction(
  '[EmployeeNotary] Update All Success',
  props<{ models: IEmployeeNotary[] }>()
);

export const updateAllEmployeeNotaryFailure = createAction(
  '[EmployeeNotary] Update All Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const deleteEmployeeNotary = createAction(
  '[EmployeeNotary] Delete',
  props<{ id: string | number }>()
);

export const deleteEmployeeNotarySuccess = createAction(
  '[EmployeeNotary] Delete Success'
);

export const deleteEmployeeNotaryFailure = createAction(
  '[EmployeeNotary] Delete Failure',
  props<{ error: Error | HttpErrorResponse }>()
);

export const setEmployeeNotaryCanUpdate = createAction(
  '[EmployeeNotary] Set Can Update',
  props<{ canUpdate: boolean }>()
);

export const setAllEmployeeNotary = createAction(
  '[EmployeeNotary] Set All',
  props<{ models: IEmployeeNotary[] }>()
);

export type CreateEmployeeNotaryAction = ReturnType<typeof createEmployeeNotary>;
export type CreateEmployeeNotarySuccessAction = ReturnType<typeof createEmployeeNotarySuccess>;
export type CreateEmployeeNotaryFailureAction = ReturnType<typeof createEmployeeNotaryFailure>;

export type GetAllEmployeeNotaryAction = ReturnType<typeof getAllEmployeeNotary>;
export type GetAllEmployeeNotarySuccessAction = ReturnType<typeof getAllEmployeeNotarySuccess>;
export type GetAllEmployeeNotaryFailureAction = ReturnType<typeof getAllEmployeeNotaryFailure>;

export type UpdateAllEmployeeNotaryAction = ReturnType<typeof updateAllEmployeeNotary>;
export type UpdateAllEmployeeNotarySuccessAction = ReturnType<typeof updateAllEmployeeNotarySuccess>;
export type UpdateAllEmployeeNotaryFailureAction = ReturnType<typeof updateAllEmployeeNotaryFailure>;

export type DeleteEmployeeNotaryAction = ReturnType<typeof deleteEmployeeNotary>;
export type DeleteEmployeeNotarySuccessAction = ReturnType<typeof deleteEmployeeNotarySuccess>;
export type DeleteEmployeeNotaryFailureAction = ReturnType<typeof deleteEmployeeNotaryFailure>;

export type SetEmployeeNotaryCanUpdateAction = ReturnType<typeof setEmployeeNotaryCanUpdate>;
export type SetAllEmployeeNotaryAction = ReturnType<typeof setAllEmployeeNotary>;
