import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {

  @Input() datepickerName!: string;
  @Input() className!: string;
  @Input() fieldName!: string;
  @Input() label!: string;
  @Input() inputModel: Date | null | undefined;  
  @Input() disabled!: boolean;
  @Input() fromHR!:boolean;
  @Input() showClearButton!:boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() inputModelChange = new EventEmitter<any>();
  
  clearDate(){
    this.inputModel = null;
    this.inputModelChange.emit(this.inputModel);
  }

}