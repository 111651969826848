import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeBarAdmissionActions from '../actions/employee-bar-admission.actions';
import { EmployeeBarAdmissionsService } from 'src/app/services/employee-bar-admissions.service';
import { IEmployeeBarAdmission } from 'src/app/models/employee-bar-admission.model';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';

@Injectable()
export class EmployeeBarAdmissionEffects {
  
  private actions$ = inject(Actions);

  constructor(
    private employeeBarAdmissionService: EmployeeBarAdmissionsService
  ) {}

  createEmployeeBarAdmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeBarAdmissionActions.createEmployeeBarAdmission),
      mergeMap((action: EmployeeBarAdmissionActions.CreateEmployeeBarAdmissionAction) => {
        return this.employeeBarAdmissionService.create(action.model, action.includeChildren).pipe(
          concatMap(model => {            
            return [
              EmployeeBarAdmissionActions.createEmployeeBarAdmissionSuccess({ model }),
              EmployeeBarAdmissionActions.getAllEmployeeBarAdmission({ queryParams: { employeeId: model.employeeId } }),
              toastSuccessAction({ message: 'Bar admission added successfully' }) 
            ];
          }),
          catchError(error => {
            return of(
              EmployeeBarAdmissionActions.createEmployeeBarAdmissionFailure({ error }),
              toastErrorAction({ message: 'Failed to add bar admission' })
            );
          })
        );
      })
    )
  );

  deleteEmployeeBarAdmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeBarAdmissionActions.deleteEmployeeBarAdmission),
      mergeMap(action => {
        return this.employeeBarAdmissionService.delete(action.id).pipe(
          concatMap((model: IEmployeeBarAdmission) => {
            return [
              EmployeeBarAdmissionActions.getAllEmployeeBarAdmission({ queryParams: { employeeId: model.employeeId } }),
              EmployeeBarAdmissionActions.deleteEmployeeBarAdmissionSuccess(),
              toastSuccessAction({ message: 'Employee bar admission deleted successfully' })           
            ];
          }),
          catchError(error => {
            return of(
              EmployeeBarAdmissionActions.deleteEmployeeBarAdmissionFailure({ error }),
              toastErrorAction({ message: 'Failed to delete bar admission' })
            );
          })
        );
      })
    )
  );

  getAllEmployeeBarAdmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeBarAdmissionActions.getAllEmployeeBarAdmission),
      mergeMap((action: EmployeeBarAdmissionActions.GetAllEmployeeBarAdmissionAction) => {
        return this.employeeBarAdmissionService.getAll(action.queryParams).pipe(
          map(models => {
            return EmployeeBarAdmissionActions.getAllEmployeeBarAdmissionSuccess({ models });
          }),
          catchError(error => {
            return of(
              EmployeeBarAdmissionActions.getAllEmployeeBarAdmissionFailure({ error }),
              toastErrorAction({ message: 'Failed to get bar admissions' })
            );
          })
        );
      })
    )
  );

  updateAllEmployeeBarAdmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeBarAdmissionActions.updateAllEmployeeBarAdmission),
      mergeMap((action: EmployeeBarAdmissionActions.UpdateAllEmployeeBarAdmissionAction) => {
        return this.employeeBarAdmissionService.updateAll(action.models).pipe(
          concatMap(() => {
            return [
              EmployeeBarAdmissionActions.updateAllEmployeeBarAdmissionSuccess({models: action.models}),
              toastSuccessAction({ message: 'Employee bar admissions updated successfully' })              
            ];
          }),
          catchError(error => {
            return of(
              EmployeeBarAdmissionActions.updateAllEmployeeBarAdmissionFailure({ error }),
              toastErrorAction({ message: 'Failed to update bar admissions' })
            );
          })
        );
      })
    )
  );

}