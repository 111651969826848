<h1>Departments</h1>
<div class="d-flex align-items-center" *ngIf="isLoading$ | async">
  <strong>Loading...</strong>
  <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
</div>

<div class="col-md-6 align-self-center mt-4">
  <mdb-form-control>
    <input mdbInput type="text" class="form-control" id="search-input" (keyup)="search($event)" />
    <label mdbLabel class="form-label" for="search-input">Search</label>
  </mdb-form-control>
</div>

<div class="datatable mt-4">
  <ng-container *ngIf="departments$ | async as departments">
    <table  class="table datatable-table"
            mdbTable
            mdbTableSort
            #table="mdbTable"
            #sort="mdbTableSort"
            [dataSource]="departments"
            [pagination]="pagination"
            [sort]="sort">
      <thead class="datatable-header">
        <tr>
          <th *ngFor="let header of headers" [mdbTableSortHeader]="header" scope="col">
            {{ header | titlecase }}
          </th>
        </tr>
      </thead>
      <tbody class="datatable-body">
        <tr *ngFor="let data of table.data">
          <td>
            {{ data.name }}
          </td>
          <td>
            <span class="badge rounded-pill bg-dark" *ngIf="!data.isActive">InActive</span>
            <span class="badge rounded-pill bg-success" *ngIf="data.isActive">Active</span>
          </td>
          <td *ngIf="((user$ | async)?.isAdmin)">
            <button type="button" class="btn btn-primary" (click)="handleClick(data);">Edit</button>        
          </td>
        </tr>
      </tbody>
    </table>
    <mdb-table-pagination #pagination></mdb-table-pagination>
  </ng-container>
</div>