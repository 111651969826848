import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootState } from 'src/app/store/root.state';
import { loginDisplay } from 'src/app/store/state/auth.state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  loginDisplay$!: Observable<boolean>;
  isLoading = true;

  constructor(private store: Store<RootState>) 
  {}

  ngOnInit(): void {
    this.loginDisplay$ = this.store.pipe(select(loginDisplay));
    this.loginDisplay$.subscribe((loginDisplay: boolean) => {
      this.isLoading = !loginDisplay;
    })
  }

}