<form #form="ngForm">
  <div class="d-flex flex-row-reverse">
    <button type="button" class="btn btn-primary btn-sm" 
            *ngIf="hasEditButton" 
            (click)="onEdit()">
      <i class="fas fa-edit"></i> Edit
    </button>
    <button type="button" class="btn btn-primary btn-sm ml-05" *ngIf="canUpdate" (click)="onSave()">
      <span class="spinner-border spinner-border-sm" role="status" *ngIf="(isSaving$ | async)" aria-hidden="true"></span>
      <i class="fas fa-save ml-025"></i>  Save
    </button>
    <button type="button" class="btn btn-primary btn-sm ml-05" *ngIf="canUpdate" 
            (click)="openPopconfirm($event)">
      <i class="fas fa-undo"></i> Cancel
    </button>
    
  </div>
	<div class="row">
		<div class="col-md-4">
      <app-input-phone [cssClass]="'col-10'" [fieldName]="'workPhone'" [labelHtml]="'Work Phone'" 
          [disabled]="hasItAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.workPhone" (fieldValueChange)="model.workPhone=$event"/>
      <app-input-phone [cssClass]="'col-10'" [fieldName]="'fax'" [labelHtml]="'Fax'"
          [disabled]="hasItAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.fax" (fieldValueChange)="model.fax=$event"/>
      <app-input-phone [cssClass]="'col-10'" [fieldName]="'mobilePhone'" [labelHtml]="'Mobile Phone'"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.mobilePhone" (fieldValueChange)="model.mobilePhone=$event"/>		
      <app-input-checkbox [cssClass]="'col-10'" [fieldName]="'keepMobilePhoneConfidential'" [labelHtml]="' Keep Mobile Confidential?'"
          [disabled]="(isReadOnly) || (hasHrAdminPermissions === false)" [readonly]="isReadOnly"
          [(fieldValue)]="model.keepMobilePhoneConfidential" (fieldValueChange)="model.keepMobilePhoneConfidential=$event"/>
      <app-input-phone [cssClass]="'col-10 inputMargin'" [fieldName]="'homePhone'" [labelText]="'Home Phone'"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.homePhone" (fieldValueChange)="model.homePhone=$event" />
      <app-input-checkbox [cssClass]="'col-10'" [fieldName]="'keepHomePhoneConfidential'" [labelHtml]="' Keep Home Confidential?'"
          [disabled]="(isReadOnly) || (hasHrAdminPermissions === false)" [readonly]="isReadOnly"
          [(fieldValue)]="model.keepHomePhoneConfidential" (fieldValueChange)="model.keepHomePhoneConfidential=$event"/>
		</div>
		<div class="col-md-4">
      <app-input-text [cssClass]="'col-10'" [fieldName]="'homeAddress1'" [labelHtml]="'Home Address<span class=&quot;text-danger&quot;>**</span>'"
          maxlength="100"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.homeAddress1" (fieldValueChange)="model.homeAddress1=$event" />
      <app-input-text [cssClass]="'col-10'" [fieldName]="'homeAddress2'" [labelHtml]="'Home Address 2<span class=&quot;text-danger&quot;>**</span>'"
          maxlength="100"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.homeAddress2" (fieldValueChange)="model.homeAddress2=$event" />
			<app-input-text [cssClass]="'col-10'" [fieldName]="'homeCity'" [labelHtml]="'City<span class=&quot;text-danger&quot;>**</span>'"
          maxlength="100"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.homeCity" (fieldValueChange)="model.homeCity=$event" />
      <app-input-text [cssClass]="'col-10'" [fieldName]="'homeState'" [labelHtml]="'State<span class=&quot;text-danger&quot;>**</span>'"
          maxlength="50"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.homeState" (fieldValueChange)="model.homeState=$event" />
      <app-input-text [cssClass]="'col-10'" [fieldName]="'homeZip'" [labelHtml]="'Zip<span class=&quot;text-danger&quot;>**</span>'"
          pattern="\d*" maxlength="5"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.homeZip" (fieldValueChange)="model.homeZip=$event" />
		</div>
		<div class="col-md-4">
      <app-input-phone [cssClass]="'col-10'" [fieldName]="'emergencyPhone'" [labelHtml]="'Emergency Phone'"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.emergencyPhone" (fieldValueChange)="model.emergencyPhone=$event" />
      <app-input-phone [cssClass]="'col-10'" [fieldName]="'emergencyText'" [labelHtml]="'Emergency Text'"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.emergencyText" (fieldValueChange)="model.emergencyText=$event" />
      <app-input-text [cssClass]="'col-10'" [fieldName]="'emergencyEmail'" [labelHtml]="'Emergency Email'"
          inputType="'email'" maxlength="256"
          [disabled]="hasHrAdminPermissions === false" [readonly]="isReadOnly"
          [(fieldValue)]="model.emergencyEmail" (fieldValueChange)="model.emergencyEmail=$event" />
		</div>
	</div>
  <div class="mt-1">
    <span class="text-ukg">** Data is synced nightly from UKG.</span>
  </div>
</form>