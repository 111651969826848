import { ICounty } from "src/app/models/county.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface CountyState extends BaseState {
  counties: ICounty[];
}

export const initialCountyState: CountyState = {
  ...initialBaseState,
  counties: [],
};


