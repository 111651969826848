import { IEmployee } from "./employee.model";
import { ISupportTeamMember } from "./support-team-member";

export interface ISupportTeam {
  supportTeamId?: number;
  name?: string;
  phone?: string;
  email?: string;
  photoName?: string;
  isActive: boolean;
  coordinatorId?: number;
  backupCoordinatorId?: number;

  coordinator?: IEmployee;
  backupCoordinator?: IEmployee;
  supportTeamMembers?: ISupportTeamMember[];
  
}

export function createSupportTeamDto(model: ISupportTeam): ISupportTeam {
  let members = model.supportTeamMembers;    
  if (model.supportTeamMembers) members = model.supportTeamMembers.map(pl => {
    return {
      supportTeamMemberId: pl.supportTeamMemberId,
      employeeId: pl.employeeId,
      supportTeamId: pl.supportTeamId
    } as ISupportTeamMember;
  });
  const result: ISupportTeam =
  {
    supportTeamId: model.supportTeamId,
    name: model.name,
    phone: model.phone,
    email: model.email,
    photoName: model.photoName,
    isActive: model.isActive,
    coordinatorId: model.coordinatorId,
    backupCoordinatorId: model.backupCoordinatorId,
    supportTeamMembers: members
  };
  return result;
}

export const SupportTeamFeature = {
  FeatureName: 'SupportTeam',
  ControllerPath: 'supportteam',
  CreateSuccessMessage: 'Support Team created successfully.',
  CreateFailureMessage: 'Failed to create Support Team.',
  UpdateSuccessMessage: 'Support Team updated successfully.',
  UpdateFailureMessage: 'Failed to update Support Team.',
  GetFailureMessage: 'Failed to get Support Team.',
};

export const SupportTeamsFeature = {
  FeatureName: 'SupportTeams',
  ControllerPath: 'supportteam',
  GetFailureMessage: 'Failed to get Support Teams.',
};