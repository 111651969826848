<div class="d-flex flex-row-reverse">
  <button type="button" class="btn btn-primary btn-sm ml-05" 
            (click)="openAddNewModal()" 
            *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))">
    <i class="fas fa-plus"></i>
    Add New Language
  </button>
  <button type="button" class="btn btn-primary btn-sm" 
          *ngIf="(canUpdate$ | async) === false && (((user$ | async)?.isHR) || ((user$ | async)?.isAdmin))" 
          (click)="onEdit()">
    <i class="fas fa-edit"></i> Edit
  </button>
  <button type="button" class="btn btn-primary btn-sm ml-05" *ngIf="(canUpdate$ | async)" 
          (click)="!onCancelCheck() ? openPopconfirm($event) : onCancel()">
    <i class="fas fa-undo"></i> Cancel
  </button>
  <button type="button" class="btn btn-primary btn-sm" *ngIf="(canUpdate$ | async)" (click)="onSave()">      
    <i class="fas fa-save ml-025"></i> Save
  </button>
</div>
<div class="row"> 
  <div class="col-md-9 ml-1">
    <div class="d-flex align-items-center" *ngIf="(isLoading$ | async)">
      <strong>Loading...</strong>
      <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
    </div>
    <div *ngIf="employeeLanguages.length === 0 && (isLoading$ | async) === false">
      <h6>No languages found.</h6>
      <h6 *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))"> Click on "Add New Language" to add a new language. </h6>
    </div>
    <table class="table table-bordered table-responsive-md table-striped" *ngIf="employeeLanguages.length > 0 && (isLoading$ | async) === false" >
      <tr>
        <th class="text-center">Language</th>
        <th class="text-center">Proficiency</th>
        <th class="text-center">Sort Order</th>
        <th *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))"></th>
      </tr>
      <tr *ngFor="let emplanguage of employeeLanguages;let id=index" >
        <td class="text-center">
          <ng-select  [(ngModel)]="emplanguage.languageId"
                      [readonly]="(canUpdate$ | async) === false"
                      name="languageId"
                      [loading]="(isLanguageLoading$ | async) ?? false">
            <ng-option *ngFor="let temp of (languages$ | async)" [value]="temp.id">{{temp.name}}</ng-option>
          </ng-select>
        </td>        
        <td class="text-center" >
          <ng-select  [(ngModel)]="emplanguage.proficiency"
                      [readonly]="(canUpdate$ | async) === false"
                      name="proficiency"
                      id="proficiency"
                      [loading]="(isProficiencyLoading$ | async) ?? false"    
                      bindlabel="name">
            <ng-option *ngFor="let temp of (proficiencies$ | async)" [value]="temp.name">{{temp.name}}</ng-option>
          </ng-select>          
        </td>
        <td class="text-center sort-order-width">
          <input  type="number"
                  name="sortOrder"
                  id="sortOrder"
                  class="form-control input-sort-order"
                  [readonly]="(canUpdate$ | async) === false"
                  mdbInput 
                  [(ngModel)]="emplanguage.sortOrder" />          
        </td>
        <td class="text-center delete-width" *ngIf="(((user$ | async)?.isAdmin) || ((user$ | async)?.isHR))">
          <span>
            <a class="icon-selectable" href="#!" role="button" 
                *ngIf="(canUpdate$ | async)" 
                (click)="setDeleteId(emplanguage.id);deletePopconfirm($event)">
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
            <a *ngIf="(canUpdate$ | async) === false" class="disabled icon-disabled">
              <i class="fas fa-trash-alt fa-lg"></i>
            </a>
          </span>
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add new language</h5>
    <button type="button" class="btn-close-custom" aria-label="Close" (click)="templateModalRef.close()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <ng-select  appearance="outline"
                    [(ngModel)]="newLanguage.languageId"
                    placeholder="Search to add language"
                    name="languageId"
                    [loading]="(isLanguageLoading$ | async) ?? false"
                    bindlabel="name">
          <ng-option *ngFor="let temp of (languages$ | async)" [value]="temp.id">{{temp.name}}</ng-option>
        </ng-select>
      </div>
      <div class="col-md-12">
        <ng-select  [(ngModel)]="newLanguage.proficiency"
                    placeholder="Search to add proficiency"           
                    name="proficiency"
                    id="proficiency"
                    appearance="outline"
                    [loading]="(isProficiencyLoading$ | async) ?? false"
                    bindlabel="name">
          <ng-option *ngFor="let temp of (proficiencies$ | async)" [value]="temp.name">{{temp.name}}</ng-option>
        </ng-select>    
      </div>
      <div class="col-md-12">
        <mdb-form-control>
          <input mdbInput type="number" id="sortOrder" name="sortOrder" class="form-control" 
                [(ngModel)]="newLanguage.sortOrder" />
          <label mdbLabel class="form-label" for="sortOrder">Sort Order</label>
        </mdb-form-control>       
      </div>
    </div> 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="templateModalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="add()">Add</button>
  </div>
</ng-template>