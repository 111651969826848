<div class="media">
  <div class="media-body ml-05">
    <div class="row">
      <div class="col-md-4"><h4>Add New Department</h4></div>
      <div class="col-md-3 offset-md-5 text-right">
        <button type="button" class="btn btn-primary btn-sm" (click)="save()">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving$ | async" aria-hidden="true"></span>
          <i class="fas fa-save ml-025"></i>  Save</button>
        <button class="btn btn-primary btn-sm ml-05" (click)="!onCancelCheck() ? openCancelModal() : cancelUpdate()">
          <i class="fas fa-undo"></i> Cancel
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <form>
      <app-input-text [cssClass]="'col-6'"  [fieldName]="'name'" [labelHtml]="'Name'" [(fieldValue)]="model.name"></app-input-text>
    </form>
  </div>
</div>