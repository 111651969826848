import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { EmployeeSupportTeamState } from '../state/employee-support-team.state';

const state = (state: RootState) => state.employeeSupportTeam;

const isLoading = createSelector(
  state,
  (state: EmployeeSupportTeamState) => state.isLoading
);

const canUpdate = createSelector(
  state,
  (state: EmployeeSupportTeamState) => state.canUpdate
);

const getAll = createSelector(
  state,
  (state: EmployeeSupportTeamState) => state.employeeSupportTeams
);

export const employeeSupportTeamSelectors = {
  isLoading,
  canUpdate,
  getAll
};