import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEmployeeEducation } from '../models/employee-education.model';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})

export class EmployeeEducationService extends AppAuthenticatedCrudBaseService<IEmployeeEducation> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'EmployeeEducation', store);
  }

  createDto(model: IEmployeeEducation): IEmployeeEducation {    
    const result: IEmployeeEducation =
    {
      employeeEducationID: model.employeeEducationID,
      employeeId: model.employeeId,
      education: model.education      
    };
    return result;
  }

}