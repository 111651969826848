import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';

@Injectable()
export class ToastEffects {
 
  private actions$ = inject(Actions);

  constructor(
    private toastService: ToastService
  ) {}

  showSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toastSuccessAction),
      tap(({ message }) => { 
        this.toastService.showSuccess(message);
      })
    ), { dispatch: false }
  );

  showError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toastErrorAction),
      tap(({ message }) => {
        this.toastService.showError(message);
      })
    ), { dispatch: false }
  );

}