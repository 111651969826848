<form #form="ngForm" *ngIf="(user$ | async) as user">
  <div class="d-flex flex-row-reverse">
    <button type="button" class="btn btn-primary btn-sm" 
            *ngIf="hasEditButton" 
            (click)="onEdit()">
      <i class="fas fa-edit"></i> Edit
    </button>    
    <button type="button" class="btn btn-primary btn-sm ml-05" *ngIf="canUpdate" 
            (click)="!onCancelCheck() ? openPopconfirm($event) : onCancel()">
      <i class="fas fa-undo"></i> Cancel
    </button>
    <button type="button" class="btn btn-primary btn-sm" *ngIf="canUpdate" (click)="onSave()">
      <span class="spinner-border spinner-border-sm" role="status" *ngIf="(isSaving$ | async)" aria-hidden="true"></span>
      <i class="fas fa-save ml-025"></i>  Save
    </button>
  </div>
  <div class="row">
    <div class="col-md-3">
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="prefix" name="prefix" class="form-control" [(ngModel)]="model.prefix" 
                [disabled]="hasHrAdminPermissions === false"  
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="prefix">Prefix <span class="noteColor">**</span></label>
      </mdb-form-control>
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="firstName" name="firstName" class="form-control" [(ngModel)]="model.firstName" [ngModelOptions]="{standalone: true}"
                [disabled]="hasHrAdminPermissions === false"  
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="firstName">First Name <span class="noteColor">**</span></label>
      </mdb-form-control>
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="middleName" name="middleName" class="form-control" [(ngModel)]="model.middleName"
                [disabled]="hasHrAdminPermissions === false"  
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="middleName">Middle Initial <span class="noteColor">**</span></label>
      </mdb-form-control>
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="legalLastName" name="legalLastName" class="form-control" [(ngModel)]="model.legalLastName" 
                [disabled]="hasHrAdminPermissions === false"  
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="lastName">Legal Last Name <span class="noteColor">**</span></label>
      </mdb-form-control>
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="suffix" name="suffix" class="form-control" [(ngModel)]="model.suffix"
                [disabled]="hasHrAdminPermissions === false"  
                [readonly]="isReadOnly" />
        <label mdbLabel class="form-label" for="suffix">Suffix <span class="noteColor">**</span></label>
      </mdb-form-control>
    </div>
    <div class="col-md-3">
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="initials" name="initials" class="form-control" 
                [(ngModel)]="model.initials" 
                [disabled]="hasHrAdminPermissions === false"  
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="initials">Initials </label>
      </mdb-form-control>
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="nickName" name="nickName" class="form-control" [(ngModel)]="model.nickName" 
                [disabled]="hasHrAdminPermissions === false"  
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="nickName">NickName <span class="noteColor">**</span></label>
      </mdb-form-control>
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="professionalFirstName" name="professionalFirstName" class="form-control" [(ngModel)]="model.professionalFirstName" 
                [disabled]="hasHrAdminPermissions === false" 
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="professionalFirstName">Professional First Name</label>
      </mdb-form-control>
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="preferredLastName" name="preferredLastName" class="form-control" [(ngModel)]="model.preferredLastName" 
                [disabled]="hasHrAdminPermissions === false" 
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="preferredLastName">Preferred Last Name</label>
      </mdb-form-control>    
    </div>
    <div class="col-md-3">
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="email" name="email" class="form-control" [(ngModel)]="model.email" 
                [disabled]="hasHrItAdminPermissions === false" 
                [readonly]="isReadOnly" />
        <label mdbLabel class="form-label" for="email">Email</label>
      </mdb-form-control> 
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="ntId" name="ntId" class="form-control" 
                [(ngModel)]="model.ntId" 
                [disabled]="hasHrItAdminPermissions === false"  
                [readonly]="isReadOnly"/>
        <label mdbLabel class="form-label" for="ntId">Nt Id</label>
      </mdb-form-control>
      <mdb-form-control class="col-10 margin" >
        <input  mdbInput type="text" id="timeKeeperNumber" name="timeKeeperNumber" 
                class="form-control" [(ngModel)]="model.timeKeeperNumber"
                [disabled]="hasHrItAdminPermissions === false" 
                [readonly]="isReadOnly" />
        <label mdbLabel class="form-label" for="timeKeeperNumber">Timekeeper# <span class="noteColor">**</span></label>
      </mdb-form-control>
    </div>
    <div class="col-md-3">
      <mdb-form-control class="col-10 mt-3">
        <input  mdbInput type="text" id="birthDate" name="birthDate" class="form-control" 
                [(ngModel)]="model.birthDate" 
                [disabled]="hasHrAdminPermissions === false" 
                [readonly]="isReadOnly" />
        <label mdbLabel class="form-label" for="birthDate">Birth Date <span class="noteColor">**</span></label>
      </mdb-form-control>
      <span class="label-dob">as (MM/DD)</span>
      <div class="form-check col-10">
        <input mdbCheckbox class="form-check-input" type="checkbox"  
              id="keepBirthDateConfidential" [(ngModel)]="model.keepBirthDateConfidential" [ngModelOptions]="{standalone: true}"
              [disabled]="(isReadOnly) || hasHrAdminPermissions === false" />
        <label class="form-check-label" for="keepBirthDateConfidential"> Keep Birthdate Confidential? </label>
      </div>    
    </div>
  </div>
  <div>
    <span class="text-ukg">** Data is synced nightly from UKG.</span>
  </div>
</form>