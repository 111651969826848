import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';
import { Observable, map, of } from 'rxjs';
import { IEmployeeDetailsView } from 'src/app/models/employee-details-view.model';
import { Profile } from 'src/app/models/user.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';
import { getEmployeeToReactivate } from 'src/app/store/actions/employee-key-dates.actions';
import { IEmployee } from 'src/app/models/employee.model';

@Component({
  selector: 'app-employee-inactive',
  templateUrl: './employee-inactive.component.html',
  styleUrls: ['./employee-inactive.component.scss']
})
export class EmployeeInactiveComponent implements OnInit {
  user$!: Observable<Profile | null>;
  employees$: Observable<IEmployeeDetailsView[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  headers = ['Name', 'Title', 'Resignation Date'];

  @ViewChild('table') table!: MdbTableDirective<IEmployeeDetailsView>;
    
  constructor( private readonly title: Title
              , private store: Store<RootState>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectors.employees.isLoading);
    this.initEmployees();  
    this.title.setTitle(`Inactive Employees`);
  }
  
  initEmployees() {
    this.employees$ = this.store.select(selectors.employees.get).pipe(
      map((employees: IEmployee[]) => Array.isArray(employees) ? employees : [])
    );
    this.user$ = this.store.pipe(select(user));
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.store.dispatch(CrudActions.get(Feature.Employees)(undefined, {isActive: false}));    
      }
    });
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.table.search(searchTerm);
  }
  
  handleClick(employee: IEmployeeDetailsView) {   
    if(employee.employeeId) {
      this.store.dispatch(getEmployeeToReactivate({ id: employee.employeeId }));
    }
  }

}