import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbTableModule } from 'mdb-angular-ui-kit/table';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbPopconfirmModule } from 'mdb-angular-ui-kit/popconfirm';
import { MdbWysiwygModule } from 'mdb-angular-wysiwyg';
import { MdbFileUploadModule } from 'mdb-angular-file-upload';

import { AppComponent } from './app.component';
import { OfficesComponent } from './components/offices/offices.component';
import { OfficeComponent } from './components/office/office.component';
import { HomeComponent } from './components/home/home.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { SupportTeamComponent } from './components/support-team/support-team.component';
import { PscComponent } from './components/psc/psc.component';
import { PscsComponent } from './components/pscs/pscs.component';
import { SupportTeamsComponent } from './components/support-teams/support-teams.component';
import { SbuComponent } from './components/sbu/sbu.component';
import { SbusComponent } from './components/sbus/sbus.component';
import { EmployeesComponent } from './components/employees/employees.component';
import { EmployeeOrgComponent } from './components/employee-org/employee-org.component';
import { EmployeeContactComponent } from './components/employee-contact/employee-contact.component';
import { EmployeeProfileComponent } from './components/employee-profile/employee-profile.component';
import { EmployeeEducationComponent } from './components/employee-education/employee-education.component';
import { EmployeeBarComponent } from './components/employee-bar/employee-bar.component';
import { EmployeeSupportTeamComponent } from './components/employee-support-team/employee-support-team.component';
import { ImageComponent } from './components/image/image.component';
import { DepartmentsComponent } from './components/departments/departments.component';
import { EmployeeBioAdminComponent } from './components/employee-bio-admin/employee-bio-admin.component';
import { CommitteesComponent } from './components/committees/committees.component';
import { CommitteeComponent } from './components/committee/committee.component';
import { DepartmentComponent } from './components/department/department.component';
import { SupportTeamNewComponent } from './components/support-team-new/support-team-new.component';
import { OfficeNewComponent } from './components/office-new/office-new.component';
import { DepartmentNewComponent } from './components/department-new/department-new.component';
import { CommitteeNewComponent } from './components/committee-new/committee-new.component';
import { ToastErrorComponent } from './components/helpers/toast-error/toast-error.component';
import { ToastSuccessComponent } from './components/helpers/toast-success/toast-success.component';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {  MsalGuard, 
          MsalInterceptor, 
          MsalBroadcastService, 
          MsalInterceptorConfiguration, 
          MsalModule, 
          MsalService, 
          MSAL_GUARD_CONFIG, 
          MSAL_INSTANCE, 
          MSAL_INTERCEPTOR_CONFIG, 
          MsalGuardConfiguration,
          MsalRedirectComponent 
        } from '@azure/msal-angular';
import { loginRequest, msalConfig, protectedResources } from './auth-config';
import { ModalCancelComponent } from './components/helpers/modal-cancel/modal-cancel.component';
import { ConfirmComponent } from './components/helpers/confirm/confirm.component';
import { DeleteConfirmComponent } from './components/helpers/delete-confirm/delete-confirm.component';
import { EmployeeKeyDatesComponent } from './components/employee-key-dates/employee-key-dates.component';
import { EmployeeNewComponent } from './components/employee-new/employee-new.component';
import { ChangeRequestComponent } from './components/change-request/change-request.component';
import { LanguageNewComponent } from './components/language-new/language-new.component';
import { BarAdmissionNewComponent } from './components/bar-admission-new/bar-admission-new.component';
import { EmployeeLanguageComponent } from './components/employee-language/employee-language.component';
import { EmployeeNotaryComponent } from './components/employee-notary/employee-notary.component';
import { EmployeeInactiveComponent } from './components/employee-inactive/employee-inactive.component';
import { BaseComponent } from './components/base/base.component';
import { InputComponent } from './components/common/input/input.component';
import { InputPhoneComponent } from './components/common/input-phone/input-phone.component';
import { InputTextComponent } from './components/common/input-text/input-text.component';
import { InputCheckboxComponent } from './components/common/input-checkbox/input-checkbox.component';
import { DatepickerComponent } from './components/common/datepicker/datepicker/datepicker.component';
import { Actions, EffectsModule } from '@ngrx/effects';
import { reducers } from './store/root.state';
import { InputNumberComponent } from './components/common/input-number/input-number.component';
import { EmployeeBarAdmissionEffects } from './store/effects/employee-bar-admission.effects';
import { EmployeeLanguageEffects } from './store/effects/employee-language.effects';
import { ProficiencyEffects } from './store/effects/proficiency.effects';
import { CountyEffects } from './store/effects/county.effects';
import { EmployeeNotaryEffects } from './store/effects/employee-notary.effects';
import { EmployeeSupportTeamEffects } from './store/effects/employee-support-team.effects';
import { EmployeeTypeEffects } from './store/effects/employee-type.effects';
import { EmployeeOrgEffects } from './store/effects/employee-org.effects';
import { EmployeeContactEffects } from './store/effects/employee-contact.effects';
import { EmployeeEducationEffects } from './store/effects/employee-education.effects';
import { ToastEffects } from './store/effects/toast.effects';
import { createCrudEffects } from './store/effects/crud.effects';
import { IBarAdmission } from './models/bar-admission.model';
import { Feature } from './models/features.model';
import { IChangeRequest } from './models/change-request.model';
import { IDepartment } from './models/department.model';
import { ICommittee } from './models/committee.model';
import { IEmployee } from './models/employee.model';
import { ILanguage } from './models/language.model';
import { ISupportTeam } from './models/support-team.model';
import { IOffice } from './models/office.model';
import { IPsc } from './models/psc.model';
import { ISbu } from './models/sbu.model';
import { IEmployeeKeyDates } from './models/employee-key-dates.model';
import { EmployeeKeyDatesEffects } from './store/effects/employee-key-dates.effects';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatTabsModule } from '@angular/material/tabs';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(protectedResources.profileApi.endpoint, protectedResources.profileApi.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    OfficesComponent,
    OfficeComponent,
    HomeComponent,
    EmployeeComponent,
    SupportTeamComponent,
    PscComponent,
    PscsComponent,
    SupportTeamsComponent,
    SbuComponent,
    SbusComponent,
    EmployeesComponent,
    EmployeeOrgComponent,
    EmployeeContactComponent,
    EmployeeProfileComponent,
    EmployeeEducationComponent,
    EmployeeBarComponent,
    EmployeeSupportTeamComponent,    
    EmployeeBioAdminComponent,
    EmployeeKeyDatesComponent,
    EmployeeNewComponent,
    ImageComponent,
    DepartmentsComponent,
    CommitteesComponent,
    CommitteeComponent,
    DepartmentComponent,
    SupportTeamNewComponent,
    OfficeNewComponent,
    DepartmentNewComponent,
    CommitteeNewComponent,
    ToastErrorComponent,
    ToastSuccessComponent,
    ModalCancelComponent,
    ConfirmComponent,
    DeleteConfirmComponent,
    ChangeRequestComponent,
    LanguageNewComponent,
    BarAdmissionNewComponent,
    EmployeeLanguageComponent,
    EmployeeNotaryComponent,
    EmployeeInactiveComponent,
    BaseComponent,
    InputComponent,
    InputNumberComponent,
    InputPhoneComponent,
    InputTextComponent,
    InputCheckboxComponent,
    DatepickerComponent
  ],
  imports: [    
    MdbNotificationModule,
    MdbCollapseModule,
    MdbTableModule,
    MdbCheckboxModule,
    MdbFormsModule,
    MdbDropdownModule,
    MdbModalModule,
    MdbTabsModule,
    MdbDatepickerModule,
    MdbPopconfirmModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MsalModule,
    StoreModule.forRoot(reducers),   
    EffectsModule.forRoot([
      createCrudEffects<IBarAdmission>(Feature.BarAdmission),
      createCrudEffects<IBarAdmission[]>(Feature.BarAdmissions),
      createCrudEffects<IChangeRequest>(Feature.ChangeRequest),
      createCrudEffects<ICommittee>(Feature.Committee),
      createCrudEffects<ICommittee[]>(Feature.Committees),
      createCrudEffects<IDepartment>(Feature.Department),
      createCrudEffects<IDepartment[]>(Feature.Departments),
      createCrudEffects<IEmployee>(Feature.Employee),
      createCrudEffects<IEmployee[]>(Feature.Employees),
      createCrudEffects<ILanguage>(Feature.Language),
      createCrudEffects<ILanguage[]>(Feature.Languages),
      createCrudEffects<IOffice>(Feature.Office),
      createCrudEffects<IOffice[]>(Feature.Offices),
      createCrudEffects<ISupportTeam>(Feature.SupportTeam),
      createCrudEffects<ISupportTeam[]>(Feature.SupportTeams),
      createCrudEffects<IPsc>(Feature.Psc),
      createCrudEffects<IPsc[]>(Feature.Pscs),
      createCrudEffects<ISbu>(Feature.Sbu),
      createCrudEffects<ISbu[]>(Feature.Sbus),
      EmployeeKeyDatesEffects,
      
      CountyEffects,
      EmployeeBarAdmissionEffects,
      EmployeeContactEffects,
      EmployeeEducationEffects,
      createCrudEffects<IEmployeeKeyDates>(Feature.EmployeeKeyDates),
      EmployeeLanguageEffects,
      EmployeeNotaryEffects,
      EmployeeOrgEffects,
      EmployeeSupportTeamEffects,
      EmployeeTypeEffects,
      ProficiencyEffects,
      ToastEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ScrollingModule,
    MdbFileUploadModule,
    MdbWysiwygModule,
    NgSelectModule,
    MatTabsModule
  ],
  providers: [
    Actions,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideAnimationsAsync()
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }