
import { IBarAdmission } from "src/app/models/bar-admission.model";
import { RootState } from "../root.state";
import { createCrudSelectors } from "./crud.selectors";
import { countySelectors } from "./county.selectors";
import { employeeBarAdmissionSelectors } from "./employee-bar-admission.selectors";
import { employeeContactSelectors } from "./employee-contact.selectors";
import { employeeEducationSelectors } from "./employee-education.selectors";
import { employeeLanguageSelectors } from "./employee-language.selectors";
import { employeeNotarySelectors } from "./employee-notary.selectors";
import { employeeOrgSelectors } from "./employee-org.selectors";
import { employeeSupportTeamSelectors } from "./employee-support-team.selectors";
import { employeeTypeSelectors } from "./employee-type.selectors";
import { proficiencySelectors } from "./proficiency.selectors";
import { IChangeRequest } from "src/app/models/change-request.model";
import { ICommittee } from "src/app/models/committee.model";
import { IDepartment } from "src/app/models/department.model";
import { IEmployee } from "src/app/models/employee.model";
import { ILanguage } from "src/app/models/language.model";
import { IOffice } from "src/app/models/office.model";
import { ISupportTeam } from "src/app/models/support-team.model";
import { ISbu } from "src/app/models/sbu.model";
import { IPsc } from "src/app/models/psc.model";
import { IEmployeeKeyDates } from "src/app/models/employee-key-dates.model";

export const selectors = {
   barAdmission: createCrudSelectors<IBarAdmission>((state: RootState) => state.barAdmission),
   barAdmissions: createCrudSelectors<IBarAdmission[]>((state: RootState) => state.barAdmissions),
   changeRequest: createCrudSelectors<IChangeRequest>((state: RootState) => state.changeRequest),
   committee: createCrudSelectors<ICommittee>((state: RootState) => state.committee),
   committees: createCrudSelectors<ICommittee[]>((state: RootState) => state.committees),
   county: countySelectors,
   department: createCrudSelectors<IDepartment>((state: RootState) => state.department),
   departments: createCrudSelectors<IDepartment[]>((state: RootState) => state.departments),
   employeeBarAdmission: employeeBarAdmissionSelectors,
   employeeContact: employeeContactSelectors,
   employeeEducation: employeeEducationSelectors,
   employeeKeyDates: createCrudSelectors<IEmployeeKeyDates>((state: RootState) => state.employeeKeyDates),
   employeeLanguage: employeeLanguageSelectors,
   employeeNotary: employeeNotarySelectors,
   employeeOrg: employeeOrgSelectors,
   employeeSupportTeam: employeeSupportTeamSelectors,
   employeeType: employeeTypeSelectors,
   employee:  createCrudSelectors<IEmployee>((state: RootState) => state.employee),
   employees: createCrudSelectors<IEmployee[]>((state: RootState) => state.employees), 
   language: createCrudSelectors<ILanguage>((state: RootState) => state.language),
   languages: createCrudSelectors<ILanguage[]>((state: RootState) => state.languages),
   office: createCrudSelectors<IOffice>((state: RootState) => state.office),
   offices: createCrudSelectors<IOffice[]>((state: RootState) => state.offices),
   proficiency: proficiencySelectors,
   psc: createCrudSelectors<IPsc>((state: RootState) => state.psc),
   pscs: createCrudSelectors<IPsc[]>((state: RootState) => state.pscs),
   sbu: createCrudSelectors<ISbu>((state: RootState) => state.sbu),
   sbus: createCrudSelectors<ISbu[]>((state: RootState) => state.sbus),
   supportTeam: createCrudSelectors<ISupportTeam>((state: RootState) => state.supportTeam),  
   supportTeams: createCrudSelectors<ISupportTeam[]>((state: RootState) => state.supportTeams),
}