import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { EmployeeOrgState } from '../state/employee-org.state';

const state = (state: RootState) => state.employeeOrg;

const isLoading = createSelector(
  state,
  (state: EmployeeOrgState) => state.isLoading
);

const isSaving = createSelector(
  state,
  (state: EmployeeOrgState) => state.isSaving
);

const canUpdate = createSelector(
  state,
  (state: EmployeeOrgState) => state.canUpdate
);

const get = createSelector(
  state,
  (state: EmployeeOrgState) => state.employeeOrg
);

export const employeeOrgSelectors = {
  isLoading,
  isSaving,
  canUpdate,
  get
};