
<div class="media">  
  <div class="alert alert-danger alert-dismissible fade show" role="alert"  *ngIf="showAlert">
    <strong>Employee with the Timekeeper#: {{model.timeKeeperNumber}} exists. </strong> 
    Go to Search & Edit to search for an employee and update information, or go to Inactive Employee to make an employee active.
    <button (click)="onAlertClose()" type="button" class="btn-close-custom" aria-label="Close"></button>
  </div>
  <div class="alert alert-warning alert-dismissible fade show" role="alert"  *ngIf="showRequiredMessage">
    <strong>First Name, Last Name, TimeKeeper#, Ntid, and Email are required. </strong>
    <button (click)="onRequiredMessageClose()" type="button" class="btn-close-custom" aria-label="Close"></button>
  </div>  
  <div class="media-body ml-05">
    <div class="row">
      <div class="col-md-4"><h4>Add New Employee</h4></div>
      <div class="col-md-3 offset-md-5 text-right">
        <button type="button" class="btn btn-primary btn-sm" (click)="save()">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving$ | async" aria-hidden="true"></span>
          <i class="fas fa-save ml-025"></i>  Save</button>
        <button class="btn btn-primary btn-sm ml-05" (click)="openCancelModal()">
          <i class="fas fa-undo"></i> Cancel
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <form>
      <app-input  type="text" 
                  className="col-6 margin"
                  fieldName="middleName"
                  [(inputModel)]="model.firstName"                   
                  label="First Name">
      </app-input>
      <app-input  type="text" 
                  className="col-6 margin"
                  fieldName="middleName"
                  [(inputModel)]="model.middleName"
                  label="Middle Initial">
      </app-input>
      <app-input  type="text" 
                  className="col-6 margin"
                  fieldName="legalLastName"
                  [(inputModel)]="model.legalLastName"                   
                  label="Legal Last Name">
      </app-input>
      <app-input  type="text" 
                  className="col-6 margin"
                  fieldName="timeKeeperNumber"
                  [(inputModel)]="model.timeKeeperNumber"                   
                  label="TimeKeeper#">
      </app-input>
      <app-input  type="text" 
                  className="col-6 margin"
                  fieldName="ntId"
                  [(inputModel)]="model.ntId"                   
                  label="Nt Id">
      </app-input>
      <app-input  type="text" 
                  className="col-6 margin"
                  fieldName="email"
                  [(inputModel)]="model.email"                   
                  label="Email">
      </app-input>   
    </form>
  </div>
</div>