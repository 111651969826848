import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CrudActions, CreateActionProps, UpdateActionProps, GetActionProps, DeleteActionProps } from '../actions/crud.actions';
import { CrudService } from 'src/app/services/crud.service';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';
import { IFeature } from 'src/app/models/features.model';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';


export function createCrudEffects<T>(feature: IFeature) {
  @Injectable()
  class FeatureCrudEffects {
    create$: Observable<Action>;
    get$:  Observable<Action>;
    update$:  Observable<Action>;
    delete$:  Observable<Action>;
    
    constructor(
      private actions$: Actions,
      private crudService: CrudService<T>,
      private router: Router
    ) {
    
    this.create$ = createEffect(() =>
      this.actions$.pipe(
        ofType(CrudActions.create<T>(feature)),
        mergeMap((action: CreateActionProps<T>) => {
          return this.crudService.create(action.feature, action.model).pipe(
            concatMap((model: T) => {
              const actions = [
                CrudActions.createSuccess<T>(feature)(model),
                toastSuccessAction({ message: feature.CreateSuccessMessage })
              ];
              if (action.routeParams) {
                let route = action.routeParams.route;
                if (action.routeParams.idPropertyName) {
                  const idValue = model[action.routeParams.idPropertyName] as string | number | undefined;
                  route = [...route, idValue];
                }
                this.router.navigate(route);
              }
              return actions;
            }),
            catchError((error: Error) => {
              return of(                
                CrudActions.createFailure(feature)(error),
                toastErrorAction({ message: feature.CreateFailureMessage})
              );
            })
          );
        })
      )
    );

    this.get$ = createEffect(() =>
      this.actions$.pipe(
        ofType(CrudActions.get(feature)),
        mergeMap((action: GetActionProps) => {
          return this.crudService.get(action.feature, action.id, action.queryParams).pipe(
            concatMap((model: T) => {
              return [
                CrudActions.getSuccess<T>(feature)(model)
              ];
            }),
            catchError((error: Error) => {
              return of(
                CrudActions.getFailure(feature)(error),
                toastErrorAction({ message: feature.GetFailureMessage })
              );
            })
          );
        })
      )
    );

    this.update$ = createEffect(() =>
      this.actions$.pipe(
        ofType(CrudActions.update<T>(feature)),
        mergeMap((action: UpdateActionProps<T>) => {
          return this.crudService.update(action.feature, action.model, action.id, action.queryParams).pipe(
            concatMap((model: T) => {
              return [
                CrudActions.updateSuccess<T>(feature)(model),
                toastSuccessAction({ message: feature.UpdateSuccessMessage })
              ];
            }),
            catchError((error: Error) => {
              return of(
                CrudActions.updateFailure(feature)(error),
                toastErrorAction({ message: feature.UpdateFailureMessage })
              );
            })
          );
        })
      )
    );

    this.delete$ = createEffect(() =>
      this.actions$.pipe(
        ofType(CrudActions.delete(feature)),
        mergeMap((action: DeleteActionProps) => {
          return this.crudService.delete(action.feature, action.id).pipe(
            concatMap((model: T) => {
              return [
                CrudActions.deleteSuccess<T>(feature)(model),
                toastSuccessAction({ message: feature.DeleteSuccessMessage })
              ];
            }),
            catchError((error: Error) => {
              return of(
                CrudActions.deleteFailure(feature)(error),
                toastErrorAction({ message: feature.DeleteFailureMessage })
              );
            })
          );
        })
      )
    );
  }
  }

  return FeatureCrudEffects;
}