import { createSelector } from '@ngrx/store';
import { MemberGroup } from '../../models/member-group.model';
import { Profile } from '../../models/user.model';
import { azureStorageURL } from '../../utils/app-config';

export interface AuthState {
  user: Profile | null;
  loginDisplay: boolean;
  memberOf: Array<MemberGroup>;
  accessToken: string;
  azureStorageKey: string;
  photoName:string;
  folderLocation:string;
  imageUniqueId:number;
  isSaving:boolean;
  isLoading:boolean;
}

export const initialState: AuthState = {
  user: null,
  loginDisplay: false,
  memberOf: [],
  accessToken: '',
  azureStorageKey:'',
  photoName:'',
  imageUniqueId:0,
  folderLocation:'',
  isSaving: false,
  isLoading:false
};

const authState = (state:{auth:AuthState}) => state.auth;
export const loginDisplay = createSelector(authState, state => state.loginDisplay);
export const user = createSelector(authState, state => state.user);
export const memberOf = createSelector(authState, state => state.memberOf);
export const accessToken = createSelector(authState, state => state.accessToken);
export const azureStorageKey = createSelector(authState, state => state.azureStorageKey);
export const photoName = createSelector(authState, state => state.photoName);
export const imageUniqueId = createSelector(authState, state => state.imageUniqueId);
export const folderLocation = createSelector(authState, state => state.folderLocation);
export const isSaving = createSelector(authState, state => state.isSaving);
export const isLoading = createSelector(authState, state => state.isLoading);


const azureImageURL = azureStorageURL;
export const imageURL = createSelector(
  azureStorageKey,
  photoName,
  folderLocation,
  imageUniqueId,
  (azureStorageKey: string, photoName: string, folder:string,imageUniqueId:number) => {
    if (azureStorageKey && photoName) {
      return `${azureImageURL}/${folder}/${photoName}${azureStorageKey}&t=${imageUniqueId}`;  
    }
    else {
      return '';
    }
  }
);