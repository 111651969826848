import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ICommittee } from 'src/app/models/committee.model';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { Observable, of, tap } from 'rxjs';
import { RootState } from 'src/app/store/root.state';
import { Store } from '@ngrx/store';
import { selectors } from 'src/app/store/selectors/selectors';
import { Feature } from 'src/app/models/features.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { toastErrorAction } from 'src/app/store/actions/toast.actions';

@Component({
  selector: 'app-committee-new',
  templateUrl: './committee-new.component.html',
  styleUrls: ['./committee-new.component.scss']
})
export class CommitteeNewComponent implements OnInit {
  
  newCommittee$: Observable<ICommittee> = of({});
  isSaving$: Observable<boolean> = of(false);
  model: ICommittee = {};
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;
  

  constructor(private readonly store: Store<RootState>
              , private readonly title: Title
              , private modalService: MdbModalService
              , private readonly router:Router
  ) {}

  ngOnInit(): void {
    this.isSaving$ = this.store.select(state => state.committee.isSaving);
    this.newCommittee$ = this.store.select(selectors.committee.get)
    .pipe(tap((model: ICommittee) => {
        this.model = {...model};
    }));
    this.newCommittee$.subscribe();
    this.title.setTitle(`New Committee`);
  }

  save() {
    const action = this.model.name?.trim() ?
      CrudActions.create<ICommittee>(Feature.Committee)(this.model) : 
      toastErrorAction({ message: 'A committee name is required!' })
    this.store.dispatch(action);
  }

  onCancelCheck(){
    return !!this.model.name;
  }

  cancelUpdate(){
    this.router.navigate(['/']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'Committee-New'},
    });    
  }
}