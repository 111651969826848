import { Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { msalAuthority, msalClientId, msalProtectedResourceUri, msalRedirectUri, msalScope } from './utils/app-config';

 export const msalConfig: Configuration = {
   auth: {
    clientId: msalClientId,
    authority: msalAuthority,
    redirectUri: msalRedirectUri,
   },
   cache: {
     cacheLocation: BrowserCacheLocation.LocalStorage
   },
   system: {
    allowNativeBroker: false,
     loggerOptions: {
       piiLoggingEnabled: false
     }
   }
 }

 export const protectedResources = {
   profileApi: {
    endpoint: msalProtectedResourceUri,
    scopes: [msalScope],
   },
 }

 export const loginRequest = {
   scopes: [...protectedResources.profileApi.scopes]
 };
