<div class="popconfirm-popover shadow-4">
  <div class="popconfirm">
    <p class="popconfirm-message">
      <span class="popconfirm-message-text">Are you sure you want to discard all the changes?</span>
    </p>
    <div class="popconfirm-buttons-container">
      <button (click)="popconfirmRef.close()" type="button" aria-label="Cancel" class="btn btn-flat btn-sm">
        No
      </button>
      <button (click)="confirm()" type="button" aria-label="Confirm" class="btn btn-primary btn-sm">
        Yes
      </button>
    </div>
  </div>
</div>