import { Component } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'app-toast-error',
  templateUrl: './toast-error.component.html',
  styleUrls: ['./toast-error.component.scss']
})
export class ToastErrorComponent {

  text: string | null = null; 
  
  constructor(public notificationRef: MdbNotificationRef<ToastErrorComponent>) {}


}
