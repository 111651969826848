import { createSelector } from '@ngrx/store';
import { RootState } from '../root.state';
import { EmployeeContactState } from '../state/employee-contact.state';

const state = (state: RootState) => state.employeeContact;

const isLoading = createSelector(
  state,
  (state: EmployeeContactState) => state.isLoading
);

const isSaving = createSelector(
  state,
  (state: EmployeeContactState) => state.isSaving
);

const canUpdate = createSelector(
  state,
  (state: EmployeeContactState) => state.canUpdate
);

const get = createSelector(
  state,
  (state: EmployeeContactState) => state.employeeContact
);

export const employeeContactSelectors = {
  isLoading,
  isSaving,
  canUpdate,
  get
};