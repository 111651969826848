import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { Observable, of } from 'rxjs';
import { IEmployeeKeyDates } from 'src/app/models/employee-key-dates.model';
import { Profile } from 'src/app/models/user.model';
import { accessToken, user } from 'src/app/store/state/auth.state';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';

@Component({
  selector: 'app-employee-key-dates',
  templateUrl: './employee-key-dates.component.html',
  styleUrls: ['./employee-key-dates.component.scss']
})
export class EmployeeKeyDatesComponent implements OnInit, OnChanges {

  @Input()  
  employeeId: string | null | undefined;

  user$!: Observable<Profile | null>;
  canUpdate$: Observable<boolean> = of(false);
  isSaving$: Observable<boolean> = of(false);
  canUpdate = false;
  isReadOnly = true;
  hasEditButton = false;
  hasHrItAdminPermissions = false;
  employeeKeyDates$: Observable<IEmployeeKeyDates | null> = of({});
  model: IEmployeeKeyDates = {};
  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;
  
  constructor(private store: Store<RootState>
              , private popconfirmService: MdbPopconfirmService) 
  {}

  ngOnInit(): void {
    this.canUpdate$ = this.store.select(selectors.employeeKeyDates.canUpdate);
    this.isSaving$ = this.store.select(selectors.employeeKeyDates.isSaving);   
    this.employeeKeyDates$ = this.store.select(selectors.employeeKeyDates.get);
    this.user$ = this.store.pipe(select(user));
    this.user$.subscribe((user: Profile | null) => {
      if(user) {
        this.hasHrItAdminPermissions = user?.isHR|| user?.isITSystems || user?.isAdmin;
        this.hasEditButton = this.isReadOnly && (this.hasHrItAdminPermissions);
      }
    })

    this.canUpdate$.subscribe((canUpdate: boolean) => {
      this.isReadOnly = !canUpdate;
      this.canUpdate = canUpdate;
      this.hasEditButton = this.isReadOnly && (this.hasHrItAdminPermissions);
    });

    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
          this.getEmployeeDates();
      }
    });

    this.employeeKeyDates$.subscribe((employeeKeyDates: IEmployeeKeyDates | null) => {
      if (employeeKeyDates) {
        this.model = {...employeeKeyDates};
        if(this.model.startDate){
          this.model.startDate = new Date(this.model?.startDate);
        }
        if(this.model.anniversaryDate){
          this.model.anniversaryDate = new Date(this.model?.anniversaryDate);
        }
        if(this.model.resignationDate){
          this.model.resignationDate = new Date(this.model?.resignationDate);
        }
        if(this.model.rehireDate){
          this.model.rehireDate = new Date(this.model?.rehireDate);
        }
      }
    });
  }
  
  ngOnChanges() {
    if(this.employeeId) {
      this.getEmployeeDates();
    }
  }

  getEmployeeDates() {
    if(this.employeeId) {
      this.store.dispatch(CrudActions.get(Feature.EmployeeKeyDates)(Number(this.employeeId)));
    }
  }

  onSave() {    
    this.store.dispatch(CrudActions.update(Feature.EmployeeKeyDates)(this.model, this.model.employeeId));
  }

  onEdit() {    
    this.store.dispatch(CrudActions.setCanUpdate(Feature.EmployeeKeyDates)(true));
  }

  onCancel() {
    this.getEmployeeDates();
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }

}