import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MdbPopconfirmRef, MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { Observable, Subject, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { IDepartment } from 'src/app/models/department.model';
import { IEmployeeOrg } from 'src/app/models/employee-org.model';
import { IEmployeeType } from 'src/app/models/employee-type.model';
import { IEmployee } from 'src/app/models/employee.model';
import { IOffice } from 'src/app/models/office.model';
import { IPscEmployee } from 'src/app/models/psc-employee.model';
import { IPsc } from 'src/app/models/psc.model';
import { ISbu } from 'src/app/models/sbu.model';
import { Profile } from 'src/app/models/user.model';
import { accessToken,  user } from 'src/app/store/state/auth.state';
import { ConfirmComponent } from '../helpers/confirm/confirm.component';
import { RootState } from 'src/app/store/root.state';
import { getAllEmployeeType } from 'src/app/store/actions/employee-type.actions';
import { getEmployeeOrg, setEmployeeOrgCanUpdate, updateEmployeeOrg } from 'src/app/store/actions/employee-org.actions';
import { selectors } from 'src/app/store/selectors/selectors';
import { Feature } from 'src/app/models/features.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';

@Component({
  selector: 'app-employee-org',
  templateUrl: './employee-org.component.html',
  styleUrls: ['./employee-org.component.scss']
})
export class EmployeeOrgComponent implements OnInit {

  @Input()
  employeeId: string | null | undefined;

  user$!: Observable<Profile | null>;
  employeeOrg$: Observable<IEmployeeOrg> = of({});
  offices$: Observable<IOffice[]> = of([]);
  physicalOffices$: Observable<IOffice[]> = of([]);
  officesLoading$: Observable<boolean> = of(false);
  sbus$: Observable<ISbu[]> = of([]);
  pscs$: Observable<IPsc[]> = of([]);
  departments$: Observable<IDepartment[]> = of([]);
  employeeTypes$: Observable<IEmployeeType[]> = of([]);
  employeeTypesLoading$: Observable<boolean> = of(false);
  supervisorEmployees$: Observable<IEmployee[]> = of([]);
  supervisorEmployeesLoading$: Observable<boolean> = of(false);
  supervisorEmployeeInput$ = new Subject<string>();
  canUpdate$: Observable<boolean> = of(false);
  isSaving$: Observable<boolean> = of(false);
  canUpdate = false;
  isReadOnly = true;
  hasEditButton = false;
  hasHrItFacilitiesAdminPermissions = false;
  hasHrItAdminPermissions = false;
  hasHrAdminPermissions = false;
  hasItAdminPermissions = false;
  hasAdminPermissions = false;
  model: IEmployeeOrg = {};
  popconfirmRef: MdbPopconfirmRef<ConfirmComponent> | null = null;
  pscEmployeeModel: IPsc[] = [];

  typeOfForms: { value: string, label: string }[] = [
    { "value": "", "label": "" },
    { "value": "Internal", "label": "Internal" },
    { "value": "Professional", "label": "Professional" },
  ];

  exemptionTypes: { value: string, label: string }[] = [
    { "value": "", "label": "" },
    { "value": "Non-Exempt", "label": "Non-Exempt" },
    { "value": "Exempt", "label": "Exempt" },
  ];

  constructor(private store: Store<RootState>
              ,private popconfirmService: MdbPopconfirmService) 
  {}

  ngOnInit(): void {
    this.employeeOrg$ = this.store.select(selectors.employeeOrg.get);
    this.canUpdate$ = this.store.select(selectors.employeeOrg.canUpdate);
    this.isSaving$ = this.store.select(selectors.employeeOrg.isSaving);
    this.departments$ = this.store.select(selectors.departments.get);
    this.employeeTypes$ = this.store.select(selectors.employeeType.getAll);
    this.employeeTypesLoading$ = this.store.select(selectors.employeeType.isLoading);
    this.offices$ = this.store.select(selectors.offices.get);
    this.physicalOffices$ = this.store.select(selectors.offices.get);
    this.officesLoading$ = this.store.select(selectors.office.isLoading);
    this.pscs$ = this.store.select(selectors.pscs.get);
    this.sbus$ = this.store.select(selectors.sbus.get);
    this.supervisorEmployeesLoading$ = this.store.select(selectors.employee.isLoading);
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken)
        {
          this.user$ = this.store.pipe(select(user));
          this.user$.subscribe((user: Profile | null) => {
            if(user) {
              this.hasAdminPermissions = user?.isAdmin;
              this.hasItAdminPermissions = user?.isITSystems || user?.isAdmin;
              this.hasHrAdminPermissions = user?.isHR|| user?.isAdmin;
              this.hasHrItAdminPermissions = user?.isHR|| user?.isITSystems || user?.isAdmin;
              this.hasHrItFacilitiesAdminPermissions = user?.isHR|| user?.isITSystems || user?.isFacilties || user?.isAdmin;
              this.hasEditButton = this.isReadOnly && (
                this.hasItAdminPermissions || this.hasAdminPermissions || 
                this.hasHrItAdminPermissions || this.hasHrAdminPermissions || 
                this.hasHrItFacilitiesAdminPermissions);
            }
          });
          this.store.dispatch(CrudActions.get(Feature.Departments)(undefined, { isActive: true }));
          this.store.dispatch(getAllEmployeeType({queryParams:{}}));
          this.store.dispatch(CrudActions.get(Feature.Offices)(undefined, { isActive: true }));
          this.store.dispatch(CrudActions.get(Feature.Sbus)(undefined, { isActive: true }));
          this.store.dispatch(CrudActions.get(Feature.Pscs)(undefined, { isActive: true }));
          this.searchSupervisorEmployees();
          this.getEmployeeOrg();

          this.employeeOrg$.subscribe((employeeOrg: IEmployeeOrg) => {
            this.model = { ...employeeOrg};
            this.pscEmployeeModel = (this.model.pscEmployees) ? this.model.pscEmployees
                .filter((pe: IPscEmployee) => pe.psc !== undefined)
                .map((pe: IPscEmployee) => pe.psc as IPsc) : [];                
          });
        }
    })

    this.canUpdate$.subscribe((canUpdate: boolean) => {
      this.isReadOnly = !canUpdate;
      this.canUpdate = canUpdate;
      this.hasEditButton = this.isReadOnly && (this.hasHrItAdminPermissions || this.hasHrAdminPermissions);
    });
  }

  getEmployeeOrg() {
    if(this.employeeId) {
      this.store.dispatch(getEmployeeOrg({ id: this.employeeId }));
    }
  }

  private searchSupervisorEmployees() {
    this.supervisorEmployees$ = this.supervisorEmployeeInput$.pipe(
      distinctUntilChanged(),
      switchMap((term: string) => {
        this.store.dispatch(CrudActions.get(Feature.Employees)(undefined, { isActive: true, name: term  }));
        return this.store.select(selectors.employees.get);
      })
    );
  }
  
  trackByFnEmployee(item: IEmployee) {
    return item.employeeId;
  }

  onSave() {
    if (this.model.pscEmployees) {
      const pscEmployees = this.model.pscEmployees.filter(pe => this.pscEmployeeModel.find(pem => pem.pscId === pe.pscId));
      const newEmployees = this.pscEmployeeModel.filter(pe => !(this.model.pscEmployees?.find(pem => pem.pscId === pe.pscId)));
      const newPscEmployees = newEmployees
          .filter((pe: IPsc) => pe.pscId !== undefined && this.model.employeeId !== undefined)
          .map<IPscEmployee>((pe: IPsc) => ({employeeId: this.model.employeeId as number, pscId: pe.pscId as number}));
      this.model.pscEmployees = [...pscEmployees, ...newPscEmployees];
    }
    this.model.supervisorEmployeeId = this.model.supervisorEmployee?.employeeId;
    this.model.assistantEmployeeId = this.model.assistantEmployee?.employeeId;
    this.store.dispatch(updateEmployeeOrg({ model: this.model, id: this.model.employeeId, includeChildren: false, queryParams: {includePscEmployees: true} }));
  }

  onEdit() {
    this.store.dispatch(setEmployeeOrgCanUpdate({ canUpdate: true }));
  }

  onCancel() {
    this.getEmployeeOrg();
  }

  openPopconfirm(event: Event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(ConfirmComponent, target, { popconfirmMode: 'modal' });
    this.popconfirmRef.onConfirm.subscribe(() => {
      this.onCancel();
    });
  }

}
