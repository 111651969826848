import { IProficiency } from "src/app/models/proficiency.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface ProficiencyState extends BaseState {
  proficiencies: IProficiency[];
}

export const initialProficiencyState: ProficiencyState = {
  ...initialBaseState,
  proficiencies: []
};
