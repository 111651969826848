import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISbu } from '../../models/sbu.model';
import { accessToken, azureStorageKey, user } from 'src/app/store/state/auth.state';
import { select, Store } from '@ngrx/store';
import { azureStorageURL } from 'src/app/utils/app-config';
import { Observable, map, of } from 'rxjs';
import { Profile } from 'src/app/models/user.model';
import { Title } from '@angular/platform-browser';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { Feature } from 'src/app/models/features.model';

@Component({
  selector: 'app-sbus',
  templateUrl: './sbus.component.html',
  styleUrls: ['./sbus.component.scss']
})
export class SbusComponent implements OnInit {

  sbus$: Observable<ISbu[]> = of([]);
  isLoading$: Observable<boolean> = of(false);
  user$!: Observable<Profile | null>;
  headElements = ['sbuId', 'name'];
  azureImageUrl = azureStorageURL;
  azureKey = '';
  
  constructor(private readonly title: Title
              , private readonly store: Store<RootState>
              , private readonly router:Router) { }

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectors.sbus.isLoading);
    this.sbus$ = this.store.select(selectors.sbus.get).pipe(
      map(sbus => Array.isArray(sbus) ? sbus : [])
    );
    this.user$ = this.store.pipe(select(user));
    this.store.pipe(select(accessToken)).subscribe((accessToken: string | null) => {
      if(accessToken) {
        this.store.dispatch(CrudActions.get(Feature.Sbus)());
      }
    });
    this.store.pipe(select(azureStorageKey)).subscribe((azureKey) => {
      this.azureKey = azureKey;
    });
    this.title.setTitle(`Sbus`);   
  }


  handleClick(sbu: ISbu) {    
    this.router.navigate(['/sbus/', sbu.sbuId]);
  }

}
