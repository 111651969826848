import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEmployeeOrg } from '../models/employee-org.model';
import { IPscEmployee } from '../models/psc-employee.model';
import { AppAuthenticatedCrudBaseService } from './app-authenticated-crud-base.service';
import { RootState } from '../store/root.state';

@Injectable({
  providedIn: 'root'
})
export class EmployeeOrgService extends AppAuthenticatedCrudBaseService<IEmployeeOrg> {
  constructor(http: HttpClient, store: Store<RootState>) {
    super(http, 'EmployeeOrg', store);
  }

  createDto(model: IEmployeeOrg): IEmployeeOrg { 
    let pscEmployees = model.pscEmployees;
    if (model.pscEmployees) pscEmployees = model.pscEmployees.map(pl => {
      return {
        pscEmployeeId: pl.pscEmployeeId,
        employeeId: pl.employeeId,
        pscId: pl.pscId
      } as IPscEmployee;
    });
    const result: IEmployeeOrg =
    {
      employeeId: model.employeeId,      
      isExecutiveBoard:model.isExecutiveBoard,
      isSeniorLeadershipTeam:model.isSeniorLeadershipTeam,
      isOMP:model.isOMP,
      isGNC:model.isGNC,
      isHybrid:model.isHybrid,
      employeeType: model.employeeType,
      typeOfForm: model.typeOfForm,
      title: model.title,
      photoName: model.photoName,
      departmentId: model.departmentId,
      floor: model.floor,
      roomNumber: model.roomNumber,
      officeId: model.officeId,
      physicalOfficeId: model.physicalOfficeId,
      supervisorEmployeeId: model.supervisorEmployeeId,
      assistantEmployeeId: model.assistantEmployeeId,
      sbuId: model.sbuId,
      pscEmployees: pscEmployees,
      correspondenceSigDisclaimer: model.correspondenceSigDisclaimer,
      exemptionType: model.exemptionType
    };
    return result;
  }
}