import { createReducer, on } from "@ngrx/store";
import * as CountyActions from "../actions/county.actions";
import { CountyState, initialCountyState } from "../state/county.state.";

export const countyReducer = createReducer(
  initialCountyState,
  on(CountyActions.getAllCounty, (state: CountyState) => {
    return { ...state, counties: [], isLoading: true, isSaving: false, error: null };
  }),
  on(CountyActions.getAllCountySuccess, (state: CountyState, { models }) => {
    return { ...state, counties: models, isLoading: false, isSaving: false, error: null };
  }),
  on(CountyActions.getAllCountyFailure, (state: CountyState, { error }) => {
    return { ...state, isLoading: false, isSaving: false, error };
  }),

);