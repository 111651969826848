<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img
        src="assets/img/HB.png"
        class="me-2"
        height="20"
        alt="HB"
        loading="lazy" />
      <small>Employee Management</small>
    </a>
    <div class="collapse navbar-collapse"
          id="navbarLeftAlignExample"
          mdbCollapse
          #leftAligned="mdbCollapse">
      <!-- Left links -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">      
        <li class="nav-item"  *ngIf="!isAdmin && !isHR">
          <a routerLink="/employees" class="nav-link" mdbWavesEffect>Employees</a>
        </li>
        <li mdbDropdown class="nav-item dropdown" *ngIf="isAdmin || isHR">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" mdbDropdownToggle aria-expanded="false">
            Employees
          </a>
          <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <a routerLink="/employee-new" class="dropdown-item" mdbWavesEffect href="#">Add New Employee</a>
            </li>
            <li>
              <a routerLink="/employees" class="dropdown-item" mdbWavesEffect href="#">Search & Edit </a>
            </li>
            <li>
              <a routerLink="/employee-activate" class="dropdown-item" mdbWavesEffect href="#">Inactive Employees</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a routerLink="/offices" class="nav-link waves-light" mdbWavesEffect>Offices</a>
        </li>
        <li class="nav-item">
          <a routerLink="/pscs" class="nav-link waves-light" mdbWavesEffect>PSCs</a>
        </li>
        <li class="nav-item">
          <a routerLink="/sbus" class="nav-link waves-light" mdbWavesEffect>SBUs</a>
        </li>
        <li class="nav-item">
          <a routerLink="/departments" class="nav-link waves-light" mdbWavesEffect>Departments</a>
        </li>
        <li class="nav-item">
          <a routerLink="/support-teams" class="nav-link waves-light" mdbWavesEffect>Support Teams</a>
        </li>
        <li class="nav-item">
          <a routerLink="/committees" class="nav-link waves-light" mdbWavesEffect>Committees</a>
        </li>
        <li mdbDropdown class="nav-item dropdown" *ngIf="isAdmin">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" mdbDropdownToggle aria-expanded="false">
            Admin
          </a>
          <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <a routerLink="/office-new" class="dropdown-item" mdbWavesEffect href="#" >New Office</a>
            </li>
            <li>
              <a routerLink="/department-new" class="dropdown-item" mdbWavesEffect href="#">New Department</a>
            </li>
            <li>
              <a routerLink="/support-teams-new" class="dropdown-item" mdbWavesEffect>New Support Team</a>
            </li>
            <li>
              <a routerLink="/committee-new" class="dropdown-item" mdbWavesEffect href="#">New Committee</a>
            </li>
            <li>
              <a routerLink="/language-new" class="dropdown-item" mdbWavesEffect href="#">New Language</a>
            </li>
            <li>
              <a routerLink="/bar-admission-new" class="dropdown-item" mdbWavesEffect href="#">New Bar Admission</a>
            </li>
          </ul>
        </li>
      </ul>
      <!-- Left links -->
    </div>  
    <ul class="navbar-nav">
      <li class="nav-item" *ngIf="loginDisplay$ | async">
        <p class="welcome">Welcome {{userName}} </p>
      </li>
      <li mdbDropdown class="nav-item dropdown" *ngIf="loginDisplay$ | async">
          <a  class="nav-link dropdown-toggle d-flex align-items-center"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              mdbDropdownToggle
              aria-expanded="false">
          <img src="{{employee.imageUrl}}" alt="" class="rounded-circle" height="40" loading="lazy">
        </a>
        <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li>
            <a routerLink="/change-request" class="dropdown-item" href="#">Request Change</a>
          </li>         
        </ul>
      </li>
    </ul>
    <!-- Left links -->
  </div>
</nav>


<!-- Content -->
<div class="container-fluid pt-3">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>

<!-- Footer -->
<footer class="bg-light text-center text-lg-start">
  <!-- Copyright -->
  <div class="text-center p-3">
    <a class="text-dark" href="https://thehub.huschblackwell.com/">© 2022  Husch Blackwell LLP. All rights reserved </a>  
  </div>
  <!-- Copyright -->
</footer>