import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeTypeActions from '../actions/employee-type.actions';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';
import { IEmployeeType } from 'src/app/models/employee-type.model';
import { toastErrorAction } from '../actions/toast.actions';

@Injectable()
export class EmployeeTypeEffects {
  private actions$ = inject(Actions);
  
  constructor(
    private employeeTypeService: EmployeeTypeService
  ) {}

  getAllEmployeeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeTypeActions.getAllEmployeeType),
      mergeMap((action: EmployeeTypeActions.GetAllEmployeeTypeAction) => {
        return this.employeeTypeService.getAll(action.queryParams).pipe(
          map((models: IEmployeeType[]) => {
            return EmployeeTypeActions.getAllEmployeeTypeSuccess({ models });
          }),
          catchError(error => {
            return of(
              EmployeeTypeActions.getAllEmployeeTypeFailure({ error }),
              toastErrorAction({ message: 'Failed to get employee types' })
            );
          })
        );
      })
    )
  );

}