<!-- Media object -->
<div class="d-flex mb-10">
  <!-- Image -->
  <app-image  [code]="model.ntId" 
              [isSmallCard]="true"
              [isEmployeePhoto]="true"
              folder="employeephotos"
              (save)="saveFilename($event)" >
  </app-image>
  <div class="ml-1">
    <h5 class="fw-bold">
      {{model.displayName}}
    </h5>
    <p >
      <a href="mailto:{{model.email}}"><i class="far fa-envelope mr-05"></i>{{model.email}}</a> <br/>      
      <a href="{{model.linkedInUrl}}" target=_blank><i class="fab fa-linkedin-in"></i>{{model.linkedInUrl}}</a><br/>
      <a href="{{model.twitterUrl}}" target=_blank><i class="fab fa-twitter"></i>{{model.twitterUrl}}</a>
    </p>
  </div>
  <div class="d-flex justify-content-end ml-1">
    <div class="p-2">
      <div class="d-flex align-items-center" *ngIf="isLoading$ | async">
        <strong>Loading...</strong>
        <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
      </div>
      <div class="d-flex align-items-center" *ngIf="isSaving$ | async">
        <strong>Saving...</strong>
        <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
      </div>
    </div>
  </div>
</div>

<div class="clearfix"></div>
<mat-tab-group [fill]="true" [justified]="true" *ngIf="(isLoading$ | async) === false">
  <mat-tab label="Profile">
    <app-employee-profile [model]="model" 
                          class="nomargin"
                          (save)="saveEmployee($event)">
    </app-employee-profile>
  </mat-tab>
  <mat-tab label="Status/Key Dates">
    <ng-template matTabContent>
      <app-employee-key-dates [employeeId]="id"> </app-employee-key-dates>
    </ng-template>
  </mat-tab>
  <mat-tab label="Organization">
    <ng-template matTabContent>
      <app-employee-org [employeeId]="id"> </app-employee-org>
    </ng-template>
  </mat-tab> 
  <mat-tab label="Contact">
    <ng-template matTabContent> 
      <app-employee-contact [employeeId]="id"></app-employee-contact>
    </ng-template>
  </mat-tab>
  <mat-tab label="Languages">
    <ng-template matTabContent> 
      <app-employee-language [employeeId]="id"></app-employee-language> 
    </ng-template>
  </mat-tab>
  <mat-tab label="Notary">    
    <ng-template matTabContent> 
      <app-employee-notary [employeeId]="id"></app-employee-notary> 
    </ng-template>
  </mat-tab>
  <mat-tab label="Support Team">
    <ng-template matTabContent> 
      <app-employee-support-team [employeeId]="id"></app-employee-support-team> 
    </ng-template>
  </mat-tab>
  <mat-tab label="Bar Admissions">
    <ng-template matTabContent> 
      <app-employee-bar [employeeId]="id"></app-employee-bar> 
    </ng-template>
  </mat-tab>
  <mat-tab label="Education">    
    <ng-template matTabContent> 
      <app-employee-education [employeeId]="id"></app-employee-education> 
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="model.bioBody" label="Public Bio">
    <div [innerHTML]="model.bioBody"></div>
  </mat-tab>
  <mat-tab *ngIf="!model.bioBody" label="Public Bio Admin">
    <app-employee-bio-admin [model]="model" 
                            class="nomargin" 
                            (save)="saveEmployee($event)">
    </app-employee-bio-admin>
  </mat-tab>
  <mat-tab *ngIf="model.recognitions" label="Recognitions">
    <div [innerHTML]="model.recognitions"></div>
  </mat-tab>
  <mat-tab *ngIf="model.professionalAssociations" label="Professional Associations">
    <div [innerHTML]="model.professionalAssociations"></div>
  </mat-tab>
</mat-tab-group>