import { IEmployeeContact } from "src/app/models/employee-contact.model";
import { BaseState, initialBaseState } from "./crud.state";

export interface EmployeeContactState extends BaseState {
  employeeContact: IEmployeeContact;
}

export const initialEmployeeContactState: EmployeeContactState = {
  ...initialBaseState,
  employeeContact: {}  
};
