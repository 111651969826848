import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { IBarAdmission } from 'src/app/models/bar-admission.model';
import { ModalCancelComponent } from '../helpers/modal-cancel/modal-cancel.component';
import { Store } from '@ngrx/store';
import { Observable, of,  tap } from 'rxjs';
import { RootState } from 'src/app/store/root.state';
import { selectors } from 'src/app/store/selectors/selectors';
import { Feature } from 'src/app/models/features.model';
import { CrudActions } from 'src/app/store/actions/crud.actions';
import { toastErrorAction } from 'src/app/store/actions/toast.actions';

@Component({
  selector: 'app-bar-admission-new',
  templateUrl: './bar-admission-new.component.html',
  styleUrls: ['./bar-admission-new.component.scss']
})
export class BarAdmissionNewComponent implements OnInit {
  newBarAdmission$: Observable<IBarAdmission> = of({});
  isSaving$: Observable<boolean> = of(false);
  model: IBarAdmission = {};
  modalRef: MdbModalRef<ModalCancelComponent> | null = null;  

  constructor(private readonly store: Store<RootState>
            , private readonly title: Title
            , private modalService: MdbModalService
            , private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.isSaving$ = this.store.select(selectors.barAdmission.isSaving);    
    this.newBarAdmission$ = this.store.select(selectors.barAdmission.get)
      .pipe(tap((model: IBarAdmission) => {
          this.model = {...model};
      }));
    this.newBarAdmission$.subscribe();
    this.title.setTitle(`New Bar Admission`);
  }

  save() {
    const action = this.model.description?.trim() ?
      CrudActions.create<IBarAdmission>(Feature.BarAdmission)(this.model) : 
      toastErrorAction({ message: 'A description is required!' });
    this.store.dispatch(action);
  }

  onCancelCheck(){
    return this.model.description || this.model.location ? true : false;
  }

  cancelUpdate(){
    this.model = {};
    this.router.navigate(['/']);
  }

  openCancelModal() {
    this.modalRef = this.modalService.open(ModalCancelComponent, {
      data: { title: 'BarAdmission-New'},
    });
  }

}
