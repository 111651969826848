import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as EmployeeNotaryActions from '../actions/employee-notary.actions';
import { EmployeeNotaryService } from 'src/app/services/employee-notary.service';
import { IEmployeeNotary } from 'src/app/models/employee-notary.model';
import { toastErrorAction, toastSuccessAction } from '../actions/toast.actions';

@Injectable()
export class EmployeeNotaryEffects {
  
  private actions$ = inject(Actions);

  constructor(
    private employeeNotaryService: EmployeeNotaryService
  ) {}

  createEmployeeNotary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeNotaryActions.createEmployeeNotary),
      mergeMap((action: EmployeeNotaryActions.CreateEmployeeNotaryAction) => {
        return this.employeeNotaryService.create(action.model, action.includeChildren).pipe(
          concatMap((model: IEmployeeNotary) => {
            return [
              EmployeeNotaryActions.createEmployeeNotarySuccess({ model }),
              EmployeeNotaryActions.getAllEmployeeNotary({ queryParams: { employeeId: model.employeeId } }),
              toastSuccessAction({ message: 'Notary added successfully' })
            ];
          }),
          catchError(error => {
            return of(
              EmployeeNotaryActions.createEmployeeNotaryFailure({ error }),
              toastErrorAction({ message: 'Failed to add notary' })
            );
          })
        );
      })
    )
  );

  deleteEmployeeNotary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeNotaryActions.deleteEmployeeNotary),
      mergeMap((action: EmployeeNotaryActions.DeleteEmployeeNotaryAction) => {
        return this.employeeNotaryService.delete(action.id).pipe(
          concatMap((model: IEmployeeNotary) => {
            return [
              EmployeeNotaryActions.deleteEmployeeNotarySuccess(),
              EmployeeNotaryActions.getAllEmployeeNotary({ queryParams: { employeeId: model.employeeId } }),
              toastSuccessAction({ message: 'Employee notary deleted successfully' }) 
            ];
          }),
          catchError(error => {
            return of(
              EmployeeNotaryActions.deleteEmployeeNotaryFailure({ error }),
              toastErrorAction({ message: 'Failed to delete notary' })
            );
          })
        );
      })
    )
  );

  getAllEmployeeNotary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeNotaryActions.getAllEmployeeNotary),
      mergeMap((action: EmployeeNotaryActions.GetAllEmployeeNotaryAction) => {
        return this.employeeNotaryService.getAll(action.queryParams).pipe(
          map((models: IEmployeeNotary[]) => {
            return EmployeeNotaryActions.getAllEmployeeNotarySuccess({ models });
          }),
          catchError(error => {
            return of(
              EmployeeNotaryActions.getAllEmployeeNotaryFailure({ error }),
              toastErrorAction({ message: 'Failed to get notaries' })
            );
          })
        );
      })
    )
  );

  updateAllEmployeeNotary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeNotaryActions.updateAllEmployeeNotary),
      mergeMap((action: EmployeeNotaryActions.UpdateAllEmployeeNotaryAction) => {
        return this.employeeNotaryService.updateAll(action.models).pipe(
          concatMap(() => {
            return [
              EmployeeNotaryActions.updateAllEmployeeNotarySuccess({ models: action.models }),
              toastSuccessAction({ message: 'Notaries updated successfully' })
            ];
          }),
          catchError(error => {
            return of(
              EmployeeNotaryActions.updateAllEmployeeNotaryFailure({ error }),
              toastErrorAction({ message: 'Failed to update notaries' })
            );
          })
        );
      })
    )
  );

}