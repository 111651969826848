<h1>SBUs</h1>


<div class="d-flex align-items-center" *ngIf="isLoading$ | async">
  <h5>Loading...</h5>
  <div class="spinner-border text-success ml-05" role="status" aria-hidden="true"></div>
</div>

<div class="row row-cols-2 row-cols-md-5">
  <div class="col mb-3" *ngFor="let sbu of sbus$ | async; let i = index">
    <div class="card sbu-card">
      <img  src="{{azureImageUrl}}/sbuphotos/{{(sbu.photoName) ? sbu.photoName : 'No_Image_Available.jpg'}}{{azureKey}}"
            alt="SBU Image"
            class="sbu-image" />
      <div class="card-body">
        <h6 class="card-title">{{sbu.name}}{{" (" + sbu.sbuCode + ")"}}</h6>
        <a  href="#!" class="btn btn-primary btn-sm float-end" (click)="handleClick(sbu)"
            *ngIf="((user$ | async)?.isAdmin)">Edit</a>
      </div>
    </div>
  </div>
</div>