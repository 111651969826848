import { Component } from '@angular/core';
import { MdbPopconfirmRef } from 'mdb-angular-ui-kit/popconfirm';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent {

  constructor(public popconfirmRef: MdbPopconfirmRef<DeleteConfirmComponent>) {}

  confirm(): void {
    const closeMessage = 'Delete confirmed';
    this.popconfirmRef.confirm(closeMessage)
  }

}