import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent  {
  @Input() type = "text"
  @Input() cssClass!: string;
  @Input() labelHtml!: string;
  @Input() fieldName!: string;
  @Input() fieldValue: number | undefined;  
  @Input() maxLength?: number;
  @Input() pattern?: string;
  @Input() readonly!: boolean | null;
  @Input() disabled!: boolean | null;
    @Output() fieldValueChange = new EventEmitter<number | undefined>();

}